import PropTypes from 'prop-types';

import themes from '../utils/iconColorThemes';

const ChevronAscend = ({ theme = 'base' }) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-5.52631e-08 4.58729L5 0.142844L10 4.58729L8.8762 5.85156L5 2.40605L1.1238 5.85156L-5.52631e-08 4.58729Z"
      fill={themes[theme]}
    />
  </svg>
);
ChevronAscend.propTypes = {
  theme: PropTypes.oneOf(Object.keys(themes))
};

export default ChevronAscend;
