const PiggyBankLabelCircledIcon = props => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.5916 0.875L14.0916 0.875001L14.0916 0.142604L13.4095 0.409338L13.5916 0.875ZM13.5916 4.60378L13.0916 4.60378L13.0916 5.10378H13.5916V4.60378ZM10.2404 2.18549L10.1941 2.68334L10.3121 2.69432L10.4225 2.65115L10.2404 2.18549ZM3.23193 11.5007H3.73193V11.2259L3.50002 11.0786L3.23193 11.5007ZM3.23193 13.1249H2.73193V13.6249H3.23193V13.1249ZM5.66672 13.1249V13.6249H6.16672V13.1249H5.66672ZM5.66672 12.1114V11.6114H5.16672V12.1114H5.66672ZM8.71003 12.1114H9.21003V11.6114H8.71003V12.1114ZM8.71003 13.1249H8.21003V13.6249H8.71003V13.1249ZM11.1448 13.1249V13.6249H11.6448V13.1249H11.1448ZM11.1448 11.8965L10.9787 11.4249L10.6448 11.5425V11.8965H11.1448ZM13.8535 9.06755V8.56755H13.5034L13.3837 8.89657L13.8535 9.06755ZM15 9.06755V9.56755H15.5V9.06755H15ZM15 4.60378H15.5V4.10378H15V4.60378ZM13.0916 0.874999L13.0916 4.60378L14.0916 4.60378L14.0916 0.875001L13.0916 0.874999ZM10.4225 2.65115L13.7737 1.34066L13.4095 0.409338L10.0583 1.71983L10.4225 2.65115ZM9.89123 2.66932C9.99327 2.66932 10.0943 2.67405 10.1941 2.68334L10.2867 1.68764C10.1562 1.6755 10.0243 1.66932 9.89123 1.66932V2.66932ZM5.29719 2.66932H9.89123V1.66932H5.29719V2.66932ZM1.5 7.14033C1.5 4.60021 3.26594 2.66932 5.29719 2.66932V1.66932C2.58189 1.66932 0.5 4.18962 0.5 7.14033H1.5ZM3.50002 11.0786C2.32735 10.3337 1.5 8.86492 1.5 7.14033H0.5C0.5 9.17474 1.47601 10.9777 2.96385 11.9227L3.50002 11.0786ZM3.73193 13.1249V11.5007H2.73193V13.1249H3.73193ZM5.66672 12.6249H3.23193V13.6249H5.66672V12.6249ZM5.16672 12.1114V13.1249H6.16672V12.1114H5.16672ZM8.71003 11.6114H5.66672V12.6114H8.71003V11.6114ZM9.21003 13.1249V12.1114H8.21003V13.1249H9.21003ZM11.1448 12.6249H8.71003V13.6249H11.1448V12.6249ZM10.6448 11.8965V13.1249H11.6448V11.8965H10.6448ZM13.3837 8.89657C12.936 10.1269 12.0431 11.05 10.9787 11.4249L11.3109 12.3681C12.6956 11.8803 13.788 10.7098 14.3234 9.23853L13.3837 8.89657ZM15 8.56755H13.8535V9.56755H15V8.56755ZM14.5 4.60378V9.06755H15.5V4.60378H14.5ZM13.5916 5.10378H15V4.10378H13.5916V5.10378Z"
      fill="#0D0D0D"
    />
    <path d="M5.0835 3.9375H9.67733" stroke="#0D0D0D" />
  </svg>
);

export default PiggyBankLabelCircledIcon;
