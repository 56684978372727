import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getColor, AVAILABLE_COLORS } from '../utils/colors';

import Tooltip from '../Tooltip';

import styles from './LabelItem.module.scss';

const LabelItem = ({
  text = null,
  color = null,
  labelColor = null,
  className = '',
  tooltip = '',
  tooltipPosition = 'top',
  tooltipColor = null,
  tooltipClassName = '',
  renderIcon = null,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  size = 'xxxs'
}) => {
  const labelClasses = classNames(
    styles['label-item'],
    styles[`label-item--${size}`],
    className
  );

  return (
    <Tooltip
      color={tooltipColor || color}
      position={tooltipPosition}
      tooltip={tooltip}
      textClassName={tooltipClassName}
    >
      <div
        className={labelClasses}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {renderIcon && (
          <div
            className={styles['label-item__icon']}
            style={{ color: getColor(labelColor || color) }}
          >
            {renderIcon()}
          </div>
        )}
        <div
          className={styles['label-item__text']}
          style={{ color: getColor(labelColor || color) }}
        >
          {typeof text === 'function' ? text() : text}
        </div>
      </div>
    </Tooltip>
  );
};

LabelItem.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  labelColor: PropTypes.oneOf(AVAILABLE_COLORS),
  color: PropTypes.oneOf(AVAILABLE_COLORS),
  className: PropTypes.string,
  tooltip: PropTypes.node,
  tooltipPosition: PropTypes.oneOf(['top', 'bottom']),
  tooltipColor: PropTypes.oneOf(AVAILABLE_COLORS),
  tooltipClassName: PropTypes.string,
  renderIcon: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  size: PropTypes.oneOf(['xxxs', 'xxs', 'xs'])
};

export default LabelItem;
