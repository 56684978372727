import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz/client';

import ExposedFilter from '../ExposedFilter';

import PropertyTypeFilterDropdown from './PropertyTypeFilterDropdown';

const PropertyTypeFilter = ({
  selectedPropertyTypes,
  isFilterOpen,
  onToggleFilter,
  onFiltersUpdate,
  totalResults,
  filters,
  showResults
}) => {
  const renderButtonContent = () =>
    selectedPropertyTypes.length !== 0
      ? selectedPropertyTypes
          .map(propertyType =>
            trans(`search.applied-filters-modal.propertyType.${propertyType}`)
          )
          .join(', ')
      : trans('filters.label.property.type');

  const renderFilterContent = handleDropdownClose => (
    <PropertyTypeFilterDropdown
      filters={filters}
      onFiltersUpdate={onFiltersUpdate}
      onButtonClick={handleDropdownClose}
      totalResults={totalResults}
    />
  );

  return (
    <ExposedFilter
      isFilterOpen={isFilterOpen}
      onToggleFilter={onToggleFilter}
      renderButtonContent={renderButtonContent}
      renderFilterContent={renderFilterContent}
      totalResults={totalResults}
      showResults={showResults}
      filterConfig={{
        name: 'propertyType',
        title: trans('filters.label.property.type'),
        subtitle: trans('exposed-filters.dropdown.property.type.description'),
        eventLabel: 'apply-date',
        dataTestOpen: 'property-type-exposed-filter-button',
        dataTestApply: 'property-type-exposed-filter-apply',
        isSelected: selectedPropertyTypes.length !== 0
      }}
    />
  );
};

PropertyTypeFilter.propTypes = {
  selectedPropertyTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  isFilterOpen: PropTypes.bool.isRequired,
  onToggleFilter: PropTypes.func.isRequired,
  onFiltersUpdate: PropTypes.func.isRequired,
  totalResults: PropTypes.number.isRequired,
  filters: PropTypes.shape({}).isRequired,
  showResults: PropTypes.bool.isRequired
};

export default PropertyTypeFilter;
