import { trans } from '@spotahome/soyuz/client';

import differenceInDays from 'date-fns/differenceInDays';
import addDays from 'date-fns/addDays';

import { capitalizeFirstLetter } from './stringUtils';

export const SHORT_TERM = 'shortTerm';
export const MID_TERM = 'midTerm';
export const LONG_TERM = 'longTerm';

export const SHORT_TERM_MIN_DAYS = 30;
export const MID_TERM_MIN_DAYS = 180;
export const LONG_TERM_MIN_DAYS = 365;

export const getRentalTypeRanges = minimumStaying => {
  const minRange = Math.max(1, Math.floor(minimumStaying / 30));

  return {
    [SHORT_TERM]: {
      from: minRange,
      to: 6
    },
    [MID_TERM]: {
      from: 6,
      to: 12
    },
    [LONG_TERM]: {
      from: 12,
      to: 24
    }
  };
};

export const getRentalTypeMinStay = (minimumStaying, rentalType) => {
  switch (rentalType) {
    case MID_TERM:
      return Math.max(minimumStaying, MID_TERM_MIN_DAYS);
    case LONG_TERM:
      return Math.max(minimumStaying, LONG_TERM_MIN_DAYS);
    default:
      return Math.max(minimumStaying, SHORT_TERM_MIN_DAYS);
  }
};

// This function assume that moveIn date is already valid
export function getRentalTypeMinStayForAvailabilites(
  moveIn,
  rentalType,
  defaultMinStay,
  maxStay,
  availabilities
) {
  const rentalTypeMinStay = getRentalTypeMinStay(defaultMinStay, rentalType);

  const moveInDate = new Date(moveIn);
  const rentalTypeMoveOutDate = addDays(moveInDate, rentalTypeMinStay);

  availabilities.sort((a, b) => new Date(a.from) - new Date(b.from));

  for (let i = 0; i < availabilities.length; i += 1) {
    const availability = availabilities[i];
    const fromDate = new Date(availability.from);
    const toDate = new Date(availability.to);

    if (moveInDate >= fromDate && moveInDate <= toDate) {
      if (rentalTypeMoveOutDate <= toDate) {
        return maxStay
          ? Math.min(maxStay, rentalTypeMinStay)
          : rentalTypeMinStay;
      }

      const diffInDays = differenceInDays(toDate, moveInDate);
      return maxStay ? Math.min(maxStay, diffInDays) : diffInDays;
    }
  }

  return defaultMinStay;
}

// TODO: This function currently returns the concatenated rental types: Short/Mid/Long
// it should return a range of months based on @search/utils/rentalType, likeL
// when rental types is shortTerm & midTerm, return: 1 - 12 months
export const getRentalTypesDisplay = rentalTypes =>
  rentalTypes
    .map(rentalType =>
      capitalizeFirstLetter(trans(`rentalDurationType.${rentalType}`))
    )
    .join(' / ');
