import PropTypes from 'prop-types';

import { RATIOS } from './utils/constants';

const ratios = {
  [RATIOS.PANORAMIC]: '0 0 160 90',
  [RATIOS.HOMECARD]: '0 0 200 150'
};

const SliderPlaceholder = ({ ratio }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox={ratios[ratio]}
    style={{
      backgroundColor: '#EFEFEF'
    }}
  >
    <g fill="#DFDFDF">
      <circle
        cx="0"
        cy="0"
        r="3.5"
        style={{
          transform: 'translateX(calc(50% - 10px)) translateY(50%)'
        }}
      />
      <circle
        cx="0"
        cy="0"
        r="3.5"
        style={{
          transform: 'translateX(50%) translateY(50%)'
        }}
      />
      <circle
        cx="0"
        cy="0"
        r="3.5"
        style={{
          transform: 'translateX(calc(50% + 10px)) translateY(50%)'
        }}
      />
    </g>
  </svg>
);

SliderPlaceholder.propTypes = {
  ratio: PropTypes.oneOf(Object.values(RATIOS)).isRequired
};

export default SliderPlaceholder;
