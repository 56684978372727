import classNames from 'classnames';

import ThemedHomecardWrapper from '../../common/ThemedHomecardWrapper';

import styles from './HorizontalHomecardWrapper.module.scss';

const HorizontalHomecardWrapper = ({ shadowless, ...props }) => {
  const theme = {
    wrapper: classNames(styles['horizontal-home-card'], {
      [styles['horizontal-home-card--shadowless']]: shadowless
    })
  };

  return <ThemedHomecardWrapper {...props} theme={theme} />;
};

export default HorizontalHomecardWrapper;
