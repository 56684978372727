import { PureComponent } from 'react';

import HorizontalHomecardImage from './components/HorizontalHomecardImage';
import HorizontalHomecardContent from './components/HorizontalHomecardContent';
import HorizontalHomecardWrapper from './components/HorizontalHomecardWrapper';

class Homecard extends PureComponent {
  render() {
    return (
      <HorizontalHomecardWrapper
        {...this.props}
        renderImage={props => <HorizontalHomecardImage {...props} />}
        renderContent={props => <HorizontalHomecardContent {...props} />}
      />
    );
  }
}

export default Homecard;
