import seedrandom from 'seedrandom';

const SHIFT_AMOUNT = 0.0005;

export const shiftMarkers = (markers = []) =>
  markers.map(e => {
    const rng = seedrandom(e.id);
    e.coord[0] += rng() * SHIFT_AMOUNT - SHIFT_AMOUNT / 2;
    e.coord[1] += rng() * SHIFT_AMOUNT - SHIFT_AMOUNT / 2;

    return e;
  });

export default { shiftMarkers };
