import gql from 'graphql-tag';

const FACET_SEARCH_QUERY = gql`
  query FacetSearch($cityId: String!, $filters: FiltersInput) {
    facetSearch(cityId: $cityId, filters: $filters) {
      typeRoomShared
      typeApartment
      typeStudio
      typeResidence
      noDeposit
      verified
      wifi
      water
      gas
      electricity
      airConditioning
      washingMachine
      dishwasher
      balcony
      elevator
      oven
      heating
      couples
      students
      professionals
      pets
      withDesk
      ensuiteRoom
    }
  }
`;

export const getFacetSearchQuery = client => (cityId, filters) =>
  client.query({
    query: FACET_SEARCH_QUERY,
    fetchPolicy: 'cache-first',
    variables: {
      cityId,
      filters
    }
  });

export default { getFacetSearchQuery };
