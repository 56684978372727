export const getWindowTitle = () => window.title;
export const getWindowPathname = () => window.location.pathname;
export const getWindowHash = () => window.location.hash;
export const getWindowSearch = () => window.location.search;
export const navigateToOtherWindow = path => (window.location.href = path);
export const replaceWindowState = (...params) => {
  if (window.history.replaceState) {
    window.history.replaceState(...params);
  }
};

export default {
  getWindowTitle,
  getWindowPathname,
  getWindowHash,
  getWindowSearch,
  replaceWindowState,
  navigateToOtherWindow
};
