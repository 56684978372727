/* eslint-disable eqeqeq */
import isEqual from 'lodash/isEqual';
import Tracking from '@spotahome/soyuz-tracking';

import { FilterUtils } from '@spotahome/ui-library';

export const isFilterChecked = (filters, currentFilter, currentValue) => {
  const filterValue = filters[currentFilter];

  if (!filterValue) {
    return false;
  }

  return filterValue instanceof Array
    ? filterValue.includes(currentValue)
    : filterValue == currentValue; // soft equal to compare string with number
};

export const getEnabledValue = (filters, currentFilter, currentValue) => {
  Tracking.ga.sendEvent(
    'Search',
    'prefilter-enable',
    `${currentFilter}-${currentValue}`
  );
  const filterValue = filters[currentFilter];

  if (filterValue instanceof Array) {
    return [...filterValue, currentValue];
  }

  return currentValue;
};

export const getDisabledValue = (filters, currentFilter, currentValue) => {
  Tracking.ga.sendEvent(
    'Search',
    'prefilter-disable',
    `${currentFilter}-${currentValue}`
  );
  const filterValue = filters[currentFilter];

  if (filterValue instanceof Array) {
    return filterValue.filter(value => value !== currentValue);
  }

  if (typeof filterValue === 'number') {
    return 0;
  }

  return '';
};

export const getFilterValue = (filters, filterConfig) =>
  isFilterChecked(filters, filterConfig.filter, filterConfig.value)
    ? getDisabledValue(filters, filterConfig.filter, filterConfig.value)
    : getEnabledValue(filters, filterConfig.filter, filterConfig.value);

export const mustShowPrefilters = (filters, filtersConfig) => {
  const prefiltersToRender = filtersConfig.map(config => config.filter);

  return (
    Object.keys(filters)
      // filter out prefilter filters
      .filter(filterKey => !prefiltersToRender.includes(filterKey))
      // filter out filters we don't consider to count (it will exclude random queryParams like utm, experimentOverride ...)
      .filter(
        filterKey =>
          !FilterUtils.EXCLUDED_FILTERS_FROM_COUNT.includes(filterKey)
      )
      // filter out default filters
      .every(filterKey =>
        isEqual(filters[filterKey], FilterUtils.DEFAULT_FILTERS[filterKey])
      )
  );
};

export default {
  isFilterChecked,
  getFilterValue,
  mustShowPrefilters
};
