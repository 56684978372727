import PropTypes from 'prop-types';

const FlipBackIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="17"
    viewBox="0 0 23 17"
    className={props.className}
    {...props}
  >
    <path
      fill={props.color}
      fillRule="nonzero"
      d="M22.478 10.17c0 3.67-2.965 6.652-6.615 6.652H4.288v-1.663h11.575c2.763 0 4.961-2.21 4.961-4.99 0-2.778-2.198-4.989-4.96-4.989H3.803l1.895 1.907L4.53 8.263.638 4.349 4.53.435 5.7 1.61 3.802 3.517h12.06c3.65 0 6.615 2.982 6.615 6.653z"
      opacity=".639"
    />
  </svg>
);

FlipBackIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string
};

FlipBackIcon.defaultProps = {
  width: '16px',
  height: '13px',
  color: '#000',
  className: ''
};

export default FlipBackIcon;
