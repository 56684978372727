export const APARTMENT = 'apartment';
export const STUDIO = 'studio';
export const ROOM_SHARED = 'room_shared';
export const ROOM_SHARED_APT = 'room_shared_apt';
export const RESIDENCE = 'residence';
export const LISTING_TYPES = [
  APARTMENT,
  STUDIO,
  ROOM_SHARED,
  RESIDENCE,
  ROOM_SHARED_APT
];

export const isApartment = type => type === APARTMENT;
export const isRoom = type => type === ROOM_SHARED;
