import gql from 'graphql-tag';

import { Mediator } from '@spotahome/soyuz-mediator';

import { COMMON_LOGIN_MODAL } from '../../channels';

import {
  adaptAlertDataToServer,
  adaptAlertDataToClient
} from '../adapters/alerts';

const REQUESTED_DATA = `
  id
  cityId
  moveIn
  moveOut
  moveInFrom
  moveInTo
  moveOutFrom
  moveOutTo
  minPrice
  maxPrice
  propertyTypes
  mediums
  cityAreaIds
  bathrooms
  bed
  features
  instantBooking
  noDeposit
  verified
  topology
  rentalType
`;

const MUTATE_REMOVE_ALERT = gql`
  mutation RemoveAlert($id: ID!) {
    removeAlert(id: $id)
  }
`;

const MUTATE_ADD_ALERT = gql`
  mutation Alert($input: AlertInput) {
    addAlert(input: $input) {
      ${REQUESTED_DATA}
    }
  }
`;

const triggerTokenError = err => {
  if (err.message === 'GraphQL error: Token expired') {
    Mediator.publish(COMMON_LOGIN_MODAL);
  }
};

const mutateAlert = (client, mutation, variables) =>
  client
    .mutate({
      mutation,
      variables: adaptAlertDataToServer(variables)
    })
    .catch(triggerTokenError);

export const removeAlert = client => id =>
  client
    .mutate({
      mutation: MUTATE_REMOVE_ALERT,
      variables: { id }
    })
    .catch(triggerTokenError);

export const addAlert = client => variables =>
  mutateAlert(client, MUTATE_ADD_ALERT, variables).then(response =>
    adaptAlertDataToClient(response, 'addAlert')
  );

export default {
  removeAlert,
  addAlert
};
