import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { getPagePath, trans, useSoyuzLocales } from '@spotahome/soyuz/client';

import classNames from 'classnames';

import Crumb from './components/Crumb';
import CrumbSeparator from './components/CrumbSeparator';

import { getCountryUrl } from './getCountryUrl';

import styles from './SearchBreadcrumbs.module.scss';

const crumbsBuilder = ({
  cityId,
  cityName,
  countryId,
  propertyType,
  topology,
  anchorText = null,
  locale
}) => {
  const type = propertyType[0];
  const countryUrl = getCountryUrl(countryId, locale);
  const cityHref = getPagePath('marketplace.free-search', {
    params: { locationString: cityId }
  });

  const crumbs = [
    {
      label: trans('breadcrumb.label.home'),
      href: getPagePath('landing')
    },
    countryUrl
      ? {
          label: trans(`country.${countryId}`),
          href: countryUrl
        }
      : null,
    {
      label: cityName,
      href: cityHref
    },
    propertyType?.length === 1 && type
      ? {
          label: trans(`breadcrumb.label.${type}_for_rent`),
          href: `${cityHref}/for-rent:${type}`
        }
      : null,
    propertyType?.length === 1 && topology?.length === 1 && type && topology[0]
      ? {
          label: trans(`search.others.apartments-${topology[0]}`),
          href: `${cityHref}/for-rent:${type}/bedrooms:${topology[0]}`
        }
      : null,
    anchorText
      ? {
          label: anchorText,
          href: ''
        }
      : null
  ];

  return crumbs.filter(Boolean);
};

const SearchBreadcrumbs = ({
  cityId,
  cityName,
  countryId,
  propertyType = [],
  topology = [],
  anchorText = null,
  className = ''
}) => {
  const { current: locale } = useSoyuzLocales();

  const crumbs = crumbsBuilder({
    cityId,
    cityName,
    countryId,
    propertyType,
    topology,
    anchorText,
    locale
  });

  return (
    <div
      className={classNames(styles['search-breadcrumbs'], className)}
      itemScope
      itemType="https://schema.org/BreadcrumbList"
    >
      {crumbs.map((crumb, i) => {
        const isLast = i === crumbs.length - 1;
        return (
          <Fragment key={crumb.label}>
            <Crumb
              label={crumb.label}
              href={isLast ? null : crumb.href}
              position={i + 1}
            />
            {!isLast && <CrumbSeparator />}
          </Fragment>
        );
      })}
    </div>
  );
};

SearchBreadcrumbs.propTypes = {
  cityId: PropTypes.string.isRequired,
  cityName: PropTypes.string.isRequired,
  countryId: PropTypes.string.isRequired,
  propertyType: PropTypes.arrayOf(PropTypes.string),
  topology: PropTypes.arrayOf(PropTypes.string),
  anchorText: PropTypes.string,
  className: PropTypes.string
};

export default SearchBreadcrumbs;
