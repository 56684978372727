import { trans } from '@spotahome/soyuz/client';

import { Button } from '@spotahome/ui-library';

/*
 * Toggled out until we re-implement Select at some point in the future
 * import SpotahomeSelectLogo from '@common/components/SpotahomeSelectLogo';
 */
import HomeCardBannerImage from '../../components/HomeCardBannerImage';

import './SelectBannerFront.scss';

const SelectBannerFront = () => (
  <HomeCardBannerImage bannerName={'select'} fullHeight>
    <div className="select-banner-front__content">
      {/* <SpotahomeSelectLogo width={'86px'} /> */}
      <div className="select-banner-front__title">
        {trans(`homecard.banner.select.title`)}
      </div>
      <Button alt className={'select-banner-front__button'} color={'select'}>
        <span className="select-banner-front__button--text">
          {trans(`homecard.banner.select.button.text`)}
        </span>
      </Button>
    </div>
  </HomeCardBannerImage>
);

export default SelectBannerFront;
