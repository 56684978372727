import { getPagePath } from '@spotahome/soyuz/client';
import { SearchUrlGeneratorUtils } from '@spotahome/ui-library';

import {
  getWindowTitle,
  getWindowPathname,
  getWindowHash,
  getWindowSearch,
  replaceWindowState
} from './windowHelper';

const getMoveDatesAsQueryParams = (moveIn, moveOut) => {
  const getParams = [];

  if (moveIn) {
    getParams.push(`move-in=${moveIn}`);
  }

  if (moveOut) {
    getParams.push(`move-out=${moveOut}`);
  }

  let result = '';

  if (getParams.length > 0) {
    result = `?${getParams.join('&')}`;
  }

  return result;
};

const queryStringToJSON = str => {
  const pairs = str.split('&');
  const result = {};
  pairs.forEach(pair => {
    const [name, value] = pair.split('=');
    if (name.length) {
      if (name.indexOf('[]') > -1) {
        if (result[name] === undefined) {
          result[name] = [];
        }

        result[name].push(value || '');
      } else {
        result[name] = value || '';
      }
    }
  });

  return result;
};

const JSONtoQueryString = object => {
  let queryString = '';

  Object.entries(object).forEach(([key, value], index) => {
    const appendingQueryPrefix = index === 0 ? '' : '&';
    if (Array.isArray(value)) {
      value.forEach(val => {
        queryString = queryString.concat(
          `${appendingQueryPrefix}${key}[]=${val}`
        );
      });
    } else {
      queryString = queryString.concat(
        `${appendingQueryPrefix}${key}=${value}`
      );
    }
  });

  return queryString;
};

const getQueryStringFromUrl = () => {
  const url = getWindowSearch();
  const pattern = new RegExp('\\?(.+=.+)', 'i');

  const matches = pattern.exec(url);

  /**
   * no query string set
   */
  if (matches === null) {
    return '';
  }

  return matches[1];
};

/**
 * Respecting RFC3986: http://tools.ietf.org/html/rfc3986#section-4.1
 */
const getUrl = (markerOrCard, queryString) => {
  return markerOrCard.url + queryString;
};

const getUrlParts = (urlState = {}) => {
  return Object.entries(urlState)
    .filter(
      ([queryParamName, queryParamValue]) =>
        queryParamName !== '' && queryParamValue !== ''
    )
    .map(
      ([queryParamName, queryParamValue]) =>
        `${queryParamName}=${queryParamValue}`
    );
};

const getLocationUrlWithNewUrlState = (urlState = {}) => {
  const searchParams = new URLSearchParams(getWindowSearch());
  Object.entries(urlState).forEach(([queryParamName, queryParamValue]) => {
    searchParams.set(queryParamName, queryParamValue);
  });

  if (searchParams.toString()) {
    return `${getWindowPathname()}?${searchParams.toString()}${getWindowHash()}`;
  }
  return `${getWindowPathname()}${getWindowHash()}`;
};

const setLocationUrl = urlState => {
  const url = getLocationUrlWithNewUrlState(urlState);

  replaceWindowState(urlState, getWindowTitle(), url);
};

const createTranslatedQueryString = (filters = {}) => {
  const urlInParts = [];
  const urlTranslated = [];
  const returnQueryString = {};

  Object.keys(filters).forEach(key => {
    // eslint-disable-next-line no-useless-escape
    const simplifiedKey = key.replace(/[\[\]']+/g, '');

    if (
      filters.seoMode &&
      SearchUrlGeneratorUtils.shouldIgnoreSeoKey(simplifiedKey)
    ) {
      return;
    }

    if (
      SearchUrlGeneratorUtils.shouldAvoidParamInTheUrl(
        simplifiedKey,
        filters[simplifiedKey]
      )
    ) {
      return;
    }

    if (SearchUrlGeneratorUtils.filterBelongsToPath(simplifiedKey)) {
      const urlParam = SearchUrlGeneratorUtils.generatePathForFilter(
        simplifiedKey,
        filters[key]
      );
      if (urlParam) {
        urlTranslated.push(urlParam);
      }
    } else {
      const urlQuery = SearchUrlGeneratorUtils.generateQueryForFilter(
        key,
        filters[key]
      );
      if (urlQuery) {
        urlInParts.push(urlQuery);
      }
    }
  });

  if (urlTranslated.length) {
    returnQueryString.params = urlTranslated.join('/');
  }
  if (urlInParts.length) {
    returnQueryString.queryString = urlInParts.join('&');
  }

  return returnQueryString;
};

const createQueryString = filters => {
  return Object.keys(filters)
    .map(key =>
      SearchUrlGeneratorUtils.generateQueryForFilter(key, filters[key])
    )
    .filter(Boolean)
    .join('&');
};

const getSearchUrl = (locationString, query) => {
  const params = { locationString };

  let url = getPagePath('marketplace.free-search', {
    params
  });
  url += query.params ? `/${query.params}` : '';
  url += query.queryString ? `?${query.queryString}` : '';

  return url;
};

const getSearchUrlFromFilters = (locationString, filters) => {
  const query = createTranslatedQueryString(filters);
  return getSearchUrl(locationString, query);
};

const setSearchLocationUrl = (locationString, filters) => {
  if (typeof window !== 'undefined') {
    const queryString = createTranslatedQueryString(filters);

    const url = getSearchUrl(locationString, queryString);

    replaceWindowState({ queryString }, getWindowTitle(), url);
  }
};

const getQueryParamValue = paramName => {
  const urlParams = new URLSearchParams(getWindowSearch());
  return urlParams.get(paramName);
};

const setQueryParameter = (key, value) => {
  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(getWindowSearch());
    params.set(key, value);
    const query = params.toString();

    replaceWindowState(
      null,
      getWindowTitle(),
      `${getWindowPathname()}?${query}${getWindowHash()}`
    );
  }
};

const deleteQueryParameter = key => {
  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(getWindowSearch());
    params.delete(key);
    const query = params.toString();

    replaceWindowState(
      null,
      getWindowTitle(),
      `${getWindowPathname()}?${query}${getWindowHash()}`
    );
  }
};

export default {
  deleteQueryParameter,
  setQueryParameter,
  setSearchLocationUrl,
  getSearchUrl,
  getSearchUrlFromFilters,
  createQueryString,
  createTranslatedQueryString,
  setLocationUrl,
  getLocationUrlWithNewUrlState,
  getUrlParts,
  getUrl,
  getQueryStringFromUrl,
  queryStringToJSON,
  JSONtoQueryString,
  getMoveDatesAsQueryParams,
  getQueryParamValue
};
