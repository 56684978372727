import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import CloseButton from '../CloseButton';

import styles from './AlertBox.module.scss';

const CENTER = 'center';
const TOP = 'top';

const ICON_POSITIONS = [CENTER, TOP];

const AlertBox = ({
  className,
  theme,
  icon,
  iconPosition,
  title,
  message,
  roundedCorners,
  closeButton,
  inlineContent
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
  };

  const cnIconMobile = classNames(
    styles['alert-box__icon'],
    styles[`alert-box__icon--mobile`]
  );

  const cnIconWeb = classNames(
    styles['alert-box__icon'],
    styles[`alert-box__icon--web`],
    {
      [styles[`alert-box__icon--web-top`]]: iconPosition === TOP
    }
  );

  const cnIconClose = classNames(
    styles['alert-box__icon'],
    styles[`alert-box__icon--close`],
    {
      [styles[`alert-box__icon--close-top`]]: iconPosition === TOP
    }
  );

  const componentClasses = classNames(
    styles['alert-box'],
    styles[`alert-box--${theme}`],
    {
      [styles['alert-box--rounded-corners']]: roundedCorners
    },
    className
  );

  const contentClasses = classNames(styles['alert-box__content'], {
    [styles['alert-box__content--inline']]: inlineContent
  });

  const titleClasses = classNames(styles['alert-box__title'], {
    [styles['alert-box__title--inline']]: inlineContent
  });

  return (
    !!isOpen && (
      <div className={componentClasses}>
        <div className={cnIconWeb}>{icon}</div>
        <div className={contentClasses}>
          <div className={titleClasses}>
            <div className={cnIconMobile}>{icon}</div>
            {title}
          </div>
          <div className={`${styles['alert-box__message']}`}>{message}</div>
        </div>
        {!!closeButton && (
          <div className={cnIconClose}>
            <CloseButton onClick={handleClose} />
          </div>
        )}
      </div>
    )
  );
};

AlertBox.propTypes = {
  theme: PropTypes.oneOf(['info', 'suggestion', 'suggestion-light']),
  className: PropTypes.string,
  icon: PropTypes.node,
  iconPosition: PropTypes.oneOf(ICON_POSITIONS),
  title: PropTypes.string,
  message: PropTypes.node.isRequired,
  roundedCorners: PropTypes.bool,
  inlineContent: PropTypes.bool,
  closeButton: PropTypes.bool
};

AlertBox.defaultProps = {
  theme: 'info',
  className: '',
  icon: null,
  iconPosition: CENTER,
  title: '',
  roundedCorners: false,
  closeButton: false,
  inlineContent: false
};

export default AlertBox;
