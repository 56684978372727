import { forwardRef } from 'react';
import PropTypes from 'prop-types';

const InstantBookingLabelIcon = forwardRef(
  ({ size = 40, className = '', ...props }, ref) => (
    <svg
      {...props}
      className={className}
      ref={ref}
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 16 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.65444 10.0685L8.51421 10.5904L8.9649 10.8886L14.4008 14.4853L5.30975 23.508L7.34548 15.9315L7.48571 15.4096L7.03502 15.1114L1.59911 11.5147L10.6902 2.49197L8.65444 10.0685Z"
        stroke="#0D0D0D"
        strokeWidth="1.5"
      />
    </svg>
  )
);

InstantBookingLabelIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string
};

export default InstantBookingLabelIcon;
