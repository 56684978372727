import classNames from 'classnames';

import styles from '../FooterSimplified.module.scss';

const FooterSimplifiedBlock = props => (
  <div className={classNames(styles.footer__block, props.className)}>
    {props.children}
  </div>
);
export default FooterSimplifiedBlock;
