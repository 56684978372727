import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz/client';

import BILLS from '../../../Filters/utils/bills-config';
import { formatBudget } from '../../../utils/filters';

import ExposedFilter from '../ExposedFilter';
import CheckboxSectionFilter from '../CheckboxSectionFilter';

import { useFiltersContext } from '../../../..';

import PriceSliderFilter from './PriceSliderFilter';
import PriceRangeHelper from './PriceRangeHelper';

const ADVANCED_OPTIONS = [
  {
    name: 'noDeposit',
    labelKey: 'search.filter.noDeposit',
    tracking: 'ga-search-form-noDeposit',
    cy: 'advanced-noDeposit',
    tooltipTextKey: 'search.filter.noDeposit.tooltip'
  }
];

const BILLS_NAMES = BILLS.map(({ name }) => name);

const BudgetFilter = ({
  isFilterOpen,
  onToggleFilter,
  currencyIsoCode,
  onFiltersUpdate,
  filters,
  totalResults,
  sortingAlgorithm,
  maxPriceInCity,
  cityId,
  showResults
}) => {
  const { facetSearch } = useFiltersContext();

  const selectedBills = BILLS_NAMES.filter(billName =>
    filters['features[]']?.includes(billName)
  );

  const renderButtonContent = () => {
    return filters.budget
      ? formatBudget(filters.budget, currencyIsoCode)
      : trans('filters.label.budget');
  };

  const handleAdvancedFilterUpdate = selectedItems => {
    const formattedSelectdItems = ADVANCED_OPTIONS.reduce(
      (acc, item) => ({
        ...acc,
        [item.name]: selectedItems.includes(item.name) ? 1 : 0
      }),
      {}
    );

    onFiltersUpdate(formattedSelectdItems);
  };

  const handleBillsFilterUpdate = selectedItems => {
    onFiltersUpdate({
      'features[]': selectedItems
    });
  };

  const renderFilterContent = () => {
    return (
      <Fragment>
        <PriceSliderFilter
          budget={filters.budget}
          currencyIsoCode={currencyIsoCode}
          maxPriceInCity={maxPriceInCity}
          updateFiltersWith={onFiltersUpdate}
          filters={filters}
          sortingAlgorithm={sortingAlgorithm}
          cityId={cityId}
        />

        <PriceRangeHelper
          budget={filters.budget}
          currencyIsoCode={currencyIsoCode}
          updateFiltersWith={onFiltersUpdate}
          maxPriceInCity={maxPriceInCity}
        />

        <CheckboxSectionFilter
          onFiltersUpdate={handleAdvancedFilterUpdate}
          options={ADVANCED_OPTIONS}
          selectedItems={ADVANCED_OPTIONS.map(option => option.name).filter(
            optionName => filters[optionName] === 1
          )}
          titleKey="search.filter.advanced-search"
          facetSearch={facetSearch}
        />

        <CheckboxSectionFilter
          onFiltersUpdate={handleBillsFilterUpdate}
          options={BILLS}
          selectedItems={filters['features[]'] || []}
          titleKey="search.filter.bills"
          facetSearch={facetSearch}
        />
      </Fragment>
    );
  };

  return (
    <ExposedFilter
      isFilterOpen={isFilterOpen}
      onToggleFilter={onToggleFilter}
      renderButtonContent={renderButtonContent}
      renderFilterContent={renderFilterContent}
      totalResults={totalResults}
      showResults={showResults}
      filterConfig={{
        name: 'budget',
        title: trans('filters.label.budget'),
        subtitle: trans('exposed-filters.dropdown.price.description'),
        eventLabel: 'apply-price',
        dataTestOpen: 'price-exposed-filter-button',
        dataTestApply: 'price-exposed-filter-apply',
        isSelected: !!filters.budget || !!selectedBills.length
      }}
    />
  );
};

BudgetFilter.propTypes = {
  isFilterOpen: PropTypes.bool.isRequired,
  onToggleFilter: PropTypes.func.isRequired,
  currencyIsoCode: PropTypes.string.isRequired,
  sortingAlgorithm: PropTypes.string.isRequired,
  onFiltersUpdate: PropTypes.func.isRequired,
  totalResults: PropTypes.number.isRequired,
  filters: PropTypes.shape({
    flexibleMoveIn: PropTypes.number,
    flexibleMoveOut: PropTypes.number,
    budget: PropTypes.string,
    noDeposit: PropTypes.number,
    'move-in': PropTypes.string,
    'move-out': PropTypes.string,
    'rentalType[]': PropTypes.arrayOf(PropTypes.string),
    'type[]': PropTypes.arrayOf(PropTypes.string),
    'features[]': PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  maxPriceInCity: PropTypes.number.isRequired,
  cityId: PropTypes.string.isRequired,
  showResults: PropTypes.bool.isRequired
};

export default BudgetFilter;
