import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';
import SoyuzAnalytics from '@spotahome/soyuz-analytics';

import { trans } from '@spotahome/soyuz/client';

import { useFiltersContext } from '../../../../context/FiltersContext';

import FormGroupToggle from '../../../FormGroupToggle';
import CheckboxWithLabel from '../../../CheckboxWithLabel';

import './PropertyTypeFilter.scss';

const NUMBER_ROOMS_TOGGLE = [
  {
    id: 'f1-id',
    value: '1',
    label: '1',
    tracking: 'ga-search-form-toggle-1-bedroom',
    cy: 'rooms-1'
  },
  {
    id: 'f2-id',
    value: '2',
    label: '2',
    tracking: 'ga-search-form-toggle-2-bedroom',
    cy: 'rooms-2'
  },
  {
    id: 'f3-id',
    value: '3',
    label: '3',
    tracking: 'ga-search-form-toggle-3-bedroom',
    cy: 'rooms-3'
  },
  {
    id: 'f3more-id',
    value: '3more',
    label: '+3',
    tracking: 'ga-search-form-toggle-3more-bedroom',
    cy: 'rooms-3more'
  }
];

const getTopologyLabel = r => {
  if (r?.length === 0) {
    return trans('search.filter.any_rooms');
  }

  const rooms = [...r];

  const ROOM_KEYS = {
    1: 1,
    2: 2,
    3: 3,
    '3more': trans('search.filter.more_than_3')
  };

  const roomKeys = rooms.sort().map(room => ROOM_KEYS[room]);

  if (roomKeys.length === 1) {
    return trans('search.filter.show_apartment_single', {
      number: roomKeys[0]
    });
  }

  const last = rooms.pop();
  return trans('search.filter.show_apartments', {
    number: rooms.join(', '),
    last
  });
};

const CheckboxWithLabelWithFacet = props => {
  const { facetSearch, filters } = useFiltersContext();

  const count = facetSearch?.[props.type];
  const isAnyTypeChecked = filters['type[]']?.length > 0;
  return (
    <CheckboxWithLabel showCount={!isAnyTypeChecked} count={count} {...props} />
  );
};

CheckboxWithLabelWithFacet.propTypes = {
  type: PropTypes.string.isRequired
};

const PropertyTypeFilterDropdown = ({
  filters = {
    'topology[]': [],
    'type[]': []
  },
  onFiltersUpdate
}) => {
  const topologies = filters['topology[]'] || [];
  const handleToggleCheckbox = (name, value, isChecked) => {
    SoyuzAnalytics.sendGA4Event('filter', {
      context: 'property',
      section: value,
      action: isChecked ? 'check' : 'uncheck'
    });
    let options = filters[name] || [];
    if (isChecked) {
      options = [...options, value];
    } else {
      options = options.filter(option => option !== value);
    }

    onFiltersUpdate({
      [name]: uniq(options)
    });
  };

  const handleApartmentsFilterTypeCheckbox = value => isChecked =>
    handleToggleCheckbox('type[]', value, isChecked);

  const isValueChecked = (arr = [], value) => arr.includes(value);

  const isPropertyTypeChecked = value =>
    isValueChecked(filters['type[]'], value);

  const handleToggleFilterTypeCheckbox = value => isChecked =>
    handleToggleCheckbox('type[]', value, isChecked);

  const handleTopologyClick = (name, value, isChecked) =>
    handleToggleCheckbox('topology[]', value, isChecked);

  const getSelectedTopologies = () => topologies.join(',');

  return (
    <div>
      <CheckboxWithLabelWithFacet
        id="apartment-id"
        name="apartment-id"
        type="typeApartment"
        text={trans('search.filter.apartments')}
        callback={handleApartmentsFilterTypeCheckbox('apartments')}
        checked={isPropertyTypeChecked('apartments')}
        className="property-type-filter__checkbox ga-search-form-apartments"
        dataAttributes={{ test: 'apartment-id' }}
      />
      {isPropertyTypeChecked('apartments') && (
        <>
          <span className="show-apartments-text">
            {getTopologyLabel(topologies)}
          </span>
          <FormGroupToggle
            onChange={handleTopologyClick}
            selectedValues={getSelectedTopologies()}
            options={NUMBER_ROOMS_TOGGLE}
          />
        </>
      )}
      <CheckboxWithLabelWithFacet
        id="studios-id"
        name="studios-id"
        type="typeStudio"
        text={trans('search.filter.studios')}
        callback={handleToggleFilterTypeCheckbox('studios')}
        checked={isPropertyTypeChecked('studios')}
        className="property-type-filter__checkbox ga-search-form-studios"
        dataAttributes={{ test: 'studios-id' }}
      />
      <CheckboxWithLabelWithFacet
        id="rooms-id"
        name="rooms-id"
        type="typeRoomShared"
        text={trans('search.filter.rooms')}
        callback={handleToggleFilterTypeCheckbox('rooms')}
        checked={isPropertyTypeChecked('rooms')}
        className="property-type-filter__checkbox ga-search-form-rooms"
        dataAttributes={{ test: 'rooms-id' }}
      />
      <CheckboxWithLabelWithFacet
        id="residences-id"
        name="residences-id"
        type="typeResidence"
        text={trans('search.filter.residences')}
        callback={handleToggleFilterTypeCheckbox('residences')}
        checked={isPropertyTypeChecked('residences')}
        className="property-type-filter__checkbox ga-search-form-residences"
        dataAttributes={{ test: 'residences-id' }}
      />
    </div>
  );
};

PropertyTypeFilterDropdown.propTypes = {
  filters: PropTypes.shape({
    'topology[]': PropTypes.arrayOf(PropTypes.string),
    'type[]': PropTypes.arrayOf(PropTypes.string)
  }),
  onFiltersUpdate: PropTypes.func.isRequired
};

export default PropertyTypeFilterDropdown;
