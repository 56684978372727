import { useState, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz/client';

import SoyuzAnalytics from '@spotahome/soyuz-analytics';

import { getBudgetValue } from '../../../utils/filters';

import PriceInput from '../../../PriceInput';

import './PriceRangeHelper.scss';

const splitBudget = budget => {
  const [min, max] = budget?.split('-');
  const budgetMin = parseInt(min, 10) || 0;
  const budgetMax = parseInt(max, 10) || '';

  return [budgetMin, budgetMax];
};
const isValidPriceRange = ({ minPrice, maxPrice }) =>
  (minPrice === null && maxPrice === null) ||
  minPrice <= maxPrice ||
  (minPrice && !maxPrice);

const PriceRangeHelper = ({
  budget = '',
  currencyIsoCode,
  updateFiltersWith,
  maxPriceInCity
}) => {
  const debounceUpdateFiltersWith = useRef(debounce(updateFiltersWith, 300));

  const [minBudget, setMinBudget] = useState(null);
  const [maxBudget, setMaxBudget] = useState(null);

  useEffect(() => {
    const [budgetMin, budgetMax] = splitBudget(budget);
    setMinBudget(budgetMin);
    setMaxBudget(budgetMax);
  }, [budget]);

  const updateBudget = ({ minPrice, maxPrice }) => {
    if (!isValidPriceRange({ minPrice, maxPrice })) {
      return;
    }

    const budgetValue = getBudgetValue({
      minBudget: minPrice || 0,
      maxBudget: maxPrice
    });
    debounceUpdateFiltersWith.current({ budget: budgetValue });
  };

  const handleMinPriceChange = ({ value }) => {
    if (Number.isNaN(parseInt(value, 10))) {
      return;
    }

    setMinBudget(value);
    updateBudget({ minPrice: value, maxPrice: maxBudget });
    SoyuzAnalytics.sendGA4Event('filter', {
      context: 'budget',
      section: 'min-price'
    });
  };

  const handleMaxPriceChange = ({ value }) => {
    if (Number.isNaN(parseInt(value, 10))) {
      return;
    }

    setMaxBudget(value);
    updateBudget({ maxPrice: value, minPrice: minBudget });
    SoyuzAnalytics.sendGA4Event('filter', {
      context: 'budget',
      section: 'max-price'
    });
  };

  const hasError = !isValidPriceRange({
    minPrice: minBudget,
    maxPrice: maxBudget
  });

  return (
    <>
      <div className="price-range-helper__inputs">
        <div className="price-range-helper__input">
          <PriceInput
            title={trans('filters.price.from')}
            name="minPrice"
            id="minPrice"
            handleOnChange={handleMinPriceChange}
            currencyIsoCode={currencyIsoCode}
            value={minBudget}
            max={minBudget}
            placeholder="0"
            hasError={hasError}
          />
        </div>
        <span className="price-range-helper__separator">-</span>
        <div className="price-range-helper__input">
          <PriceInput
            title={trans('filters.price.to')}
            name="maxPrice"
            id="maxPrice"
            handleOnChange={handleMaxPriceChange}
            currencyIsoCode={currencyIsoCode}
            value={maxBudget}
            min={maxBudget}
            placeholder={`${maxPriceInCity}+`}
            hasError={hasError}
          />
        </div>
      </div>

      {hasError && (
        <p className="price-range-helper__error">
          {trans('search.filters.price-range-helper.error')}
        </p>
      )}
    </>
  );
};

PriceRangeHelper.propTypes = {
  budget: PropTypes.string,
  currencyIsoCode: PropTypes.string.isRequired,
  updateFiltersWith: PropTypes.func.isRequired,
  maxPriceInCity: PropTypes.number.isRequired
};

export default PriceRangeHelper;
