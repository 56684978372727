import PropTypes from 'prop-types';

import Colors from '../utils/colors';

import styles from './FavoriteIconWithBorder.module.scss';

const FavoriteIconWithBorder = ({ active = false }) => (
  <svg
    width="24"
    height="23"
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={styles.favorite}
      d="M11.3335 2.57935L12.0015 3.17819L12.6691 2.57884C15.0951 0.400671 18.8461 0.473697 21.1832 2.81451C23.5155 5.15308 23.6012 8.87138 21.4464 11.3052L11.9987 20.7663L2.55324 11.3052C0.398181 8.87117 0.485755 5.14652 2.81532 2.8156C5.15568 0.476268 8.89961 0.397542 11.3335 2.57935Z"
      fill={active ? Colors.primary : Colors.medium}
      stroke="white"
      opacity={1}
      strokeWidth="2"
    />
  </svg>
);

FavoriteIconWithBorder.propTypes = {
  active: PropTypes.bool
};

export default FavoriteIconWithBorder;
