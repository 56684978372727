import PropTypes from 'prop-types';

const SIZES = {
  small: {
    width: '9px',
    height: '19px'
  },
  base: {
    width: '10px',
    height: '35px'
  }
};

const RIGHT_ARROW = {
  points: '1.37 0 0.009 1.464 17.08 17.312 0 33.551 1.379 35 20 17.294'
};

const LEFT_ARROW = {
  transform:
    'translate(10.000000, 17.500000) rotate(-180.000000) translate(-10.000000, -17.500000)',
  points:
    '1.37 -8.17124146e-14 0.009 1.464 17.08 17.312 -4.45865567e-13 33.551 1.379 35 20 17.294'
};

const GalleryArrow = ({
  className = '',
  color = 'white',
  size = 'base',
  polygonProps,
  dataTest = ''
}) => (
  <svg
    width={SIZES[size].width}
    height={SIZES[size].height}
    viewBox="0 0 20 35"
    version="1.1"
    className={className}
    data-test={dataTest}
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon fill={color} {...polygonProps} />
    </g>
  </svg>
);

GalleryArrow.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(SIZES)),
  polygonProps: PropTypes.oneOf([RIGHT_ARROW, LEFT_ARROW]).isRequired,
  dataTest: PropTypes.string
};

export const GalleryArrowRight = props => (
  <GalleryArrow
    {...props}
    dataTest="gallery-arrow-right"
    polygonProps={RIGHT_ARROW}
  />
);

export const GalleryArrowLeft = props => (
  <GalleryArrow
    {...props}
    dataTest="gallery-arrow-left"
    polygonProps={LEFT_ARROW}
  />
);

export default { GalleryArrowRight, GalleryArrowLeft };
