export default [
  {
    id: 'fsingle-id',
    value: 'single-group',
    tracking: 'ga-search-form-toggle-single-bed',
    icon: 'single-bed',
    cy: 'bed-single-group'
  },
  {
    id: 'fdouble-id',
    value: 'double',
    tracking: 'ga-search-form-toggle-double-bed',
    icon: 'double-bed',
    cy: 'bed-double'
  },
  {
    id: 'ftwin-id',
    value: 'twin',
    label: '2',
    tracking: 'ga-search-form-toggle-twin-beds',
    icon: 'single-bed',
    cy: 'bed-twin'
  }
];
