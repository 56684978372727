import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FilterGroup = memo(({ children, label, tooltip, classModifier }) => {
  const getClassName = () => {
    const modifier = classModifier ? `filter-group--${classModifier}` : '';
    return classNames('filter-group', modifier);
  };

  return (
    <div className={getClassName()}>
      <div className="filter-group-left-side">
        <div className="filter-group-left-side__label">
          <span>{label}</span>
          {tooltip()}
        </div>
      </div>
      <div className="filter-group-right-side">{children}</div>
    </div>
  );
});

FilterGroup.propTypes = {
  label: PropTypes.string,
  classModifier: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  tooltip: PropTypes.func
};

FilterGroup.defaultProps = {
  label: '',
  classModifier: '',
  children: null,
  tooltip: () => {}
};

export default FilterGroup;
