import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useThemeFlag } from '../../../base/ThemeProvider';

import ChevronDown from '../icons/ChevronDown';
import ChevronUp from '../icons/ChevronUp';
import ChevronAscend from '../icons/ChevronAscend';
import ChevronDescend from '../icons/ChevronDescend';
import Tooltip, { withTooltip } from '../Tooltip';
import BaseChip from '../BaseChip';

import styles from './Chip.module.scss';

const Chip = withTooltip(BaseChip, [Tooltip.POSITION_TOP]);

Chip.Filter = ({
  isOpen = false,
  isSelected = false,
  isOverlay = false,
  showChevron = false,
  ...props
}) => {
  const iconClassNames = classNames(styles['chip-dropdown__icon']);

  const ChevronIcon = isOpen ? ChevronUp : ChevronDown;

  const Chevron = isOpen ? ChevronAscend : ChevronDescend;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const newButtonsEnabled = useThemeFlag('newButtons');

  let iconTheme = 'dark';

  if (isOverlay) {
    iconTheme = 'light';
  } else if (isSelected) {
    iconTheme = 'interaction';
  }

  return (
    <Chip
      withBorder
      isOverlay={isOverlay}
      isSelected={isSelected}
      colorSchema={BaseChip.SCHEMAS.filter}
      renderTrailingIcon={() =>
        showChevron ? (
          <span className={iconClassNames}>
            {newButtonsEnabled ? (
              <Chevron theme={iconTheme} />
            ) : (
              <ChevronIcon theme={iconTheme} />
            )}
          </span>
        ) : null
      }
      {...props}
    />
  );
};

Chip.Filter.propTypes = {
  ...Chip.propTypes,
  isOpen: PropTypes.bool,
  isSelected: PropTypes.bool,
  showChevron: PropTypes.bool,
  isOverlay: PropTypes.bool
};

Chip.Choice = ({ isActivated = false, isDarkMode = false, ...props }) => (
  <Chip
    withBorder
    isActivated={isActivated}
    {...props}
    colorSchema={BaseChip.SCHEMAS.choice}
    isDarkMode={isDarkMode}
  />
);

Chip.Choice.propTypes = {
  ...Chip.propTypes,
  isActivated: PropTypes.bool,
  isDarkMode: PropTypes.bool
};

export default Chip;
