import { trans } from '@spotahome/soyuz/client';

import * as DateRangeHandler from './DateRangeHandler';

const DISCOUNT_TYPE_PERCENTAGE = 'percentage';
const DISCOUNT_TYPE_FIXED = 'fixed';

/**
 * Checks if num is between start and up to end.
 * If end is not specified, it checks num is equal or greater than start.
 * If start is greater than end the params are swapped to support negative ranges.
 * @param {number} num the number to be checked
 * @param {number} start the start of the range
 * @param {number} end the end of the range
 * @return {boolean}
 */
const isInRange = (num, start, end) => {
  if (!end) {
    return num >= start;
  }
  return (num - start) * (num - end) <= 0;
};

/**
 * Calculates the staying days between two dates
 * @example getStayingDaysDiff('2020-01-01', '2020-01-01') // 1
 * @param {Date|String} moveIn the number to be checked
 * @param {Date|String} moveOut the start of the range
 * @return {Number} The number of staying days
 */
const getStayingDaysDiff = (moveIn, moveOut) => {
  return DateRangeHandler.getDaysDiff(moveIn, moveOut) + 1;
};

/**
 * This method checks if for the pair move in & move out dates there's
 * an eligible offer available. If more than one it returns the first one.
 *
 * If some date is not given, then it returns all offers as potentially
 * eligible offers
 * @param {array} offers the listing offers
 * @param {object} dates the staying tenant dates
 * @return {object} the eligible offer
 */
export const getEligibleOffer = (offers, { moveIn, moveOut } = {}) => {
  if (!offers?.length) {
    return null;
  }

  if (!moveIn || !moveOut) {
    return offers;
  }

  const stayingPeriodInDays = getStayingDaysDiff(moveIn, moveOut);

  return offers.find(({ minStay, maxStay }) =>
    isInRange(stayingPeriodInDays, minStay, maxStay)
  );
};

export const hasEligibleOffer = (...args) => !!getEligibleOffer(...args);

export const getBestEligibleOffer = offers =>
  offers.reduce(
    (acc, offer) => (acc.discountValue > offer.discountValue ? acc : offer),
    { discountValue: 0 }
  );

export const getOfferAmount = ({
  discountValue,
  discountType,
  currencySymbol
}) => `${discountValue}${discountType === 'percentage' ? '%' : currencySymbol}`;

export const getOfferRange = (
  { minStay, maxStay, discountValue, discountType },
  currencySymbol
) => {
  if (!minStay || (maxStay && minStay >= maxStay)) {
    return null;
  }

  const minStayAsMonths = minStay ? Math.floor(minStay / 30) : 0;
  const maxStayAsMonths = maxStay ? Math.floor(maxStay / 30) : 0;

  const amount = getOfferAmount({
    discountValue,
    discountType,
    currencySymbol
  });

  if (minStayAsMonths && maxStayAsMonths) {
    return trans('listing.book-now-card.eligible-offers.itemBetween', {
      amount,
      minStay: minStayAsMonths,
      maxStay: maxStayAsMonths
    });
  }

  if (minStayAsMonths) {
    return trans('listing.book-now-card.eligible-offers.moreThan', {
      amount,
      minStay: minStayAsMonths
    });
  }

  if (minStay && maxStay) {
    return trans('listing.book-now-card.eligible-offers.itemBetween.days', {
      amount,
      minStay,
      maxStay
    });
  }

  return trans('listing.book-now-card.eligible-offers.moreThan.days', {
    amount,
    minStay
  });
};

const bestOfType = (type, offers) =>
  offers
    .filter(Boolean)
    .filter(({ discountType }) => discountType === type)
    .map(({ discountValue }) => discountValue)
    .sort((a, b) => a - b)
    .pop();

export const getBestOffer = offers => {
  if (!offers?.length) {
    return null;
  }

  let bestOfferValue = bestOfType(DISCOUNT_TYPE_PERCENTAGE, offers);

  if (bestOfferValue) {
    return {
      discountType: DISCOUNT_TYPE_PERCENTAGE,
      discountValue: bestOfferValue
    };
  }

  bestOfferValue = bestOfType(DISCOUNT_TYPE_FIXED, offers);

  if (bestOfferValue) {
    return {
      discountType: DISCOUNT_TYPE_FIXED,
      discountValue: bestOfferValue
    };
  }

  return null;
};
