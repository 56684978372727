import PropTypes from 'prop-types';

import themes from '../utils/iconColorThemes';

const CircleClockIcon = ({ theme = 'base' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd" stroke={themes[theme]}>
      <circle
        cx="12"
        cy="12"
        r="11.25"
        fill="none"
        stroke={themes[theme]}
        strokeWidth="1.5"
      />
      <path strokeWidth="1.8" d="M18.012 12H12V5.88" />
    </g>
  </svg>
);

CircleClockIcon.propTypes = {
  theme: PropTypes.oneOf(Object.keys(themes))
};

export default CircleClockIcon;
