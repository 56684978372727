/* eslint-disable import/no-named-as-default-member */
import { getPagePath } from '@spotahome/soyuz/client';

import SortingUtils from './sorting';

export const SEO_MODE_KEYS_TO_IGNORE = ['area', 'seoMode', 'areaId'];

const FILTERS = {
  'type[]': {
    includeAs: 'path',
    seoKey: 'for-rent',
    value: 'type'
  },
  'topology[]': {
    includeAs: 'path',
    seoKey: 'bedrooms',
    value: 'topology'
  },
  page: {
    includeAs: 'path',
    seoKey: 'page',
    value: 'page'
  }
};

const QUERY_SEPARATOR = '=';
const PATH_SEPARATOR = ':';
const SORT_BY_KEY = 'sortBy';
const PAGE_KEY = 'page';
const IB_FILTER_KEY = 'instantBooking';
const FIRST_PAGE = 1;
const NO_IB_FILTER = 0;
const NO_VERIFIED_FILTER = 0;
const NO_IS_BEST_CHOICE_FILTER = 0;
const VERIFIED_KEY = 'verified';
const IS_BEST_CHOICE_KEY = 'isBestChoice';
const FLEXIBLE_MOVE_IN_KEY = 'flexibleMoveIn';
const FLEXIBLE_MOVE_OUT_KEY = 'flexibleMoveOut';
const FLEXIBLE_DATE_FILTER = 1;
const SEO_MODE = 'seoMode';

const PARAMS_TO_AVOID_CHECKS = {
  [SORT_BY_KEY]: value => SortingUtils.isDefaultSort(value),
  [PAGE_KEY]: value => parseInt(value, 10) === FIRST_PAGE,
  [IB_FILTER_KEY]: value => parseInt(value, 10) === NO_IB_FILTER,
  [VERIFIED_KEY]: value => parseInt(value, 10) === NO_VERIFIED_FILTER,
  [IS_BEST_CHOICE_KEY]: value =>
    parseInt(value, 10) === NO_IS_BEST_CHOICE_FILTER,
  [FLEXIBLE_MOVE_IN_KEY]: value => parseInt(value, 10) === FLEXIBLE_DATE_FILTER,
  [FLEXIBLE_MOVE_OUT_KEY]: value =>
    parseInt(value, 10) === FLEXIBLE_DATE_FILTER,
  [SEO_MODE]: () => true
};

const filterNamesforUrl = Object.keys(FILTERS).reduce((acc, key) => {
  const seoKey = FILTERS[key].seoKey || key;
  return {
    ...acc,
    [seoKey]: key
  };
}, {});

const translatedFilters = Object.keys(FILTERS).reduce((acc, filterType) => {
  const { seoKey, value } = FILTERS[filterType];
  return {
    ...acc,
    [value]: seoKey
  };
}, {});

function getFilterUrlName(filterKey) {
  return filterNamesforUrl[filterKey];
}

const generateUrlSliceForFilter = (filterKey, filterValue, separator) => {
  const filterValueArr =
    filterValue instanceof Array ? filterValue : [filterValue];

  return filterValueArr
    .filter(Boolean)
    .sort()
    .map(value => `${filterKey}${separator}${value}`);
};

const filterIsArray = filterName => filterName.includes('[]');

const mapFilterNames = filterPath => {
  const [pathKey, filterValue] = filterPath.split(':');
  return {
    filterName: getFilterUrlName(pathKey),
    filterValue
  };
};

const hasFilter = ({ filterName, filterValue }) => filterName && filterValue;

const mergeFilters = (filterName, filterValue, prevFilter = []) => ({
  [filterName]: filterIsArray(filterName)
    ? [...prevFilter, filterValue]
    : filterValue
});

const getFiltersFromPath = pathname =>
  pathname
    .split('/')
    .map(mapFilterNames)
    .filter(hasFilter)
    .reduce(
      (acc, { filterName, filterValue }) => ({
        ...acc,
        ...mergeFilters(filterName, filterValue, acc[filterName])
      }),
      {}
    );

const getFiltersFromQuery = searchParams =>
  Array.from(searchParams).reduce(
    (acc, [filterName, filterValue]) => ({
      ...acc,
      ...mergeFilters(filterName, filterValue, acc[filterName])
    }),
    {}
  );

export const filtersObjectFromUrl = url => {
  const parsedUrl = new URL(url);
  const filterFromPath = getFiltersFromPath(parsedUrl.pathname);
  const filterFromQuery = getFiltersFromQuery(parsedUrl.searchParams);

  return {
    ...filterFromPath,
    ...filterFromQuery
  };
};

export const filterBelongsToPath = filterKey =>
  typeof translatedFilters[filterKey] !== 'undefined';

export const generatePathForFilter = (filterKey, filterValue) =>
  filterBelongsToPath(filterKey)
    ? generateUrlSliceForFilter(
        translatedFilters[filterKey],
        filterValue,
        PATH_SEPARATOR
      ).join('/')
    : '';

export const generateQueryForFilter = (filterKey, filterValue) =>
  filterBelongsToPath(filterKey)
    ? ''
    : generateUrlSliceForFilter(filterKey, filterValue, QUERY_SEPARATOR).join(
        '&'
      );

// TODO: Change naming to paramHasDefaultValue or similar
export const shouldAvoidParamInTheUrl = (name, value) => {
  const paramURLChecker = PARAMS_TO_AVOID_CHECKS[name];

  return paramURLChecker ? paramURLChecker(value) : false;
};

export const shouldIgnoreSeoKey = key => SEO_MODE_KEYS_TO_IGNORE.includes(key);

const createTranslatedQueryString = (filters = {}) => {
  const urlInParts = [];
  const urlTranslated = [];
  const returnQueryString = {};

  Object.keys(filters).forEach(key => {
    // eslint-disable-next-line no-useless-escape
    const simplifiedKey = key.replace(/[\[\]']+/g, '');

    if (filters.seoMode && shouldIgnoreSeoKey(simplifiedKey)) {
      return;
    }

    if (shouldAvoidParamInTheUrl(simplifiedKey, filters[simplifiedKey])) {
      return;
    }

    if (filterBelongsToPath(simplifiedKey)) {
      const urlParam = generatePathForFilter(simplifiedKey, filters[key]);
      if (urlParam) {
        urlTranslated.push(urlParam);
      }
    } else {
      const urlQuery = generateQueryForFilter(key, filters[key]);
      if (urlQuery) {
        urlInParts.push(urlQuery);
      }
    }
  });

  if (urlTranslated.length) {
    returnQueryString.params = urlTranslated.join('/');
  }
  if (urlInParts.length) {
    returnQueryString.queryString = urlInParts.join('&');
  }

  return returnQueryString;
};

const getSearchUrl = (locationString, query) => {
  const params = { locationString };

  let url = getPagePath('marketplace.free-search', {
    params
  });
  url += query.params ? `/${query.params}` : '';
  url += query.queryString ? `?${query.queryString}` : '';

  return url;
};

export const getSearchUrlFromFilters = (locationString, filters) => {
  const query = createTranslatedQueryString(filters);
  return getSearchUrl(locationString, query);
};

export default {
  generatePathForFilter,
  generateQueryForFilter,
  filterBelongsToPath,
  filtersObjectFromUrl,
  shouldAvoidParamInTheUrl,
  shouldIgnoreSeoKey,
  getSearchUrlFromFilters
};
