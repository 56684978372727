import { getCurrencySymbolForISOCode } from '@spotahome/soyuz-currency';

const DEFAULT_ISO_CODE = 'EUR';

class CurrencyFormatter {
  constructor(isoCode = DEFAULT_ISO_CODE) {
    this.isoCode = isoCode;
  }

  getIsoCode() {
    return this.isoCode;
  }

  getLabel() {
    return this.isoCode;
  }

  getSymbol() {
    return getCurrencySymbolForISOCode(this.isoCode);
  }
}

export const getCurrencySymbol = isoCode =>
  getCurrencySymbolForISOCode(isoCode);

export default CurrencyFormatter;
