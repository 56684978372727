import PropTypes from 'prop-types';

const LockIcon = ({ width = 10, height = 14, className = '' }) => (
  <svg
    viewBox="0 0 10 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    className={className}
  >
    <path
      d="M1.20517 13.3334C0.872722 13.3334 0.588722 13.2157 0.353167 12.9802C0.117722 12.7447 0 12.4607 0 12.1282V5.87187C0 5.53943 0.117722 5.25543 0.353167 5.01987C0.588722 4.78443 0.872722 4.66671 1.20517 4.66671H2V3.33337C2 2.50093 2.29189 1.79282 2.87567 1.20904C3.45944 0.625262 4.16756 0.333374 5 0.333374C5.83244 0.333374 6.54056 0.625262 7.12433 1.20904C7.70811 1.79282 8 2.50093 8 3.33337V4.66671H8.79483C9.12728 4.66671 9.41128 4.78443 9.64683 5.01987C9.88228 5.25543 10 5.53943 10 5.87187V12.1282C10 12.4607 9.88228 12.7447 9.64683 12.9802C9.41128 13.2157 9.12728 13.3334 8.79483 13.3334H1.20517ZM1.20517 12.3334H8.79483C8.85472 12.3334 8.90389 12.3142 8.94233 12.2757C8.98078 12.2373 9 12.1881 9 12.1282V5.87187C9 5.81199 8.98078 5.76282 8.94233 5.72437C8.90389 5.68593 8.85472 5.66671 8.79483 5.66671H1.20517C1.14528 5.66671 1.09611 5.68593 1.05767 5.72437C1.01922 5.76282 1 5.81199 1 5.87187V12.1282C1 12.1881 1.01922 12.2373 1.05767 12.2757C1.09611 12.3142 1.14528 12.3334 1.20517 12.3334ZM5 10.1667C5.32389 10.1667 5.59933 10.0533 5.82633 9.82637C6.05322 9.59937 6.16667 9.32393 6.16667 9.00004C6.16667 8.67615 6.05322 8.40071 5.82633 8.17371C5.59933 7.94682 5.32389 7.83337 5 7.83337C4.67611 7.83337 4.40067 7.94682 4.17367 8.17371C3.94678 8.40071 3.83333 8.67615 3.83333 9.00004C3.83333 9.32393 3.94678 9.59937 4.17367 9.82637C4.40067 10.0533 4.67611 10.1667 5 10.1667ZM3 4.66671H7V3.33337C7 2.77782 6.80556 2.3056 6.41667 1.91671C6.02778 1.52782 5.55556 1.33337 5 1.33337C4.44444 1.33337 3.97222 1.52782 3.58333 1.91671C3.19444 2.3056 3 2.77782 3 3.33337V4.66671Z"
      fill="#1C1B1F"
    />
  </svg>
);

LockIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string
};

export default LockIcon;
