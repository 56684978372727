import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz-trans/client';

import HelpCenterButton from '../FooterContainer/HelpCenterButton';

import FooterSimplifiedBlockTitle from './components/FooterSimplifiedBlockTitle';
import FooterSimplifiedLink from './components/FooterSimplifiedLink';

const SupportFooter = props => {
  const { help, contactUs } = props;

  return (
    <>
      <FooterSimplifiedBlockTitle text={trans('footer.label.support')} />
      <HelpCenterButton color="exposed" href={help} />
      <FooterSimplifiedLink key="footer.item.contact_support" href={contactUs}>
        {trans('footer.item.contact_support')}
      </FooterSimplifiedLink>
    </>
  );
};

SupportFooter.propTypes = {
  help: PropTypes.string.isRequired,
  contactUs: PropTypes.string.isRequired
};

export default SupportFooter;
