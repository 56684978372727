export const FEATURES = [
  {
    name: 'air_conditioning',
    labelKey: 'search.filter.air_conditioning',
    tracking: 'ga-search-form-air_conditioning',
    cy: 'features-air-conditioning'
  },
  {
    name: 'heating',
    labelKey: 'search.filter.heating',
    tracking: 'ga-search-form-heating',
    cy: 'features-heating'
  },
  {
    name: 'washing_machine',
    labelKey: 'search.filter.washingMachine',
    tracking: 'ga-search-form-washing_machine',
    cy: 'features-washing-machine'
  },
  {
    name: 'oven',
    labelKey: 'search.filter.oven',
    tracking: 'ga-search-form-oven',
    cy: 'features-oven'
  },
  {
    name: 'dishwasher',
    labelKey: 'search.filter.dishwasher',
    tracking: 'ga-search-form-dishwasher',
    cy: 'features-dishwasher'
  },
  {
    name: 'withDesk',
    labelKey: 'search.filter.with_desk',
    tracking: 'ga-search-form-withDesk',
    cy: 'features-with-desk'
  },
  {
    name: 'balcony',
    labelKey: 'search.filter.balcony',
    tracking: 'ga-search-form-balcony',
    cy: 'features-balcony'
  },
  {
    name: 'elevator',
    labelKey: 'search.filter.elevator',
    tracking: 'ga-search-form-elevator',
    cy: 'features-elevator'
  }
];
