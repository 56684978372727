import { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import Metrics from '@spotahome/soyuz-tracking';

import { trans } from '@spotahome/soyuz/client';

import InfoIcon from '../../../icons/InfoIcon';
import Button from '../../../Button';
import DropdownButton from '../../../DropdownButton';
import AlertBox from '../../../AlertBox';

import {
  useHotspotItem,
  HOTSPOT_FILTERS
} from '../../../../context/HotspotsContext';

import './ExposedFilter.scss';

export const INFO_KEYS = {
  availabilities: {
    content: 'search.wizard.step.dates.description',
    title: 'search.wizard.step.dates.title'
  },
  propertyType: {
    content: 'search.wizard.step.propertyTypes.description',
    title: 'search.wizard.step.propertyTypes.title'
  },
  budget: {
    content: 'search.wizard.step.prices.description',
    title: 'search.wizard.step.prices.title'
  }
};

const FiltersExposedInfoBox = ({ filterName }) => {
  const { isHotspotActive } = useHotspotItem(filterName);
  const { content, title } = INFO_KEYS[filterName];

  return isHotspotActive() ? (
    <div className="filters-exposed-info-box">
      <AlertBox
        icon={<InfoIcon />}
        title={trans(title)}
        message={trans(content)}
      />
    </div>
  ) : null;
};

FiltersExposedInfoBox.propTypes = {
  filterName: PropTypes.string.isRequired
};

const FiltersExposedTitle = ({
  title,
  subtitle,
  renderSubtitleTooltip = () => {}
}) => (
  <div className="filters-exposed-title">
    <div className="filters-exposed-title__title">{title} </div>
    <p className="filters-exposed-title__subtitle">
      {subtitle} {renderSubtitleTooltip()}
    </p>
  </div>
);

FiltersExposedTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  renderSubtitleTooltip: PropTypes.func
};

const FiltersExposedButton = ({ text, onClick, dataTest }) => (
  <div className="filters-exposed-button">
    <Button
      onClick={onClick}
      fullWidth
      color="interaction"
      data-test={dataTest}
      size="large"
    >
      {text}
    </Button>
  </div>
);

FiltersExposedButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  dataTest: PropTypes.string.isRequired
};

const ExposedFilter = ({
  isFilterOpen,
  onToggleFilter,
  renderButtonContent,
  renderFilterContent,
  filterConfig,
  totalResults,
  showResults
}) => {
  const { setHotspotVisited, initializeHotspotVisited, renderHotspotLed } =
    useHotspotItem(HOTSPOT_FILTERS[filterConfig.name]);

  useEffect(() => {
    if (filterConfig.isSelected) {
      initializeHotspotVisited();
    }
  }, []);

  const handleClose = () => {
    setHotspotVisited();
    onToggleFilter();
  };

  const handleToggle = e => {
    e?.stopPropagation();
    onToggleFilter();
  };

  return (
    <DropdownButton
      className="exposed-filter"
      isOpen={isFilterOpen}
      isSelected={filterConfig.isSelected}
      onToggle={handleToggle}
      onClose={handleClose}
      dataTest={filterConfig.dataTestOpen}
      renderButtonContent={() => (
        <Fragment>
          {renderHotspotLed()}
          {renderButtonContent()}
        </Fragment>
      )}
      renderDropdownContent={handleDropdownClose => (
        <>
          <div className="exposed-filter__content">
            <FiltersExposedTitle
              title={trans(filterConfig.title)}
              subtitle={trans(filterConfig.subtitle)}
              renderSubtitleTooltip={filterConfig.renderSubtitleTooltip}
            />
            <FiltersExposedInfoBox
              filterName={HOTSPOT_FILTERS[filterConfig.name]}
            />
            {renderFilterContent(handleDropdownClose)}
          </div>
          <FiltersExposedButton
            onClick={() => {
              Metrics.ga.sendEvent(
                'Search',
                'exposed-filters',
                filterConfig.eventLabel
              );
              handleDropdownClose();
            }}
            text={
              showResults
                ? trans('search.filters.view_close_filters', {
                    nResults: `${totalResults}`
                  })
                : trans('search.filter_header.close_filters')
            }
            dataTest={filterConfig.dataTestApply}
          />
        </>
      )}
    />
  );
};

ExposedFilter.propTypes = {
  isFilterOpen: PropTypes.bool.isRequired,
  onToggleFilter: PropTypes.func.isRequired,
  renderButtonContent: PropTypes.func.isRequired,
  renderFilterContent: PropTypes.func.isRequired,
  totalResults: PropTypes.number.isRequired,
  showResults: PropTypes.bool.isRequired,
  filterConfig: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    eventLabel: PropTypes.string,
    dataTestOpen: PropTypes.string,
    dataTestApply: PropTypes.string,
    isSelected: PropTypes.bool,
    renderSubtitleTooltip: PropTypes.func
  }).isRequired
};

export default ExposedFilter;
