import PropTypes from 'prop-types';
import classNames from 'classnames';

import { trans } from '@spotahome/soyuz/client';

import './SEOContent.scss';

const SEOContent = ({ title, subtitle, body, isCentered }) => {
  const seoWrapperClasses = classNames('seo-wrapper', {
    'seo-wrapper--centered': isCentered
  });

  return (
    <article className={seoWrapperClasses}>
      {title && <h2>{trans(title)}</h2>}
      {subtitle && <h3>{trans(subtitle)}</h3>}

      <div dangerouslySetInnerHTML={{ __html: trans(body) }} />
    </article>
  );
};

SEOContent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  body: PropTypes.string,
  isCentered: PropTypes.bool
};

SEOContent.defaultProps = {
  title: '',
  subtitle: '',
  body: '',
  isCentered: false
};

export default SEOContent;
