const RefreshIcon = props => (
  <svg
    {...props}
    width="14px"
    height="14px"
    viewBox="0 0 14 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.42857143,2.75708198 C3.20799007,0.420101715 6.31131854,-0.593455465 9.18507437,0.351335162 C12.451559,1.4252413 14.4545289,4.71144145 13.9115177,8.10583934 C13.3685064,11.5002372 10.44025,13.9980619 7.00165958,14 C5.144704,14 3.36380449,13.2625548 2.05073861,11.9498938 C0.737672731,10.6372328 1.55468074e-16,8.85688251 0,7.00049958 C0,6.61392784 0.313474977,6.30054953 0.700165958,6.30054953 C1.08685694,6.30054953 1.40033192,6.61392784 1.40033192,7.00049958 C1.40134621,9.83564923 3.52186503,12.2226809 6.33790728,12.5586434 C9.15394953,12.8946059 11.7768248,11.0734758 12.4452072,8.31818728 C13.1135895,5.56289871 11.61655,2.74296931 8.95938777,1.75205179 C6.62662689,0.882111057 4.04601979,1.66287132 2.57104163,3.57142857 L4.28571429,3.57142857 C4.68020339,3.57142857 5,3.89122518 5,4.28571429 C5,4.68020339 4.68020339,5 4.28571429,5 L0.714285714,5 C0.319796607,5 0,4.68020339 0,4.28571429 L0,0.714285714 C0,0.319796607 0.319796607,0 0.714285714,0 C1.10877482,0 1.42857143,0.319796607 1.42857143,0.714285714 L1.42857143,2.75708198 Z"
      id="Refresh"
    />
  </svg>
);

export default RefreshIcon;
