export const MOBILE_BIG = 440;
export const MOBILE_BIG_PX = `${MOBILE_BIG}px`;
export const TABLET = 768;
export const TABLET_PX = `${TABLET}px`;
export const TABLET_BIG = 1024;
export const TABLET_BIG_PX = `${TABLET_BIG}px`;
export const DESKTOP = 1280;
export const DESKTOP_PX = `${DESKTOP}px`;

export const MQ_DESKTOP_MIN_WIDTH = 1101;
export const MQ_TABLET_MAX_WIDTH = MQ_DESKTOP_MIN_WIDTH - 1;
export const MQ_TABLET_MIN_WIDTH = 701;
export const MQ_MOBILE_MAX_WIDTH = MQ_TABLET_MIN_WIDTH - 1;
export const MQ_MOBILE_MIN_WIDTH = 480;
export const MQ_SMALL_MOBILE_MAX_WIDTH = MQ_MOBILE_MIN_WIDTH - 1;
export const ROOM = 'rooms';
export const APARTMENT = 'apartments';
export const RESIDENCE = 'residences';
export const STUDENTS = 'studios';
export const ROOM_TYPES = [ROOM, APARTMENT, RESIDENCE, STUDENTS];
export const HI_JIFFY_TOKEN = '6aDaya1CgnMlhg';

export const HOMECARD_IMAGE_SIZES = {
  homecardHidpi: '640w',
  homecard: '320w'
};

export const FEATURED_CITIES = [
  'madrid',
  'berlin',
  'brussels',
  'barcelona',
  'milan'
];

export const TOAST_TIMEOUT = 8000;
