import PropTypes from 'prop-types';
import classNames from 'classnames';

import SeoSnippetShape from '../../shapes/SeoSnippet';

import './SEOSnippet.scss';

const SEOSnippet = ({ seoSnippet, isCentered = false }) => {
  if (!seoSnippet?.features) {
    return null;
  }

  const seoWrapperClasses = classNames('seo-snippet', {
    'seo-snippet--centered': isCentered
  });

  return (
    <article className={seoWrapperClasses}>
      <h2>{seoSnippet.title}</h2>
      <table>
        <tbody>
          {seoSnippet.features.map(feature => {
            return (
              <tr key={feature.title}>
                <td>{feature.title}</td>
                <td>{feature.content}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </article>
  );
};

SEOSnippet.propTypes = {
  seoSnippet: PropTypes.shape(SeoSnippetShape).isRequired,
  isCentered: PropTypes.bool
};

export default SEOSnippet;
