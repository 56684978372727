import HomeCardPlaceHolder from '@spotahome/marketplace-common/src/components/Homecard/HomeCardPlaceHolder';

const EMPTY_LIST_LENGTH = 8;

const EmptyList = () => (
  <div className="l-list" style={{ margin: 0 }}>
    {new Array(EMPTY_LIST_LENGTH).fill().map((_, i) => (
      <div className="l-list__item" key={i}>
        <HomeCardPlaceHolder />
      </div>
    ))}
  </div>
);

export default EmptyList;
