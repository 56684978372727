import PropTypes from 'prop-types';

import { useSoyuzLocales } from '@spotahome/soyuz/client';

import { ExternalLinkProvider } from '../../context/ExternalLink';

import FooterSimplified from './FooterSimplified';

const FooterSimplifiedContainer = ({ locales, onLocaleChange }) => {
  const { current } = useSoyuzLocales();
  return (
    <ExternalLinkProvider currentLocale={current}>
      <FooterSimplified locales={locales} onLocaleChange={onLocaleChange} />
    </ExternalLinkProvider>
  );
};

FooterSimplifiedContainer.propTypes = {
  locales: PropTypes.arrayOf(PropTypes.string),
  onLocaleChange: PropTypes.func
};

FooterSimplifiedContainer.defaultProps = {
  locales: null,
  onLocaleChange: null
};

export default FooterSimplifiedContainer;
