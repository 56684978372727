/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';

import { trans, useSoyuzExperiment } from '@spotahome/soyuz/client';
import {
  CloseButton,
  cityToggleConfig,
  FormGroupToggle,
  CheckboxWithLabel
} from '@spotahome/ui-library';
import Tracking from '@spotahome/soyuz-tracking';

// lodash utils
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import SoyuzAnalytics from '@spotahome/soyuz-analytics';

import { useFiltersContext } from '@spotahome/ui-library/src/tenant/context/FiltersContext';
import { isFilteredByDate } from '@spotahome/ui-library/src/tenant/components/utils/filters';

import camelCase from 'lodash/camelCase';

import FilterGroup from '../FilterGroup';

import ClearFiltersButton from './ClearFiltersButton';

import NUMBER_BATHROOMS_TOGGLE from './bathrooms-config';
import BED_TYPE_TOGGLE from './beds-config';
import { FEATURES } from './features-config';
import { RULES } from './rules-config';

const SHOW_PROPERTIES_WITH_NO_DEPOSIT = 1;
const SHOW_ALL_PROPERTIES = 0;
const SHOW_VERIFIED_PROPERTIES = 1;
const SHOW_SOLDOUT_PROPERTIES = 1;

const isValueChecked = (arr = [], value) => arr.includes(value);

const CheckboxWithFacet = props => {
  const { facetSearch } = useFiltersContext();
  const count = facetSearch?.[camelCase(props.name)];

  return (
    <CheckboxWithLabel
      className="checkbox-with-label"
      showCount
      count={count}
      {...props}
    />
  );
};

CheckboxWithFacet.propTypes = {
  name: PropTypes.string.isRequired
};

const Filters = ({
  cityId,
  onFiltersUpdate,
  onClearFilters = () => {},
  onCloseFilters = () => {},
  activeFiltersCount = 0
}) => {
  const { filters } = useFiltersContext();

  const handleToggleCheckbox = (name, value, isChecked) => {
    let options = filters[name];
    if (isChecked) {
      options = [...options, value];
    } else {
      options = options.filter(option => option !== value);
    }

    SoyuzAnalytics.sendGA4Event('filter', {
      context: 'filters',
      section: name !== 'bathrooms[]' ? value : `${name}-${value}`,
      action: isChecked ? 'check' : 'uncheck'
    });

    onFiltersUpdate({
      [name]: uniq(options)
    });
  };

  const handleNoDepositFilterCheckbox = isChecked =>
    onFiltersUpdate({
      noDeposit: isChecked
        ? SHOW_PROPERTIES_WITH_NO_DEPOSIT
        : SHOW_ALL_PROPERTIES
    });

  const isNoDepositChecked = () => {
    const noDeposit = parseInt(filters.noDeposit, 10);
    return noDeposit === SHOW_PROPERTIES_WITH_NO_DEPOSIT;
  };

  const isFeatureChecked = value => {
    return isValueChecked(filters['features[]'], value);
  };

  const isSoldoutChecked = () => {
    const soldOUt = parseInt(filters.includeBlockedProperties, 10);
    return soldOUt === SHOW_SOLDOUT_PROPERTIES;
  };

  const isVerifiedChecked = () => {
    const verified = parseInt(filters.verified, 10);
    return verified === SHOW_VERIFIED_PROPERTIES;
  };

  const handleBathroomClick = (name, value, isChecked) =>
    handleToggleCheckbox('bathrooms[]', value, isChecked);

  const handleBedTypesClick = (name, value, isChecked) => {
    SoyuzAnalytics.sendGA4Event('filter', {
      context: 'filters',
      section: `bed-${value}`
    });

    let selectedValues = [];
    if (!isEmpty(filters.bed)) {
      selectedValues = filters.bed.split(',');
    }

    if (isChecked) {
      selectedValues.push(value);
      selectedValues = uniq(selectedValues);
    } else {
      selectedValues = selectedValues.filter(
        currentVal => currentVal !== value
      );
    }

    onFiltersUpdate({ bed: selectedValues.join(',') });
  };

  const handleVerifiedFilterCheckbox = isChecked => {
    onFiltersUpdate({
      verified: isChecked ? SHOW_VERIFIED_PROPERTIES : SHOW_ALL_PROPERTIES
    });
  };

  const handleSoldoutdFilterCheckbox = isChecked => {
    onFiltersUpdate({
      includeBlockedProperties: isChecked
        ? SHOW_SOLDOUT_PROPERTIES
        : SHOW_ALL_PROPERTIES
    });

    SoyuzAnalytics.sendGA4Event('filter', {
      context: 'filters',
      section: 'soldout',
      action: isChecked ? 'check' : 'uncheck'
    });
  };

  const handleToggleFilterFeaturesCheckbox = value => {
    return isChecked => {
      handleToggleCheckbox('features[]', value, isChecked);
    };
  };

  const handleClearFilters = () => {
    onClearFilters();
    Tracking.ga.sendEvent('Search', 'clear-all-filters-expanded');
  };

  const renderFeaturesGroup = (group, title) => (
    <FilterGroup
      classModifier="checkboxes"
      label={trans(`search.filter.${title}`)}
    >
      {group.map(({ name, labelKey, cy }) => (
        <CheckboxWithFacet
          key={name}
          name={name}
          id={`${name}-id`}
          text={trans(labelKey)}
          callback={handleToggleFilterFeaturesCheckbox(name)}
          checked={isFeatureChecked(name)}
          dataAttributes={{ cy }}
        />
      ))}
    </FilterGroup>
  );

  const soldOutExp = useSoyuzExperiment('ShowSoldOutProperties');
  const showSoldOutFilter =
    isFilteredByDate(filters) && soldOutExp.get('behaviour') === 'variant';

  const listToUse = RULES;

  return (
    <div data-test="expanded-full-filters">
      <div className="filters__header">
        <div className="filters__title">
          <h1 className="filters__title-text">{trans('filters.title')} </h1>
          <ClearFiltersButton
            activeFiltersCount={activeFiltersCount}
            onClearFilters={handleClearFilters}
          />
        </div>

        <CloseButton onClick={onCloseFilters} />
      </div>

      {showSoldOutFilter && (
        <FilterGroup
          classModifier="checkboxes"
          label={trans('filters.label.availabilities')}
        >
          <CheckboxWithLabel
            id="soldout"
            name="soldout"
            text={trans('search.filter.soldout.text')}
            callback={handleSoldoutdFilterCheckbox}
            checked={isSoldoutChecked()}
          />
        </FilterGroup>
      )}
      {renderFeaturesGroup(FEATURES, 'features')}
      {renderFeaturesGroup(listToUse, 'rules')}
      <FilterGroup
        classModifier="property-type-checkboxes"
        label={trans('search.filter.advanced-search')}
      >
        <CheckboxWithFacet
          id="verified"
          name="verified"
          labelTitle={trans('search.filter.verified.title')}
          text={trans('search.filter.verified.text')}
          callback={handleVerifiedFilterCheckbox}
          checked={isVerifiedChecked()}
        />

        {cityToggleConfig(cityId).isNoDepositSupported && (
          <CheckboxWithFacet
            id="noDeposit"
            name="noDeposit"
            dataAttributes={{ cy: 'no-deposit-filter' }}
            labelTitle={trans('search.filter.no-deposit.title')}
            text={trans('search.filter.no-deposit.text')}
            callback={handleNoDepositFilterCheckbox}
            checked={isNoDepositChecked()}
          />
        )}
      </FilterGroup>
      <FilterGroup
        classModifier="toggle"
        label={trans('search.filter.type_bed')}
      >
        <FormGroupToggle
          onChange={handleBedTypesClick}
          selectedValues={filters.bed}
          options={BED_TYPE_TOGGLE}
          key="specific-key-bed"
        />
      </FilterGroup>
      <FilterGroup
        classModifier="align-label-to-first-row"
        label={trans('search.filter.bathrooms')}
      >
        <FormGroupToggle
          onChange={handleBathroomClick}
          selectedValues={filters['bathrooms[]'].join(',')}
          options={NUMBER_BATHROOMS_TOGGLE(
            trans('search.filter.bathrooms.2-or-more')
          )}
        />
        <div style={{ position: 'relative', top: '-10px' }}>
          <CheckboxWithFacet
            name="ensuiteRoom"
            id="ensuiteRoom-id"
            dataAttributes={{ cy: 'bathroom-private-bathroom' }}
            text={trans('search.filter.ensuiteRoom')}
            callback={handleToggleFilterFeaturesCheckbox('ensuiteRoom')}
            checked={isFeatureChecked('ensuiteRoom')}
          />
        </div>
      </FilterGroup>
    </div>
  );
};

Filters.propTypes = {
  cityId: PropTypes.string.isRequired,
  onFiltersUpdate: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func,
  activeFiltersCount: PropTypes.number,
  onCloseFilters: PropTypes.func
};

export default Filters;
