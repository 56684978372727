/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';

import Link from '../../Link';

import { capitalizeFirstLetter } from '../../utils/stringUtils';

const Crumb = ({ href = '', label, position }) => (
  <span
    itemProp="itemListElement"
    itemScope
    itemType="https://schema.org/ListItem"
  >
    {href ? (
      <Link itemProp="item" href={href} className="ga-detail-breadcrumb">
        <span itemProp="name">{capitalizeFirstLetter(label)}</span>
        <meta itemProp="position" content={position} />
      </Link>
    ) : (
      <span>
        <span itemProp="name">{capitalizeFirstLetter(label)}</span>
        <meta itemProp="position" content={position} />
      </span>
    )}
  </span>
);

Crumb.propTypes = {
  label: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  href: PropTypes.string
};

export default Crumb;
