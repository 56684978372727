import PropTypes from 'prop-types';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import isBefore from 'date-fns/isBefore';

import { trans } from '@spotahome/soyuz/client';
import Tracking from '@spotahome/soyuz-tracking';

import SoyuzAnalytics from '@spotahome/soyuz-analytics';

import { capitalizeFirstLetter } from '../../utils/stringUtils';

import FormDate from '../../FormDate';
import FormRadioButton from '../../FormRadioButton';

import {
  getRentalTypeRanges,
  SHORT_TERM,
  MID_TERM,
  LONG_TERM
} from '../../utils/rentalType';

import './AvailabilityFilter.scss';

const FLEXIBLE_OFFSET = 21;

const AvailabilityFilter = ({
  from,
  to,
  minimumStaying,
  updateFiltersWith,
  rentalType,
  disablePrice = false,
  moveOutFrom = '',
  moveOutTo = '',
  moveInFrom = '',
  moveInTo = ''
}) => {
  const today = new Date();
  const todayDateFormatted = format(today, 'yyyy-MM-dd');
  const minMoveOutDate = new Date(from || todayDateFormatted);
  const offsetMinMoveOutDate = addDays(minMoveOutDate, minimumStaying);

  // TODO: Check for cities with dynamic minimum stay to avoid defaulting to 1 month in the future.

  const rentalTypeRanges = getRentalTypeRanges(minimumStaying);

  const moveOutValues = [
    {
      value: SHORT_TERM,
      exposed: `${capitalizeFirstLetter(
        trans('rentalDurationType.shortTerm')
      )}`,
      text: `${rentalTypeRanges[SHORT_TERM].from}-${
        rentalTypeRanges[SHORT_TERM].to
      } ${trans('date.months')}`
    },
    {
      value: MID_TERM,
      exposed: `${capitalizeFirstLetter(trans('rentalDurationType.midTerm'))}`,
      text: `${rentalTypeRanges[MID_TERM].from}-${
        rentalTypeRanges[MID_TERM].to
      } ${trans('date.months')}`
    },
    {
      value: LONG_TERM,
      exposed: `${capitalizeFirstLetter(trans('rentalDurationType.longTerm'))}`,
      text: `${rentalTypeRanges[LONG_TERM].from}+ ${trans('date.months')}`
    }
  ];

  const handleMoveInDateSelected = ({ value }) => {
    Tracking.ga.sendEvent('Search', 'availabilities-date', 'move-in');
    SoyuzAnalytics.sendGA4Event('filter', {
      context: 'availabilities',
      section: 'move-in'
    });

    const offsetNewMoveIn = addDays(new Date(value), minimumStaying);
    let updatedMoveOut = to;

    if (isBefore(new Date(to), offsetNewMoveIn)) {
      updatedMoveOut = format(offsetNewMoveIn, 'yyyy-MM-dd');
    }

    updateFiltersWith({
      'move-in': value,
      'move-out': updatedMoveOut,
      moveInFrom: format(
        subDays(new Date(value), FLEXIBLE_OFFSET),
        'yyyy-MM-dd'
      ),
      moveInTo: format(addDays(new Date(value), FLEXIBLE_OFFSET), 'yyyy-MM-dd'),
      moveOutFrom: '',
      moveOutTo: ''
    });
  };

  const handleTodaySelected = () => {
    Tracking.ga.sendEvent('Search', 'availabilities-date', 'today');
    SoyuzAnalytics.sendGA4Event('filter', {
      context: 'availabilities',
      section: 'today'
    });
    const offsetNewMoveIn = addDays(today, minimumStaying);
    let updatedMoveOut = to;

    if (isBefore(new Date(to), offsetNewMoveIn)) {
      updatedMoveOut = format(offsetNewMoveIn, 'yyyy-MM-dd');
    }

    updateFiltersWith({
      'move-in': todayDateFormatted,
      moveInFrom: todayDateFormatted,
      moveInTo: format(
        addDays(new Date(todayDateFormatted), FLEXIBLE_OFFSET),
        'yyyy-MM-dd'
      ),
      'move-out': updatedMoveOut,
      moveOutFrom: '',
      moveOutTo: ''
    });
  };

  const handleMoveOutDateSelected = ({ value }) => {
    Tracking.ga.sendEvent('Search', 'availabilities-date', 'move-out');
    SoyuzAnalytics.sendGA4Event('filter', {
      context: 'availabilities',
      section: 'move-out',
      value
    });
    updateFiltersWith({
      'move-out': value,
      'rentalType[]': [],
      moveOutFrom: '',
      moveOutTo: ''
    });
  };

  const handleMoveOutRangeSelected = value => {
    let newRentalType;
    if (rentalType.includes(value)) {
      Tracking.ga.sendEvent('Search', 'availabilities-remove', value);
      SoyuzAnalytics.sendGA4Event('filter', {
        context: 'availabilities',
        section: 'move-out-range',
        action: 'remove',
        value
      });
      newRentalType = rentalType.filter(option => option !== value);
    } else {
      Tracking.ga.sendEvent('Search', 'availabilities-add', value);
      SoyuzAnalytics.sendGA4Event('filter', {
        context: 'availabilities',
        section: 'move-out-range',
        action: 'add',
        value
      });
      newRentalType = [...rentalType, value];
    }

    updateFiltersWith({
      'rentalType[]': newRentalType,
      'move-out': ''
    });
  };

  const isTodayDateSelected = () => todayDateFormatted === from;

  const isRentalTypeSelected = () => rentalType.length;

  return (
    <div className="availability-filter">
      <div className="availability-filter__title">
        {trans('search.exposedfilters.dates.move-in')}
      </div>

      <div className="availability-filter__button-container">
        <FormRadioButton
          onClick={handleTodaySelected}
          value="asap"
          selected={isTodayDateSelected()}
        >
          <div className="availability-filter__button-text">
            {trans('search.exposedfilters.dates.asap')}
          </div>
        </FormRadioButton>
        <FormDate
          onChange={handleMoveInDateSelected}
          value={!isTodayDateSelected() && from}
          absolutePosition
          calendarBordered
          minDate={today}
        >
          {({
            renderCalendar,
            displayRef,
            triggerRef,
            humanReadableDate,
            selectedDate
          }) => (
            <>
              <div ref={triggerRef}>
                <FormRadioButton
                  value="move-in"
                  selected={
                    selectedDate &&
                    !isTodayDateSelected() &&
                    from === selectedDate
                  }
                  disabled={false}
                >
                  <span
                    ref={displayRef}
                    className="availability-filter__button-text"
                  >
                    {humanReadableDate ||
                      trans('search.exposedfilters.dates.select_date')}
                  </span>
                </FormRadioButton>
              </div>
              {renderCalendar()}
            </>
          )}
        </FormDate>
      </div>
      <div className="availability-filter__title">
        {trans('search.exposedfilters.dates.move-out')}
      </div>
      <div className="availability-filter__button-container">
        {moveOutValues.map(({ value, text }) => (
          <FormRadioButton
            key={value}
            value={value}
            selected={rentalType.includes(value) && !to}
            onClick={handleMoveOutRangeSelected}
          >
            <div className="availability-filter__button-text">{text}</div>
          </FormRadioButton>
        ))}
        <FormDate
          onChange={handleMoveOutDateSelected}
          value={!isRentalTypeSelected() && to}
          disabled={!from && !moveInFrom && !moveInTo}
          absolutePosition
          calendarBordered
          minDate={offsetMinMoveOutDate}
          calendarPosition={offsetMinMoveOutDate}
        >
          {({ renderCalendar, displayRef, triggerRef, humanReadableDate }) => (
            <>
              <div ref={triggerRef}>
                <FormRadioButton
                  value="move-out"
                  disabled={!from && !moveInFrom && !moveInTo}
                  selected={
                    !rentalType.length && !moveOutFrom && !moveOutTo && to
                  }
                >
                  <div
                    ref={displayRef}
                    className="availability-filter__button-text"
                  >
                    {humanReadableDate ||
                      trans('search.exposedfilters.dates.specific_move_out')}
                  </div>
                </FormRadioButton>
              </div>
              {renderCalendar()}
            </>
          )}
        </FormDate>
      </div>
      {disablePrice && <p>{trans('search.hide-prices-until-dates')}</p>}
    </div>
  );
};

AvailabilityFilter.propTypes = {
  updateFiltersWith: PropTypes.func.isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  minimumStaying: PropTypes.number.isRequired,
  disablePrice: PropTypes.bool,
  rentalType: PropTypes.arrayOf(PropTypes.string).isRequired,
  moveOutFrom: PropTypes.string,
  moveOutTo: PropTypes.string,
  moveInFrom: PropTypes.string,
  moveInTo: PropTypes.string
};

export default AvailabilityFilter;
