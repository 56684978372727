import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { HorizontalHomecard } from '@spotahome/ui-library';
import { useSoyuzLocales, withLocales } from '@spotahome/soyuz/client';
import { localesShape } from '@spotahome/soyuz/shapes';

import { useFiltersContext } from '@spotahome/ui-library/src/tenant/context/FiltersContext';

import getGqlClient from '../../graphql/client';
import getHomecardsQuery from '../../graphql/queries/homecards';
import {
  getMonthlyPrice,
  getUrlWithMapParameters
} from '../../utils/homecards';

import HomecardMapPlaceholder from './HomecardMapPlaceholder';
import './HomecardMap.scss';

const fetchHomecard = async (id, locale) => {
  const { data } = await getHomecardsQuery(getGqlClient())([id], locale, false);
  return (data && data.homecards && data.homecards[0]) || undefined;
};

const HomecardMap = ({ id, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const [homecard, setHomecard] = useState();
  const { filters } = useFiltersContext();
  const { current: locale } = useSoyuzLocales();
  const moveIn = filters['move-in'];
  const moveOut = filters['move-out'];

  const currentYear = new Date().getFullYear();

  const firstAvailableDate = homecard && new Date(homecard.firstAvailableDate);
  const firstAvailableYear = firstAvailableDate?.getFullYear();

  useEffect(() => {
    setLoading(true);
    fetchHomecard(id, locale)
      .then(result => {
        setHomecard({
          ...result,
          monthlyPrice: getMonthlyPrice(result.displayPrice)
        });
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [id, locale]);

  return (
    <div className="homecard-map">
      {loading || !homecard ? (
        <HomecardMapPlaceholder />
      ) : (
        <HorizontalHomecard
          {...rest}
          availableFrom={homecard.firstAvailableDate}
          type={homecard.type}
          image={homecard.mainPhotoUrl}
          link={getUrlWithMapParameters(homecard.url, moveIn, moveOut)}
          price={{
            type: homecard.monthlyPrice.type,
            amount: homecard.monthlyPrice.minimumPrice,
            currencyCode: homecard.currencyIsoCode
          }}
          title={homecard.title}
          showPrice
          newTab={false}
          dataTest="horizontal-homecard"
          showAvailableFromYear={firstAvailableYear !== currentYear}
        />
      )}
    </div>
  );
};

HomecardMap.propTypes = {
  id: PropTypes.number.isRequired,
  locales: PropTypes.shape(localesShape).isRequired
};

export default HomecardMap;
