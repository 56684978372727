import { Component } from 'react';
import classNames from 'classnames';

class IconLoading extends Component {
  static defaultProps = {
    size: ''
  };

  render() {
    const classes = classNames(
      'loading-icon',
      this.props.size === 'small' ? 'loading-icon--small' : ''
    );

    return (
      <i className={classes}>
        <span className="loading-icon__text">Loading...</span>
      </i>
    );
  }
}

export default IconLoading;
