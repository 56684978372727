import SpotahomeIcon from './SpotahomeIcon';
import styles from './FooterSimplified.module.scss';

const getYear = () => new Date().getFullYear();

const CopyrightSimplifiedFooter = () => (
  <span className={styles['footer__disclaimer-copyright']}>
    <SpotahomeIcon /> ©{getYear()} Spotahome
  </span>
);

export default CopyrightSimplifiedFooter;
