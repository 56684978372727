import PropTypes from 'prop-types';
import { withClientConfig } from '@spotahome/soyuz/client';

const SahContainer = ({ children }) => <div>{children}</div>;

SahContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default withClientConfig()(SahContainer);
