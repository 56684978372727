import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';
import SoyuzAnalytics from '@spotahome/soyuz-analytics';

import { trans } from '@spotahome/soyuz/client';

import Tooltip from '../../../Tooltip';
import InfoIcon from '../../../icons/InfoIcon';
import CheckboxWithLabel from '../../../CheckboxWithLabel';

import './CheckboxSectionFilter.scss';
import { FacetSearchPropTypes } from '../../../../constants/shapes';

const CheckboxSectionFilter = ({
  onFiltersUpdate,
  selectedItems,
  options,
  titleKey,
  facetSearch = {}
}) => {
  const isSelected = name => selectedItems.includes(name);
  const handleToggleFilterCheckbox = name => isChecked => {
    SoyuzAnalytics.sendGA4Event('filter', {
      context: 'budget',
      section: name,
      action: isChecked ? 'check' : 'uncheck'
    });

    let newSelectedItems = selectedItems;
    if (isChecked) {
      newSelectedItems = [...newSelectedItems, name];
    } else {
      newSelectedItems = newSelectedItems.filter(
        selectedItem => selectedItem !== name
      );
    }

    onFiltersUpdate(uniq(newSelectedItems));
  };

  return (
    <div className="exposed-filter-checkbox-section">
      <p className="exposed-filter-checkbox-section__title">
        {trans(titleKey)}
      </p>
      <div className="exposed-filter-checkbox-section__content">
        {options.map(({ name, labelKey, tracking, cy, tooltipTextKey }) => (
          <div className="exposed-filter-checkbox-section__filter" key={name}>
            <CheckboxWithLabel
              id={name}
              name={name}
              text={trans(labelKey)}
              callback={handleToggleFilterCheckbox(name)}
              checked={isSelected(name)}
              className={`exposed-filter-checkbox-section__checkbox ${tracking}`}
              dataAttributes={{ cy }}
              showCount
              count={facetSearch?.[name]}
            />
            {tooltipTextKey && (
              <Tooltip
                className="exposed-filter-checkbox-section__tooltip"
                color="blue-cerulean"
                tooltip={trans(tooltipTextKey)}
                placement="top"
                trigger={['click', 'hover']}
              >
                <InfoIcon size={16} />
              </Tooltip>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

CheckboxSectionFilter.propTypes = {
  onFiltersUpdate: PropTypes.func.isRequired,
  titleKey: PropTypes.string.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      labelKey: PropTypes.string,
      tracking: PropTypes.string,
      cy: PropTypes.string,
      tooltipTextKey: PropTypes.string
    })
  ).isRequired,
  facetSearch: FacetSearchPropTypes
};

export default CheckboxSectionFilter;
