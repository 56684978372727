const ALL_IN_PLAN = 'allIn';
const SELECT_PLAN = 'select';
const PREMIUM_PLAN = 'premium';
const PLUS_PLAN = 'plus';
const STANDARD_PLAN = 'standard';
const GUARANTEED_EXCLUSIVITY_TYPE = 'guaranteed';

export const PAID_PLANS = [ALL_IN_PLAN, SELECT_PLAN, PREMIUM_PLAN, PLUS_PLAN];
export const RENT_COLLECTION_PLANS = [SELECT_PLAN, PLUS_PLAN];

export const isStandardPlan = plan => plan === STANDARD_PLAN;
export const isRelationalPlan = (plan, exclusivityType) =>
  plan === SELECT_PLAN ||
  plan === PLUS_PLAN ||
  (plan === ALL_IN_PLAN && exclusivityType === GUARANTEED_EXCLUSIVITY_TYPE);

export const isPlusPlan = plan => plan === PLUS_PLAN || plan === SELECT_PLAN;
export const isPlanWithRequieredDocuments = plan =>
  plan === SELECT_PLAN || plan === PREMIUM_PLAN;
