import toPairs from 'lodash/toPairs';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';

import { FilterUtils } from '@spotahome/ui-library';

const SEO_MODE_FILTERS_ALLOWED = ['area', 'seoMode', 'type[]', 'topology[]'];

export const SEO_MODE_KEYS_TO_IGNORE = ['area', 'seoMode', 'areaId'];

// If there are filters different to the default ones,
// and those are not in SEO_MODE_FILTERS_ALLOWED, then isSeoMode = false.
export const isSeoMode = filters => {
  const notDefaultFilters = differenceWith(
    toPairs(filters),
    toPairs(FilterUtils.DEFAULT_FILTERS),
    isEqual
  );

  return (
    notDefaultFilters
      .filter(
        // If 'area' is present, so it will be 'areaId[]', which needs to be removed in that case
        ([key]) => key !== 'areaId[]' || (key === 'areaId[]' && !filters.area)
      )
      .filter(
        // Remove filters that are SEO allowed
        ([key]) => !SEO_MODE_FILTERS_ALLOWED.includes(key)
      ).length === 0
  );
};

export const removeSeoFilters = filters => {
  return Object.keys(filters).reduce((newFilters, key) => {
    return !['area', 'seoMode'].includes(key)
      ? {
          ...newFilters,
          [key]: filters[key]
        }
      : newFilters;
  }, {});
};

export default {
  SEO_MODE_KEYS_TO_IGNORE,
  isSeoMode,
  removeSeoFilters
};
