import PropTypes from 'prop-types';
import { Link } from '@spotahome/ui-library';

import internalLinkingModule from '../../shapes/internalLinking';
import './InternalLinking.scss';

const InternalLinkingModules = ({ internalLinking }) => {
  return !internalLinking
    ? null
    : internalLinking.map(module => {
        return (
          <div className="internal-linking" key={module.title}>
            <h2 className="internal-linking__title">{module.title}</h2>
            <div className="internal-linking__links internal-linking--module-links">
              {module.links.map(link => (
                <Link href={link.url} key={link.url}>
                  {link.anchorText}
                </Link>
              ))}
            </div>
          </div>
        );
      });
};

InternalLinkingModules.propTypes = {
  internalLinking: PropTypes.arrayOf(PropTypes.shape(internalLinkingModule))
    .isRequired
};

export default InternalLinkingModules;
