const AlertBell = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={71}
    height={79}
    viewBox="0 0 71 79"
    fill="none"
  >
    <path d="M35.5 78.333a9 9 0 01-9-9h18a9 9 0 01-9 9z" fill="#FB6E44" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.774 8.567C51.867 3.082 43.854 0 35.5 0c-8.354 0-16.366 3.082-22.274 8.567C7.32 14.053 4 21.492 4 29.25v27h63v-27c0-7.758-3.319-15.197-9.226-20.683z"
      fill="#0A0777"
    />
    <path fill="#C9E3F7" d="M.833 54.667h69.333v14.667H.833z" />
  </svg>
);

export default AlertBell;
