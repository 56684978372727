const COUNTRY_URLS = {
  france: {
    es: 'https://www.spotahome.com/es/w/country/alquileres-en-francia',
    en: 'https://www.spotahome.com/w/country/rentals-in-france',
    fr: 'https://www.spotahome.com/fr/w/country/locations-en-france',
    it: 'https://www.spotahome.com/it/w/country/affitti-in-francia',
    de: 'https://www.spotahome.com/de/w/country/mieten-in-frankreich',
    pt: 'https://www.spotahome.com/pt/w/country/arrendamentos-franca'
  },
  spain: {
    es: 'https://www.spotahome.com/es/w/country/alquileres-en-espana',
    en: 'https://www.spotahome.com/w/country/rentals-in-spain',
    fr: 'https://www.spotahome.com/fr/w/country/locations-en-espagne',
    it: 'https://www.spotahome.com/it/w/country/affitti-in-spagna',
    de: 'https://www.spotahome.com/de/w/country/mieten-in-spanien',
    pt: 'https://www.spotahome.com/pt/w/country/arrendamentos-espanha'
  },
  italy: {
    es: 'https://www.spotahome.com/es/w/country/alquileres-en-italia',
    en: 'https://www.spotahome.com/w/country/rentals-in-italy',
    fr: 'https://www.spotahome.com/fr/w/country/locations-en-italie',
    it: 'https://www.spotahome.com/it/w/country/affitti-in-italia',
    de: 'https://www.spotahome.com/de/w/country/mieten-in-italien',
    pt: 'https://www.spotahome.com/pt/w/country/arrendamentos-italia'
  },
  germany: {
    es: 'https://www.spotahome.com/es/w/country/alquileres-en-alemania',
    en: 'https://www.spotahome.com/w/country/rentals-in-germany',
    fr: 'https://www.spotahome.com/fr/w/country/locations-en-allemagne',
    it: 'https://www.spotahome.com/it/w/country/affitti-in-germania',
    de: 'https://www.spotahome.com/de/w/country/mieten-in-deutschland',
    pt: 'https://www.spotahome.com/pt/w/country/arrendamentos-alemanha'
  },
  portugal: {
    es: 'https://www.spotahome.com/es/w/country/alquileres-en-portugal',
    en: 'https://www.spotahome.com/w/country/rentals-in-portugal',
    fr: 'https://www.spotahome.com/fr/w/country/locations-en-portugal',
    it: 'https://www.spotahome.com/it/w/country/affitti-in-portogallo',
    de: 'https://www.spotahome.com/de/w/country/mieten-in-portugal',
    pt: 'https://www.spotahome.com/pt/w/country/arrendamentos-portugal'
  },
  uk: {
    es: 'https://www.spotahome.com/es/w/country/alquileres-en-reino-unido',
    en: 'https://www.spotahome.com/w/country/rentals-in-united-kingdom',
    fr: 'https://www.spotahome.com/fr/w/country/locations-en-royaume-uni',
    it: 'https://www.spotahome.com/it/w/country/affitti-in-regno-unito',
    de: 'https://www.spotahome.com/de/w/country/mieten-in-grossbritannien',
    pt: 'https://www.spotahome.com/pt/w/country/arrendamentos-reino-unido'
  }
};

export const getCountryUrl = (countryId, locale) => {
  return COUNTRY_URLS[countryId]?.[locale] || null;
};

export default getCountryUrl;
