import once from 'lodash/once';
import Tracking from '@spotahome/soyuz-tracking';

import { useEcommerceManagerFactory } from '@spotahome/ecommerce-tracking';

const useHomecardListTracking = cityId => {
  const ecommerceManager = useEcommerceManagerFactory();
  const triggerHotjarPermanentBookingRecording = once(() => {
    Tracking.hotjar.triggerEvent('permanent_booking_search');
  });

  const trackProductImpressions = (cardList, indexedHomecards) => {
    try {
      ecommerceManager.trackSearchPageImpressions(
        cardList,
        indexedHomecards,
        cityId
      );
    } catch (err) {
      console.error(err);
    }
  };

  const trackHomecardClick = (position, cardData) => {
    ecommerceManager.trackSearchPageHomecardClick(cardData, position, cityId);
  };

  const triggerHotjarRecording = (homecards = {}) => {
    const hasHomecardWithPermanentDuration = Object.values(homecards).find(
      homecard => homecard.allowsPermanentContract
    );

    if (hasHomecardWithPermanentDuration) {
      triggerHotjarPermanentBookingRecording();
    }
  };

  return {
    trackInitialMount: (markerList, homecards) => {
      trackProductImpressions(markerList, homecards);
      triggerHotjarRecording(homecards);
    },
    trackProductImpressions,
    trackHomecardClick,
    triggerHotjarRecording
  };
};

export default useHomecardListTracking;
