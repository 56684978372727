import { forwardRef } from 'react';
import PropTypes from 'prop-types';

const BestChoiceLabelIcon = forwardRef(
  ({ size = 40, className = '', ...props }, ref) => (
    <svg
      {...props}
      className={className}
      ref={ref}
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 18 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9806 9.88926C17.9806 4.96318 13.9552 0.956055 9.0066 0.956055C4.29666 0.956055 0.428228 4.58185 0.0639924 9.1766C0.0451526 9.41415 0.0325928 9.64545 0.0325928 9.88926C0.0325928 10.1331 0.0451526 10.3644 0.0639924 10.6019C0.29007 13.4525 1.86633 15.9281 4.1585 17.3972H0.0325928V18.1098C0.0325928 23.0359 4.05802 27.043 9.0066 27.043C13.7103 27.043 17.585 23.4172 17.9492 18.8225C17.968 18.5849 17.9806 18.3536 17.9806 18.1098C17.9806 17.866 17.968 17.6347 17.9492 17.3972C17.7231 14.5465 16.1469 12.071 13.8547 10.6019H17.9806V9.88926ZM1.49581 10.6019H8.29069V17.3659C4.69857 17.0283 1.83493 14.1714 1.49581 10.6019ZM9.0066 25.6177C5.08793 25.6177 1.86005 22.6295 1.49581 18.8225H16.5174C16.1532 22.6295 12.9253 25.6177 9.0066 25.6177ZM16.5174 17.3972H9.72251V10.6332C13.3083 10.9707 16.1783 13.8214 16.5174 17.3972ZM1.49581 9.1766C1.85377 5.36952 5.08793 2.38137 9.0066 2.38137C12.9253 2.38137 16.1532 5.36952 16.5174 9.1766H1.49581Z"
        fill="#0D0D0D"
      />
    </svg>
  )
);

BestChoiceLabelIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string
};

export default BestChoiceLabelIcon;
