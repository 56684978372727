import PropTypes from 'prop-types';

import themes from '../utils/iconColorThemes';

const ChevronUp = ({ theme = 'base' }) => (
  <svg
    width="8"
    height="6"
    viewBox="0 0 8 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="chevron-up-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-4.42105e-08 4.26983L4 0.714275L8 4.26983L7.10096 5.28125L4 2.52484L0.899039 5.28125L-4.42105e-08 4.26983Z"
      fill={themes[theme]}
    />
  </svg>
);

ChevronUp.propTypes = {
  theme: PropTypes.oneOf(Object.keys(themes))
};

export default ChevronUp;
