const cityToastInformationKeys = {
  // valencia: {
  //   freeComission: 'information.modal.content.valencia-free-comission',
  //   bookingFeeDiscount: 'information.modal.content.booking-fee-discount'
  // }
};

export const hasValidCityInfo = cityName =>
  Object.keys(cityToastInformationKeys).filter(city => city === cityName)
    .length > 0;

export const cityInformation = (typeOfMessage, cityName) => {
  const city = cityToastInformationKeys[cityName];
  return city[typeOfMessage];
};

export default { cityInformation, hasValidCityInfo };
