/* eslint-disable no-underscore-dangle */
import { clientErrorTracker, trans } from '@spotahome/soyuz/client';
import { ModalOptIn } from '@spotahome/ui-library';
import Tracking from '@spotahome/soyuz-tracking';

import getGqlClient from '../../graphql/client';
import setMarketingOptIn from '../../graphql/mutations/marketing-opt-in';

const handleOptInAccepted = async () => {
  try {
    await setMarketingOptIn(getGqlClient())();
  } catch (error) {
    Tracking.ga.sendEvent('sign-up', 'opt-in', 'error');
    clientErrorTracker.error({ error, source: 'OptInModalWrapper-Accepted' });
  }
};

const OptInModalWrapper = () => (
  <ModalOptIn
    modalBodytext={trans('opt-in-checkbox-question')}
    checkboxText={trans('opt-in-checkbox-text')}
    buttonCtaText={trans('opt-in-button-cta')}
    setMarketingOptIn={handleOptInAccepted}
  />
);

export default OptInModalWrapper;
