/* eslint-disable dot-notation */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Colors } from '@spotahome/ui-library';

import MapIcon from './MapIcon';
import SquareListIcon from './SquareListIcon';

import './MapListSwitcher.scss';

const Switcher = ({
  renderLeftIcon,
  renderRightIcon,
  dataTest,
  onClickLeft,
  onClickRight,
  isPositionRight
}) => {
  const [isRightActive, setIsRightActive] = useState(false);

  const toggleIsRight = () => {
    setIsRightActive(!isRightActive);
    if (isRightActive) {
      onClickLeft();
    } else {
      onClickRight();
    }
  };

  useEffect(() => {
    setIsRightActive(isPositionRight);
  }, [isPositionRight, setIsRightActive]);

  const ballStyles = classNames({
    switcher__ball: 'switcher__ball',
    'switcher__ball--right': isRightActive
  });

  const switcherIconStyles = classNames({
    switcher__icon: 'switcher__icon',
    'switcher__icon--left': !isRightActive,
    'switcher__icon--right': isRightActive
  });

  return (
    <div
      className="switcher"
      onClick={toggleIsRight}
      role="button"
      tabIndex={0}
      data-test={dataTest}
    >
      <span className={ballStyles} />
      <div className="switcher__overlay">
        <span className={switcherIconStyles}>{renderLeftIcon()}</span>
        <span className={switcherIconStyles}>{renderRightIcon()}</span>
      </div>
    </div>
  );
};

Switcher.propTypes = {
  renderLeftIcon: PropTypes.func.isRequired,
  renderRightIcon: PropTypes.func.isRequired,
  dataTest: PropTypes.string,
  onClickLeft: PropTypes.func,
  onClickRight: PropTypes.func,
  isPositionRight: PropTypes.bool
};

Switcher.defaultProps = {
  dataTest: '',
  onClickLeft: () => {},
  onClickRight: () => {},
  isPositionRight: false
};

const MapListSwitcher = ({ onClickLeft, onClickRight, isPositionRight }) => (
  <Switcher
    onClickLeft={onClickLeft}
    onClickRight={onClickRight}
    isPositionRight={isPositionRight}
    renderLeftIcon={() => <SquareListIcon color={Colors.interaction} />}
    renderRightIcon={() => <MapIcon color={Colors.interaction} />}
    dataTest="map-list-switcher"
  />
);

MapListSwitcher.propTypes = {
  onClickLeft: PropTypes.func.isRequired,
  onClickRight: PropTypes.func.isRequired,
  isPositionRight: PropTypes.bool.isRequired
};

export default MapListSwitcher;
