export const adaptAlertDataToServer = ({
  moveIn,
  moveInFrom,
  moveInTo,
  moveOut,
  moveOutFrom,
  moveOutTo,
  minPrice,
  maxPrice,
  propertyTypes,
  cityId,
  mediums,
  cityAreaIds,
  features,
  rentalType,
  bed,
  noDeposit,
  verified,
  instantBooking,
  bathrooms,
  topology,
  ...rest
}) => ({
  ...rest,
  input: {
    cityId,
    mediums,
    moveIn: moveIn || null,
    moveInFrom: moveInFrom || null,
    moveInTo: moveInTo || null,
    moveOut: moveOut || null,
    moveOutFrom: moveOutFrom || null,
    moveOutTo: moveOutTo || null,
    minPrice: minPrice || null,
    maxPrice: maxPrice || null,
    propertyTypes: propertyTypes || null,
    cityAreaIds: cityAreaIds || [],
    features: features || [],
    rentalType: rentalType || [],
    bed: bed || null,
    noDeposit: noDeposit || null,
    verified: verified || null,
    instantBooking: instantBooking || null,
    bathrooms: bathrooms || [],
    topology: topology || []
  }
});

export const adaptAlertDataToClient = (response, key) => {
  const {
    moveIn,
    moveInFrom,
    moveInTo,
    moveOut,
    moveOutFrom,
    moveOutTo,
    minPrice,
    maxPrice,
    propertyTypes,
    cityAreaIds,
    features,
    rentalType,
    bed,
    noDeposit,
    verified,
    instantBooking,
    bathrooms,
    topology,
    ...rest
  } = response.data[key];

  return {
    ...rest,
    moveIn: moveIn || '',
    moveInFrom: moveInFrom || '',
    moveInTo: moveInTo || '',
    moveOut: moveOut || '',
    moveOutFrom: moveOutFrom || '',
    moveOutTo: moveOutTo || '',
    minPrice: minPrice || 0,
    maxPrice: maxPrice || 0,
    propertyTypes: propertyTypes || [],
    cityAreaIds: cityAreaIds || [],
    features: features || [],
    rentalType: rentalType || [],
    bed: bed || null,
    noDeposit: noDeposit || null,
    verified: verified || null,
    instantBooking: instantBooking || null,
    bathrooms: bathrooms || [],
    topology: topology || []
  };
};

export default {
  adaptAlertDataToServer,
  adaptAlertDataToClient
};
