import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz-trans/client';

import FooterSimplifiedBlockTitle from './components/FooterSimplifiedBlockTitle';
import FooterSimplifiedBlockList from './components/FooterSimplifiedBlockList';
import FooterSimplifiedLink from './components/FooterSimplifiedLink';

const LegalFooter = props => {
  const LegalLinks = [
    {
      url: props.privacyPolicies,
      locoKey: 'footer.item.privacy_policies'
    },
    {
      url: props.cookiesPolicy,
      locoKey: 'footer.item.cookies_policy'
    },
    {
      url: props.termsAndConditions,
      locoKey: 'footer.item.terms_and_conditions'
    }
  ];

  return (
    <Fragment>
      <FooterSimplifiedBlockTitle text={trans('footer.label.legal')} />
      <FooterSimplifiedBlockList>
        {LegalLinks.map(({ locoKey, url }) => (
          <FooterSimplifiedLink key={locoKey} href={url}>
            {trans(locoKey)}
          </FooterSimplifiedLink>
        ))}
      </FooterSimplifiedBlockList>
    </Fragment>
  );
};

LegalFooter.propTypes = {
  termsAndConditions: PropTypes.string.isRequired,
  cookiesPolicy: PropTypes.string.isRequired,
  privacyPolicies: PropTypes.string.isRequired
};

export default LegalFooter;
