import { useState } from 'react';
import PropTypes from 'prop-types';

import AvailabilityFilter from './components/AvailabilityFilter';
import BudgetFilter from './components/BudgetFilter';
import PropertyTypeFilter from './components/PropertyTypeFilter';

const ExposedFilters = ({
  filters = {},
  cityId,
  currencyIsoCode,
  totalResults,
  onFiltersUpdate = () => {},
  onFiltersOpen = () => {},
  onFiltersClose = () => {},
  maxPriceInCity,
  minimumStaying,
  sortingAlgorithm,
  showResults = false
}) => {
  const {
    'move-in': moveIn,
    'move-out': moveOut,
    moveOutFrom,
    moveOutTo,
    moveInFrom,
    moveInTo,
    'rentalType[]': rentalTypes,
    'type[]': propertyTypes
  } = filters;

  const [openFilter, setOpenFilter] = useState(false);

  const toggleExposedFilter = filterName => () => {
    const isFilterOpen = openFilter === filterName;

    if (isFilterOpen) {
      setOpenFilter(null);
      onFiltersClose(filterName);
    } else {
      setOpenFilter(filterName);
      onFiltersOpen(filterName);
    }
  };

  return (
    <>
      <AvailabilityFilter
        moveIn={moveIn}
        moveOut={moveOut}
        selectedRentalTypes={rentalTypes || []}
        isFilterOpen={openFilter === 'date'}
        onToggleFilter={toggleExposedFilter('date')}
        onFiltersUpdate={onFiltersUpdate}
        minimumStaying={minimumStaying}
        totalResults={totalResults}
        moveOutFrom={moveOutFrom}
        moveOutTo={moveOutTo}
        moveInFrom={moveInFrom}
        moveInTo={moveInTo}
        showResults={showResults}
      />
      <PropertyTypeFilter
        selectedPropertyTypes={propertyTypes || []}
        isFilterOpen={openFilter === 'property-type'}
        onToggleFilter={toggleExposedFilter('property-type')}
        onFiltersUpdate={onFiltersUpdate}
        totalResults={totalResults}
        filters={filters}
        showResults={showResults}
      />
      <BudgetFilter
        isFilterOpen={openFilter === 'price'}
        onToggleFilter={toggleExposedFilter('price')}
        currencyIsoCode={currencyIsoCode}
        onFiltersUpdate={onFiltersUpdate}
        filters={filters}
        totalResults={totalResults}
        sortingAlgorithm={sortingAlgorithm}
        maxPriceInCity={maxPriceInCity}
        cityId={cityId}
        showResults={showResults}
      />
    </>
  );
};

ExposedFilters.propTypes = {
  totalResults: PropTypes.number.isRequired,
  filters: PropTypes.shape({
    flexibleMoveIn: PropTypes.number,
    flexibleMoveOut: PropTypes.number,
    budget: PropTypes.string,
    'move-in': PropTypes.string,
    'move-out': PropTypes.string,
    'rentalType[]': PropTypes.arrayOf(PropTypes.string),
    'type[]': PropTypes.arrayOf(PropTypes.string),
    'features[]': PropTypes.arrayOf(PropTypes.string),
    moveInFrom: PropTypes.string,
    moveInTo: PropTypes.string,
    moveOutFrom: PropTypes.string,
    moveOutTo: PropTypes.string
  }),
  cityId: PropTypes.string.isRequired,
  currencyIsoCode: PropTypes.string.isRequired,
  onFiltersUpdate: PropTypes.func,
  onFiltersOpen: PropTypes.func,
  onFiltersClose: PropTypes.func,
  maxPriceInCity: PropTypes.number.isRequired,
  minimumStaying: PropTypes.number.isRequired,
  sortingAlgorithm: PropTypes.string.isRequired,
  showResults: PropTypes.bool
};

export default ExposedFilters;
