import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz/client';

import { useCookie, cityToggleConfig } from '@spotahome/ui-library';

import GenericBanner from '../GenericBanner';

const COOKIE_NAME = 'reduced-booking-fees-banner-seen';
const COOKIE_VALUE = 1;
const COOKIE_DURATION_TWO_WEEKS = 60 * 60 * 24 * 14;

const SearchBanner = ({ cityId }) => {
  const { getCookie, setCookie } = useCookie();
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    if (!getCookie(COOKIE_NAME)) {
      setCookie(COOKIE_NAME, COOKIE_VALUE, {
        maxAge: COOKIE_DURATION_TWO_WEEKS
      });
      setShowBanner(true);
    }
  }, []);

  if (cityToggleConfig(cityId).showFeeOffer && showBanner) {
    return (
      <GenericBanner
        content={trans('fee-offer-banner.content.text')}
        color={'blue-indigo'}
      />
    );
  }

  return null;
};

SearchBanner.propTypes = {
  cityId: PropTypes.string.isRequired
};

export default SearchBanner;
