import classNames from 'classnames/bind';

import styles from './ModalFooter.module.scss';

const cx = classNames.bind(styles);

const ModalFooter = ({ children, className }) => {
  const modalFooterClassName = cx('modalFooter', className);

  return <div className={modalFooterClassName}>{children}</div>;
};

export default ModalFooter;
