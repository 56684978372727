import PropTypes from 'prop-types';
import classNames from 'classnames';
import MediaQuery from 'react-responsive';
import { trans } from '@spotahome/soyuz/client';
import { Button } from '@spotahome/ui-library';
import Metrics from '@spotahome/soyuz-tracking';

import AlertButton from '@spotahome/marketplace-common/src/components/AlertButton';
import { useFiltersContext } from '@spotahome/ui-library/src/tenant/context/FiltersContext';

import {
  MQ_MOBILE_MIN_WIDTH,
  MQ_SMALL_MOBILE_MAX_WIDTH
} from '@spotahome/marketplace-common/src/constants';

import './FiltersHeader.scss';

const FiltersHeader = ({
  totalResults,
  citySlug,
  onHandleFiltersToggle,
  isFooterInSearchPage
}) => {
  const { filters } = useFiltersContext();
  const closeFiltersText = trans('search.filters.view_close_filters', {
    nResults: totalResults
  });

  const classes = classNames('filter-header', 'filter-header--expanded', {
    'filter-header--expanded--new-layout': isFooterInSearchPage
  });

  const buttonContainerClasses = classNames('filter-header__button-container');

  const resultButtonClasses = classNames(
    'filter-header__results-button',
    'ga-search-form-more-filters'
  );

  const handleOnClick = () => {
    Metrics.ga.sendEvent('Search', 'Alert Secondary CTA', 'click button');
  };

  return (
    <div className={classes}>
      <div className={buttonContainerClasses}>
        <AlertButton
          cityId={citySlug}
          filters={filters}
          expandedFilters
          onClick={handleOnClick}
          buttonProps={{
            alt: true,
            color: 'interaction'
          }}
          dataTest="alert-button-filters-header"
        />
        <Button
          className={resultButtonClasses}
          onClick={onHandleFiltersToggle}
          dataTest="more-filters-button"
          color="secondary"
          renderContent={() => (
            <>
              <MediaQuery minWidth={MQ_MOBILE_MIN_WIDTH}>
                {closeFiltersText}
              </MediaQuery>
              <MediaQuery maxWidth={MQ_SMALL_MOBILE_MAX_WIDTH}>
                {closeFiltersText}
              </MediaQuery>
            </>
          )}
        />
      </div>
    </div>
  );
};

FiltersHeader.propTypes = {
  totalResults: PropTypes.number.isRequired,
  onHandleFiltersToggle: PropTypes.func.isRequired,
  citySlug: PropTypes.string.isRequired,
  filters: PropTypes.shape({
    'bathrooms[]': PropTypes.arrayOf(PropTypes.string),
    bed: PropTypes.string,
    budget: PropTypes.string,
    'features[]': PropTypes.arrayOf(PropTypes.string),
    'move-in': PropTypes.string,
    'move-out': PropTypes.string,
    poi: PropTypes.string,
    'topology[]': PropTypes.arrayOf(PropTypes.string),
    'type[]': PropTypes.arrayOf(PropTypes.string),
    'rentalType[]': PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  isFooterInSearchPage: PropTypes.bool
};

FiltersHeader.defaultProps = {
  isFooterInSearchPage: false
};

export default FiltersHeader;
