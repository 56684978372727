import { useRef } from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getColor, AVAILABLE_COLORS } from '../utils/colors';

import styles from './Tooltip.module.scss';

const LABEL_COLOR_CSS_VARIABLE = '--tooltip-label-color';

const POSITION_TOP = 'top';
const POSITION_RIGHT = 'right';
const POSITION_LEFT = 'left';
const POSITION_BOTTOM = 'bottom';

const POSITIONS = [
  POSITION_TOP,
  POSITION_RIGHT,
  POSITION_BOTTOM,
  POSITION_LEFT
];

const POSITIONS_OPPOSED = {
  [POSITION_TOP]: POSITION_BOTTOM,
  [POSITION_RIGHT]: POSITION_LEFT,
  [POSITION_BOTTOM]: POSITION_TOP,
  [POSITION_LEFT]: POSITION_RIGHT
};

const useTooltipSmartPosition = initialPositionList => {
  const tooltipRef = useRef();

  const checkOverlappings = () => {
    const tooltipBounds = tooltipRef.current.getBoundingClientRect();
    const bodyBounds = document.body.getBoundingClientRect();

    return {
      [POSITION_TOP]: tooltipBounds.top < bodyBounds.top,
      [POSITION_RIGHT]: tooltipBounds.right > bodyBounds.right,
      [POSITION_BOTTOM]: tooltipBounds.bottom > bodyBounds.bottom,
      [POSITION_LEFT]: tooltipBounds.left < bodyBounds.left
    };
  };

  const addPositionClassName = position => {
    tooltipRef.current.classList.add(styles[`tooltip__text--${position}`]);
  };

  const removePositionClassName = position => {
    tooltipRef.current.classList.remove(styles[`tooltip__text--${position}`]);
  };

  const cleanPositionClassNames = () => {
    POSITIONS.forEach(pos => removePositionClassName(pos));
  };

  const optimizePosition = () => {
    const overlap = checkOverlappings();

    const optimalPositions = POSITIONS.reduce(
      (acc, position) => [
        ...acc,
        overlap[position] ? POSITIONS_OPPOSED[position] : null
      ],
      []
    );

    initialPositionList.forEach(initialPosition => {
      if (!overlap[initialPosition]) {
        optimalPositions.push(initialPosition);
      }
    });

    const optimalPositionFiltered = optimalPositions.filter(
      position => position
    );

    if (optimalPositionFiltered.length) {
      cleanPositionClassNames();
      optimalPositionFiltered.forEach(position => {
        addPositionClassName(position);
      });
    }
  };

  return {
    tooltipRef,
    addPositionClassName,
    cleanPositionClassNames,
    optimizePosition
  };
};

const Tooltip = ({
  color = null,
  className = '',
  textClassName = '',
  tooltip = '',
  position = 'top',
  children,
  modifier = '',
  smartPosition = true,
  isRebranded = false,
  ...restProps
}) => {
  const positionList = Array.isArray(position) ? position : [position];
  const {
    tooltipRef,
    cleanPositionClassNames,
    optimizePosition,
    addPositionClassName
  } = useTooltipSmartPosition(positionList);

  const tooltipContainerClasses = classNames(className, {
    [styles.tooltip]: tooltip
  });

  const tooltipContentClasses = classNames(
    styles.tooltip__text,
    textClassName,
    {
      [styles[`tooltip__text--${modifier}`]]: modifier
    },
    isRebranded ? styles['tooltip__text--rebranded'] : ''
  );

  const handleTooltipClick = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleMouseEnter = () => {
    positionList.forEach(addPositionClassName);

    if (smartPosition) {
      optimizePosition();
    }
  };

  const handleMouseLeave = () => {
    cleanPositionClassNames();
  };

  return tooltip ? (
    <div
      role="button"
      tabIndex="0"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={tooltipContainerClasses}
      onClick={handleTooltipClick}
      style={{
        [LABEL_COLOR_CSS_VARIABLE]: isRebranded
          ? getColor('white')
          : getColor(color)
      }}
      {...restProps}
    >
      <span ref={tooltipRef} className={tooltipContentClasses}>
        {tooltip}
      </span>
      {children}
    </div>
  ) : (
    children
  );
};

Tooltip.propTypes = {
  color: PropTypes.oneOf(AVAILABLE_COLORS),
  className: PropTypes.string,
  textClassName: PropTypes.string,
  tooltip: PropTypes.node,
  position: PropTypes.oneOfType([
    PropTypes.oneOf(POSITIONS),
    PropTypes.arrayOf(PropTypes.oneOf(POSITIONS))
  ]),
  children: PropTypes.node.isRequired,
  modifier: PropTypes.string,
  smartPosition: PropTypes.bool,
  isRebranded: PropTypes.bool
};

export const withTooltip =
  (Component, position) =>
  ({ tooltip, ...props }) =>
    tooltip ? (
      <Tooltip position={position} tooltip={tooltip} isRebranded>
        <Component {...props} />
      </Tooltip>
    ) : (
      <Component {...props} />
    );

Tooltip.POSITION_TOP = POSITION_TOP;
Tooltip.POSITION_RIGHT = POSITION_RIGHT;
Tooltip.POSITION_LEFT = POSITION_LEFT;
Tooltip.POSITION_BOTTOM = POSITION_BOTTOM;

export default Tooltip;
