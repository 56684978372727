import { useState, useContext, createContext } from 'react';
import PropTypes from 'prop-types';

const HighlightMarkersContext = createContext({
  handleCardMouseEnter: () => {},
  handleCardMouseLeave: () => {},
  handleMarkerClicked: () => {},
  homecardMouseEnterId: null,
  markerClickedId: null
});

function scrollToCard(markerId) {
  if (markerId) {
    const homecard = document.querySelector(
      `[data-homecard-scroll="${markerId}"]`
    );

    if (homecard) {
      homecard.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start'
      });
    }
  }
}

export const HighlightMarkersProvider = ({ children }) => {
  const [homecardMouseEnterId, setHomecardMouseEnterId] = useState(null);
  const [markerClickedId, setMarkerClickedId] = useState(null);

  const handleCardMouseEnter = adId => {
    setHomecardMouseEnterId(parseInt(adId, 10));
    setMarkerClickedId(null);
  };

  const handleCardMouseLeave = () => {
    setHomecardMouseEnterId(null);
  };

  const handleMarkerClicked = clickedMarkerId => {
    if (markerClickedId && clickedMarkerId === markerClickedId) {
      setMarkerClickedId(null);
    } else {
      setMarkerClickedId(parseInt(clickedMarkerId, 10));
      scrollToCard(clickedMarkerId);
    }
  };

  return (
    <HighlightMarkersContext.Provider
      value={{
        handleCardMouseEnter,
        handleCardMouseLeave,
        handleMarkerClicked,
        homecardMouseEnterId,
        markerClickedId
      }}
    >
      {children}
    </HighlightMarkersContext.Provider>
  );
};

HighlightMarkersProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export const useHighlightMarkersContext = () =>
  useContext(HighlightMarkersContext);

export const HighlightMarkersConsumer = HighlightMarkersContext.Consumer;

export const useHomecardMouseEnterId = () => {
  const { homecardMouseEnterId } = useHighlightMarkersContext();

  return homecardMouseEnterId;
};

export const useMarkerClickedId = () => {
  const { markerClickedId } = useHighlightMarkersContext();

  return markerClickedId;
};

export default {
  HighlightMarkersProvider,
  HighlightMarkersConsumer,
  useHighlightMarkersContext,
  useHomecardMouseEnterId,
  useMarkerClickedId
};
