import PropTypes from 'prop-types';

import themes from '../utils/iconColorThemes';

import styles from '../Favourite/Favourite.module.scss';

const FavouriteIcon = ({
  theme = 'base',
  stroke = 'favourite',
  opacity = 0.59,
  width = 26,
  height = 24
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 26 24"
    data-test="favorite-icon"
  >
    <path
      fill={theme !== 'empty' ? themes[theme] : 'none'}
      stroke={themes[stroke]}
      d="M14.838,21.793 C16.1664969,20.8082414 17.4510698,19.7655686 18.688,18.668 C22.114,15.614 24.385,12.72 24.916,10.273 C25.1372749,9.55273562 25.25,8.80348745 25.25,8.05 C25.25,5.395 23.882,2.943 21.696,1.657 L21.689,1.653 C20.6837081,1.06403384 19.5401124,0.752426441 18.375,0.75 C16.557,0.75 14.832,1.504 13.547,2.859 L13.004,3.431 L12.46,2.861 C11.168,1.504 9.438,0.75 7.62,0.75 C6.45702723,0.754247176 5.31583177,1.06576456 4.312,1.653 C2.118,2.938 0.75,5.39 0.75,8.05 C0.75,8.804 0.862,9.552 1.1,10.335 C1.613,12.72 3.886,15.613 7.315,18.668 C8.55324262,19.7656512 9.8391512,20.8083249 11.169,21.793 C11.7703874,22.2425988 12.3782218,22.5790515 13,23 C13.423,22.716 14.063,22.37 14.838,21.793 Z"
      strokeWidth={1.5}
      opacity={theme === 'favourite' ? 1 : opacity}
      className={styles.favourite}
    />
  </svg>
);

FavouriteIcon.propTypes = {
  theme: PropTypes.oneOf(Object.keys(themes)),
  stroke: PropTypes.oneOf(Object.keys(themes)),
  opacity: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number
};

export default FavouriteIcon;
