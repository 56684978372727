import { formatPrice } from '@spotahome/soyuz-currency';

import { getBestOffer } from '@spotahome/ui-library/src/tenant/components/utils/offers';

import { HOMECARD_IMAGE_SIZES } from '../constants';

export const getHomecardImageSet = photoUrls =>
  Object.keys(photoUrls)
    .filter(size => photoUrls[size] && HOMECARD_IMAGE_SIZES[size])
    .map(size => `${photoUrls[size]} ${HOMECARD_IMAGE_SIZES[size]}`)
    .join(', ');

export const getUrlWithMovingDates = (url, moveIn, moveOut, rentalTypes) => {
  let getParams = [];
  getParams = moveIn ? [...getParams, `move-in=${moveIn}`] : getParams;
  getParams = moveOut ? [...getParams, `move-out=${moveOut}`] : getParams;

  rentalTypes.forEach(rentalType => {
    getParams = [...getParams, `rentalType[]=${rentalType}`];
  });

  return getParams.length ? `${url}?${getParams.join('&')}` : url;
};

export const getUrlWithMapParameters = (url, moveIn, moveOut, mapCenter) => {
  let getParams = [];
  getParams = moveIn ? [...getParams, `move-in=${moveIn}`] : getParams;
  getParams = moveOut ? [...getParams, `move-out=${moveOut}`] : getParams;
  getParams = mapCenter ? [...getParams, `mapCenter=${mapCenter}`] : getParams;

  const urlStartType = url.includes('?') ? '&' : '?';

  return getParams.length
    ? `${url}${urlStartType}map=true&${getParams.join('&')}`
    : `${url}${urlStartType}map=true`;
};

export const getBestOfferDiscount = (offers = [], currencyIsoCode) => {
  const bestOffer = getBestOffer(offers);

  if (!bestOffer) {
    return null;
  }

  let discount = `${bestOffer.discountValue} %`;
  if (bestOffer.discountType === 'fixed') {
    discount = formatPrice(bestOffer.discountValue, currencyIsoCode);
  }

  return discount;
};

export const getRatingFromReviews = (reviews = {}) => reviews.ratingAverage;

const MAIN_BILLS = {
  Electricity: 5000,
  Water: 5001,
  Gas: 5002,
  Wifi: 5003
};

const includeNaFeatures = bill => (bill === 'na' ? true : bill);

export const isAllBillsIncluded = (bills = {}) =>
  Object.keys(MAIN_BILLS).every(billId =>
    includeNaFeatures(bills[MAIN_BILLS[billId]])
  );

export const isSomeBillsIncluded = (bills = {}) =>
  Object.keys(MAIN_BILLS).some(billId => bills[MAIN_BILLS[billId]] === true);

const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://photos.spotahome.com'
    : 'https://sah-staging-photos-resized.s3-eu-west-1.amazonaws.com';

const FORMAT_320 = 'pt_320_240';
const FORMAT_640 = 'pt_640_480';

export const getHomecardPhoto = (imageId, format) =>
  `${BASE_URL}/${format}/${imageId}.jpg`;

export const convertPhotoIdsToPhotosStructure = photoIds =>
  photoIds?.map(id => {
    return {
      src: getHomecardPhoto(id, FORMAT_640)
    };
  });

export const converPhotoIdToPhotoUrlsStructure = photoId => {
  return {
    homecardHidpi: getHomecardPhoto(photoId, FORMAT_640),
    homecard: getHomecardPhoto(photoId, FORMAT_320)
  };
};

export const getPhotoUrls = photoIds =>
  photoIds?.length ? converPhotoIdToPhotoUrlsStructure(photoIds[0]) : {};

export const getPhotos = (photoIds, otherPropertyPhotoIds) => [
  ...convertPhotoIdsToPhotosStructure(photoIds),
  ...(convertPhotoIdsToPhotosStructure(otherPropertyPhotoIds) ?? [])
];

export const getMonthlyPrice = displayPrice => ({
  type: 'fixed',
  minimumPrice: displayPrice,
  fixedPrice: displayPrice
});
