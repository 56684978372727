import PropTypes from 'prop-types';

const CheckIcon = props => {
  const { className, color } = props;
  return (
    <svg
      width="16px"
      height="13px"
      viewBox="0 0 16 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
      {...props}
    >
      <g
        id="How-it-works"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(-96.000000, -165.000000)"
          fill={color}
          id="Fill-2"
        >
          <polygon points="110.239354 165 102.134968 174.447016 97.6317384 169.959383 96 171.754023 102.267728 178 112 166.655456" />
        </g>
      </g>
    </svg>
  );
};

CheckIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string
};

CheckIcon.defaultProps = {
  color: '#32B496',
  className: ''
};

export default CheckIcon;
