const RATING_VALUE = '3.9';
const RATING_REVIEWS_COUNT = '9253';
const BEST_RATING = '5';
const WORST_RATING = '0';

export default {
  '@type': 'AggregateRating',
  ratingValue: RATING_VALUE,
  ratingCount: RATING_REVIEWS_COUNT,
  bestRating: BEST_RATING,
  worstRating: WORST_RATING
};
