import PropTypes from 'prop-types';

export default {
  title: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      anchorText: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  )
};
