export const navigateTo = (link, { newTab }) => {
  if (window) {
    const method = newTab
      ? window.open
      : window.location.assign.bind(window.location);

    method(link);
  }
};

export default { navigateTo };
