import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './BaseChip.module.scss';

const SCHEMAS = {
  filter: 'filter',
  choice: 'choice',
  tag: 'tag',
  blue: 'blue',
  pink: 'pink'
};

const BaseChip = ({
  renderLeadingIcon = null,
  renderTrailingIcon = null,
  label,
  colorSchema,
  onClick = () => {},
  isSelected = false,
  shadowed = false,
  withBorder = false,
  isActivated = false,
  isDarkMode = false,
  isOverlay = false,
  className = null,
  dataTest = null,
  isSpaced = false
}) => {
  const selectableProps = onClick
    ? {
        tabIndex: 0,
        role: 'button',
        onClick
      }
    : {
        tabIndex: -1
      };

  const chipClassNames = classNames(
    styles.chip,
    styles[`chip--schema-${colorSchema}`],
    {
      [styles[`chip--schema-${colorSchema}--with-border`]]: withBorder,
      [styles[`chip--shadowed`]]: shadowed,
      [styles[`chip--schema-${colorSchema}--dark-mode`]]: isDarkMode,
      [styles[`chip--schema-${colorSchema}--selected`]]: isSelected,
      [styles[`chip--schema-${colorSchema}--activated`]]:
        isActivated && !isDarkMode,
      [styles[`chip--schema-${colorSchema}--activated-dark`]]:
        isActivated && isDarkMode,
      [styles[`chip--schema-${colorSchema}--overlay`]]: isOverlay
    },
    className
  );
  const labelClassNames = classNames(styles.chip__label, {
    [styles['chip__label--spaced']]: isSpaced
  });

  return (
    <div className={chipClassNames} {...selectableProps} data-test={dataTest}>
      {renderLeadingIcon ? renderLeadingIcon() : null}
      <div className={labelClassNames}>{label}</div>
      {renderTrailingIcon ? renderTrailingIcon() : null}
    </div>
  );
};

BaseChip.SCHEMAS = SCHEMAS;

BaseChip.propTypes = {
  onClick: PropTypes.func,
  renderLeadingIcon: PropTypes.func,
  renderTrailingIcon: PropTypes.func,
  isSelected: PropTypes.bool,
  isActivated: PropTypes.bool,
  shadowed: PropTypes.bool,
  withBorder: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ]).isRequired,
  colorSchema: PropTypes.oneOf(Object.values(SCHEMAS)).isRequired,
  className: PropTypes.string,
  isDarkMode: PropTypes.bool,
  isOverlay: PropTypes.bool,
  dataTest: PropTypes.string,
  isSpaced: PropTypes.bool
};

export default BaseChip;
