import PropTypes from 'prop-types';
import classNames from 'classnames';

import { GalleryArrowRight, GalleryArrowLeft } from '../icons/GalleryArrow';
import {
  GalleryArrowWithBackgroundRight,
  GalleryArrowWithBackgroundLeft
} from '../icons/GalleryArrowWithBackground';

import styles from './GalleryArrow.module.scss';

const GalleryArrow = ({
  navRole,
  onClick,
  style = 'small',
  dataTest = '',
  withBackground = false
}) => {
  const LeftChevronIcon = withBackground
    ? GalleryArrowWithBackgroundLeft
    : GalleryArrowLeft;
  const RightChevronIcon = withBackground
    ? GalleryArrowWithBackgroundRight
    : GalleryArrowRight;
  return (
    <div
      className={classNames(
        styles['gallery-arrow'],
        styles[`gallery-arrow--${navRole}`]
      )}
    >
      <div
        onClick={onClick}
        role="button"
        tabIndex="0"
        className={classNames(styles.pointer, {
          [styles['pointer--small']]: style === 'small' && !withBackground,
          [styles['pointer--big']]: style === 'big' && !withBackground,
          [styles['pointer--with-background']]: withBackground
        })}
        data-test={dataTest}
      >
        {navRole === 'next' ? (
          <RightChevronIcon className={styles['pointer-shadow']} />
        ) : (
          <LeftChevronIcon className={styles['pointer-shadow']} />
        )}
      </div>
    </div>
  );
};

GalleryArrow.propTypes = {
  navRole: PropTypes.oneOf(['next', 'prev']).isRequired,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.oneOf(['small', 'big']),
  dataTest: PropTypes.string,
  withBackground: PropTypes.bool
};

export default GalleryArrow;
