import { trans } from '@spotahome/soyuz/client';

import { BILLS_INCLUDED_VALUES } from '../tenant/constants';

import BestChoiceLabelIcon from '../tenant/components/icons/BestChoiceLabelIcon';
import BillsLabelIcon from '../tenant/components/icons/BillsLabelIcon';
import InstantBookingLabelIcon from '../tenant/components/icons/InstantBookingLabelIcon';
import LongTermLabelIcon from '../tenant/components/icons/LongTermLabelIcon';
import NoDepositLabelIcon from '../tenant/components/icons/NoDepositLabelIcon';
import PlusLabelIcon from '../tenant/components/icons/PlusLabelIcon';
import PiggyBankLabelIcon from '../tenant/components/icons/PiggyBankLabelIcon';
import LockIcon from '../tenant/components/icons/LockIcon';

export const getListingChips = (
  isNoSecurityDeposit,
  isInstantBooking,
  bills,
  isBestChoice,
  isPlus,
  isLongTerm,
  {
    useSecurityDepositAlternativeIcon = false,
    addDepositProjectionChip = false
  } = {}
) => {
  const chips = [];

  if (isNoSecurityDeposit) {
    chips.push({
      label: `${trans('homecard.label.no_deposit')}`,
      tooltip: `${trans('tooltip.message.no-deposit')}`,
      renderLeadingIcon: () =>
        useSecurityDepositAlternativeIcon ? (
          <PiggyBankLabelIcon size="20" />
        ) : (
          <NoDepositLabelIcon size="20" />
        ),
      isSpaced: true
    });
  }

  if (!isNoSecurityDeposit && addDepositProjectionChip) {
    chips.push({
      label: trans('homecard.label.deposit.protected'),
      renderLeadingIcon: () => <LockIcon width="15" height="18" />,
      isSpaced: true
    });
  }

  if (isInstantBooking) {
    chips.push({
      label: trans('homecard.label.instant'),
      renderLeadingIcon: () => <InstantBookingLabelIcon size="20" />,
      isSpaced: true
    });
  }

  if (bills !== BILLS_INCLUDED_VALUES.none) {
    chips.push({
      label:
        BILLS_INCLUDED_VALUES.all === bills
          ? trans('homecard.bills.included')
          : trans('homecard.bills.some-included'),
      renderLeadingIcon: () => <BillsLabelIcon size="20" />,
      isSpaced: true
    });
  }

  if (isBestChoice) {
    chips.push({
      label: trans('homecard.label.best-choice'),
      tooltip: trans('homecard.label.best-choice.tooltip'),
      renderLeadingIcon: () => <BestChoiceLabelIcon size="20" />,
      isSpaced: true
    });
  }

  if (isPlus) {
    chips.push({
      label: 'Spotahome PLUS',
      renderLeadingIcon: () => <PlusLabelIcon size="20" />,
      isSpaced: true
    });
  }

  if (isLongTerm) {
    chips.push({
      label: trans('homecard.label.long-term'),
      renderLeadingIcon: () => <LongTermLabelIcon size="20" />,
      isSpaced: true
    });
  }

  return chips.map(chip => ({
    ...chip,
    label: chip?.keepLabelCase ? chip.label : chip.label.toUpperCase()
  }));
};

const MAIN_BILLS = {
  Electricity: 5000,
  Water: 5001,
  Gas: 5002,
  Wifi: 5003
};

const includeNaFeatures = bill => (bill === 'na' ? true : bill);

export const isAllBillsIncluded = (bills = {}) =>
  Object.keys(MAIN_BILLS).every(billId =>
    includeNaFeatures(bills[MAIN_BILLS[billId]])
  );

export const isSomeBillsIncluded = (bills = {}) =>
  Object.keys(MAIN_BILLS).some(billId => bills[MAIN_BILLS[billId]] === true);

export const getBillsIncluded = bills => {
  if (isAllBillsIncluded(bills)) {
    return BILLS_INCLUDED_VALUES.all;
  }
  if (isSomeBillsIncluded(bills)) {
    return BILLS_INCLUDED_VALUES.some;
  }

  return BILLS_INCLUDED_VALUES.none;
};
