import PropTypes from 'prop-types';
import { getResourcePath, trans } from '@spotahome/soyuz/client';
import classNames from 'classnames';

import './HomeCardBannerImage.scss';

const HomeCardBannerImage = ({
  bannerName,
  children,
  fullHeight,
  mobileImage
}) => {
  const imageUrl = `/images/homecard-banner/${bannerName}.${
    mobileImage ? 'mobile' : 'web'
  }.jpg`;

  const homeCardBannerImageCn = classNames({
    'home-card-banner-image': true,
    'home-card-banner-image--full-height': fullHeight
  });

  return (
    <div className={homeCardBannerImageCn}>
      <img
        className="home-card-banner-image__img"
        src={getResourcePath(imageUrl)}
        alt={trans(`homecard.banner.${bannerName}.label`)}
      />
      {children}
    </div>
  );
};

HomeCardBannerImage.propTypes = {
  bannerName: PropTypes.string,
  children: PropTypes.node,
  mobileImage: PropTypes.bool,
  fullHeight: PropTypes.bool
};

HomeCardBannerImage.defaultProps = {
  bannerName: '',
  children: null,
  mobileImage: false,
  fullHeight: false
};

export default HomeCardBannerImage;
