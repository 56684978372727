import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useCookie } from '@spotahome/ui-library';

const EXPIRATION = 1000 * 60 * 60 * 24 * 7; // 7 days in ms

const HomeCardBanner = ({
  name,
  component: BannerComponent,
  isOneColumnView
}) => {
  const CookieManager = useCookie();
  const [show, setShow] = useState(false);
  const cookieName = `sah-banner-${name}`;

  const handleHideClick = () => {
    CookieManager.setCookie(cookieName, 1, { maxAge: EXPIRATION });
    setShow(false);
  };

  useEffect(() => {
    const cookie = CookieManager.getCookie(cookieName);

    if (!cookie) {
      setShow(true);
    }
  }, []);

  return show ? (
    <div className="l-list__item">
      <BannerComponent
        isOneColumnView={isOneColumnView}
        onHideClick={handleHideClick}
      />
    </div>
  ) : null;
};

HomeCardBanner.propTypes = {
  name: PropTypes.string.isRequired,
  component: PropTypes.element.isRequired,
  isOneColumnView: PropTypes.bool
};

HomeCardBanner.defaultProps = {
  isOneColumnView: false
};

export default HomeCardBanner;
