export default [
  {
    name: 'wifi',
    labelKey: 'search.filter.wifi',
    tracking: 'ga-search-form-wifi',
    cy: 'bills-wifi'
  },
  {
    name: 'electricity',
    labelKey: 'search.filter.electricity',
    tracking: 'ga-search-form-electricity',
    cy: 'bills-electricity'
  },
  {
    name: 'water',
    labelKey: 'search.filter.water',
    tracking: 'ga-search-form-water',
    cy: 'bills-water'
  },
  {
    name: 'gas',
    labelKey: 'search.filter.gas',
    tracking: 'ga-search-form-gas',
    cy: 'bills-gas'
  }
];
