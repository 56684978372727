import PropTypes from 'prop-types';

import Colors from '../utils/colors';

const InfoIcon = ({ size = 16, ...props }) => (
  <svg
    {...props}
    width={`${size}px`}
    height={`${size}px`}
    viewBox="211 0 25 26"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      transform="translate(211.000000, 0.000000)"
    >
      <path
        d="M12.8,6.7 C11.9,6.7 11.1,7.5 11.1,8.4 C11.1,9.3 11.9,10.1 12.8,10.1 C13.7,10.1 14.5,9.3 14.5,8.4 C14.5,7.4 13.7,6.7 12.8,6.7 Z"
        id="Shape"
        fill={Colors.interaction}
      />
      <rect
        fill={Colors.interaction}
        x="11.2"
        y="11.9"
        width="3.2"
        height="7.3"
      />
      <path
        d="M12.5,0.5 C5.6,0.5 0,6.1 0,13 C0,19.9 5.6,25.5 12.5,25.5 C19.4,25.5 25,19.9 25,13 C25,6.1 19.4,0.5 12.5,0.5 Z M12.5,24.1 C6.4,24.1 1.4,19.2 1.4,13 C1.4,6.8 6.4,1.9 12.5,1.9 C18.6,1.9 23.6,6.9 23.6,13 C23.6,19.1 18.6,24.1 12.5,24.1 Z"
        id="Shape"
        fill={Colors.interaction}
      />
    </g>
  </svg>
);

InfoIcon.propTypes = {
  size: PropTypes.number
};

export default InfoIcon;
