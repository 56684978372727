/* global ga */
import { getLoginImagePath } from '../utils/getExternalImagePath';

import FooterSimplifiedBlockList from './components/FooterSimplifiedBlockList';
import FooterSimplifiedLink from './components/FooterSimplifiedLink';
import FooterSimplifiedStoreIcon from './components/FooterSimplifiedStoreIcon';

const IOS_APP_INFO = {
  link: 'https://spotahome.app.link/ios_footer_home',
  eventCategory: 'app-promotion',
  eventAction: 'footer-icon-ios',
  eventLabel: 'open'
};
const ANDROID_APP_INFO = {
  link: 'https://spotahome.app.link/android_footer_home',
  eventCategory: 'app-promotion',
  eventAction: 'footer-icon-android',
  eventLabel: 'open'
};

const track = ({ eventCategory, eventAction, eventLabel }) => {
  if (typeof ga === 'function') {
    ga('send', {
      hitType: 'event',
      eventCategory,
      eventAction,
      eventLabel
    });
  }
};

const AppFooter = () => (
  <FooterSimplifiedBlockList isInline>
    <FooterSimplifiedLink
      href={IOS_APP_INFO.link}
      onClick={() => track(IOS_APP_INFO)}
    >
      <FooterSimplifiedStoreIcon
        src={getLoginImagePath('app-store.png')}
        alt="App Store"
      />
    </FooterSimplifiedLink>
    <FooterSimplifiedLink
      href={ANDROID_APP_INFO.link}
      onClick={() => track(ANDROID_APP_INFO)}
    >
      <FooterSimplifiedStoreIcon
        src={getLoginImagePath('google-play.png')}
        alt="Google Play Store"
      />
    </FooterSimplifiedLink>
  </FooterSimplifiedBlockList>
);

export default AppFooter;
