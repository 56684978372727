import gql from 'graphql-tag';

import getGqlClient from '../../clients/graphql/marketplace';

const QUERY_UNIT_PRICES = gql`
  query UnitPrices($cityId: String!, $filters: FiltersInput) {
    unitPrices(cityId: $cityId, filters: $filters)
  }
`;

export const getUnitPricesQuery = client => (cityId, filters) =>
  client.query({
    query: QUERY_UNIT_PRICES,
    variables: {
      cityId,
      filters
    }
  });

export const requestUnitPrices = async ({
  cityId,
  adaptedFilters,
  maxPriceInCity
}) => {
  try {
    const { data } = await getUnitPricesQuery(getGqlClient())(
      cityId,
      adaptedFilters
    );

    return data.unitPrices.filter(price => price <= maxPriceInCity);
  } catch (error) {
    if (error.message !== 'CANCELED') {
      console.error(error);
    }
    return [];
  }
};
