export default bathroomsTransKey => [
  {
    id: 'f1bathroom-id',
    value: '1',
    label: '1',
    tracking: 'ga-search-form-toggle-1-bathroom',
    cy: 'bathroom-1'
  },
  {
    id: 'f1morebathroom-id',
    value: '1more',
    label: `${bathroomsTransKey}`,
    tracking: 'ga-search-form-toggle-1more-bathroom',
    cy: 'bathroom-1more'
  }
];
