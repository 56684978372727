import { forwardRef } from 'react';
import PropTypes from 'prop-types';

const NoDepositLabelIcon = forwardRef((props, ref) => (
  <svg
    {...props}
    ref={ref}
    width={`${props.size}px`}
    height={`${props.size}px`}
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.936 21.9134L9.15778 25.6916L6.76208 23.2959L10.5403 19.5177L12.936 21.9134Z"
      stroke="#0D0D0D"
      strokeWidth="1.5"
    />
    <path
      d="M4.76086 25.4394L2.01722 22.696L12.9131 11.7999L15.6568 14.5433L4.76086 25.4394Z"
      stroke="#0D0D0D"
      strokeWidth="1.5"
    />
    <path
      d="M14.1659 3.63487C16.7363 1.06442 20.9038 1.06442 23.4743 3.63487C26.0448 6.20532 26.0448 10.3728 23.4743 12.9433C20.9039 15.5137 16.7363 15.5137 14.1659 12.9433C11.5954 10.3728 11.5954 6.20532 14.1659 3.63487Z"
      stroke="#0D0D0D"
      strokeWidth="1.5"
    />
    <path
      d="M17.4464 7.03366C18.1722 6.30779 19.3491 6.30779 20.075 7.03366C20.8008 7.75952 20.8008 8.93638 20.075 9.66225C19.3491 10.3881 18.1722 10.3881 17.4464 9.66225C16.7205 8.93638 16.7205 7.75952 17.4464 7.03366Z"
      stroke="#0D0D0D"
      strokeWidth="1.5"
    />
  </svg>
));

NoDepositLabelIcon.defaultProps = {
  size: 40,
  className: ''
};

NoDepositLabelIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string
};

export default NoDepositLabelIcon;
