import { trans } from '@spotahome/soyuz/client';

import FlipBackIcon from './FlipBackIcon';

import SelectBullet from './SelectBullet';
import './SelectBullet.scss';
import './SelectBannerBack.scss';

const SelectBannerBack = () => {
  return (
    <div className="select-banner-back__container">
      <div className="select-banner-back__message">
        {trans(`homecard.banner.select.message`)}
      </div>
      <SelectBullet index={1} />
      <SelectBullet index={2} />
      <SelectBullet index={3} />
      <SelectBullet index={4} />
      <FlipBackIcon
        width="20px"
        height="20px"
        viewBox="0 0 24 24"
        className="select-banner-back__back-icon"
      />
    </div>
  );
};

export default SelectBannerBack;
