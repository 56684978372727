import getHomecardsQuery from '@spotahome/marketplace-common/src/graphql/queries/homecards';
import getGqlClient from '@spotahome/marketplace-common/src/graphql/client';

function indexCardsById(cards) {
  return cards
    .filter(card => card)
    .reduce(
      (acc, card) => ({
        ...acc,
        [card.id]: {
          ...card
        }
      }),
      {}
    );
}

export const getCardsFromIds = async (cardIds, locale) => {
  let newCards = {};
  if (cardIds.length) {
    try {
      const res = await getHomecardsQuery(getGqlClient())(cardIds, locale);

      if (res.data) {
        newCards = indexCardsById(res.data.homecards);
      }
    } catch (err) {
      console.error(err);
    }
  }

  return newCards;
};

export default {
  getCardsFromIds
};
