const HomecardImagePlaceholder = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 304 235"
    data-test="homecard-image-placeholder"
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#EFEFEF" d="M0 0h304v235H0z" />
      <g fill="#DFDFDF" transform="translate(138 114)">
        <circle cx="3.5" cy="3.5" r="3.5" />
        <circle cx="14.5" cy="3.5" r="3.5" />
        <circle cx="25.5" cy="3.5" r="3.5" />
      </g>
    </g>
  </svg>
);

export default HomecardImagePlaceholder;
