import { trans } from '@spotahome/soyuz/client';

import PropTypes from 'prop-types';

import CheckIcon from '../../../../components/icons/CheckIcon';

import './SelectBullet.scss';

const SelectBullet = ({ index }) => (
  <div className="select-bullet__item">
    <CheckIcon
      width="10px"
      height="10px"
      viewBox="0 -1 15 15"
      color="#8143ff"
      className="select-bullet__icon"
    />
    <p className="select-bullet__text">
      {trans(`homecard.banner.select.bullet.${index}`)}
    </p>
  </div>
);

SelectBullet.propTypes = {
  index: PropTypes.number.isRequired
};

export default SelectBullet;
