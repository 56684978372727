import { useState } from 'react';

import { Mediator } from '@spotahome/soyuz-mediator';
import { trans } from '@spotahome/soyuz/client';
import Metrics from '@spotahome/soyuz-tracking';
import { useCookie, useUserInfoContext } from '@spotahome/ui-library';

import SoyuzAnalytics from '@spotahome/soyuz-analytics';

import { COMMON_LOGIN_MODAL } from '../channels';
import getGqlClient from '../graphql/client';
import { addFavorite, removeFavorite } from '../graphql/mutations/favorites';

const noOp = () => {};

const COOKIE_NAME = 'sah-guest-used-favorite';
const COOKIE_VALUE = 1;
const COOKIE_DURATION_30_MINS = 1800;

const useFavorite = ({
  id,
  cityId,
  propertyType,
  price,
  position,
  isFavorite: isInitialFavorite,
  favoritesCount: initialFavoritesCount,
  onToggleFavorite = noOp,
  pageType, // `search` or `product_page`
  section // 'listing', 'other_rooms', 'similar_properties'
}) => {
  const { isUserLogged } = useUserInfoContext();
  const { getCookie, setCookie } = useCookie();
  const [isFavorite, setIsFavorite] = useState(isInitialFavorite);
  const [favoritesCount, setFavoritesCount] = useState(initialFavoritesCount);

  const markFavoriteSilently = async () => {
    if (!id) {
      console.error('Can not toggle a favorite without an id');
      return;
    }
    try {
      await addFavorite(getGqlClient())(id);
    } catch (error) {
      console.error(error);
    }
  };

  const markAsFavorite = async (favoriteId, isLogged) => {
    await addFavorite(getGqlClient())(favoriteId);
    if (!isLogged && !getCookie(COOKIE_NAME)) {
      setCookie(COOKIE_NAME, COOKIE_VALUE, {
        maxAge: COOKIE_DURATION_30_MINS
      });
      Mediator.publish(COMMON_LOGIN_MODAL, {
        initialAction: 'signup',
        renderTitle: action => trans(`${action}.modal.title`),
        renderSubtitle: action => trans(`${action}.modal.subtitle`),
        events: {
          open: {
            eventCategory: 'signup/login modal',
            eventAction: 'FavoriteClaim',
            eventLabel: 'open'
          },
          close: {
            eventCategory: 'signup/login modal',
            eventAction: 'FavoriteClaim',
            eventLabel: 'close'
          }
        }
      });
    }
    Metrics.ga.sendEvent('favorite-button', 'enable-click', favoriteId);

    SoyuzAnalytics.sendGA4Event('add_to_wishlist', {
      items: [
        {
          item_id: favoriteId,
          item_category: cityId,
          item_category2: propertyType,
          price,
          quantity: 1,
          item_list_id: section,
          index: position ?? 0
        }
      ]
    });

    Metrics.actions.sendActionEvent(Metrics.actions.SAVE_FAVORITE, {
      listingId: `${id}`,
      pageType,
      section: section || undefined
    });
  };

  const unmarkAsFavorite = async favoriteId => {
    await removeFavorite(getGqlClient())(id);
    Metrics.ga.sendEvent('favorite-button', 'disable-click', favoriteId);
  };

  const toggleFavorite = async () => {
    if (!id) {
      console.error('Can not toggle a favorite without an id');
      return;
    }
    const newIsFavorite = !isFavorite;
    setIsFavorite(newIsFavorite);
    setFavoritesCount(newIsFavorite ? favoritesCount + 1 : favoritesCount - 1);

    try {
      const action = newIsFavorite ? markAsFavorite : unmarkAsFavorite;
      await action(id, isUserLogged);
      onToggleFavorite(id, newIsFavorite);
    } catch (error) {
      console.error(error);
      setIsFavorite(!newIsFavorite);
    }
  };

  return {
    isFavorite,
    favoritesCount,
    toggleFavorite,
    markFavoriteSilently
  };
};

export default useFavorite;
