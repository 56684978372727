export const RATIOS = {
  HOMECARD: '4:3',
  PANORAMIC: '16:9'
};

export const KEYS = {
  NEXT_ARROW: 39,
  PREV_ARROW: 37
};

export const IMAGE_NAVIGATION = {
  prev: 'previous',
  next: 'next'
};

export default {
  KEYS,
  IMAGE_NAVIGATION,
  RATIOS
};
