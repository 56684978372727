import { DateRangeHandler } from '@spotahome/ui-library';

export const getMonthsFirstPayment = (checkIn, checkOut, payments) => {
  const months = DateRangeHandler.getMonths(checkIn, checkOut);

  return parseInt(
    months[payments.findIndex(price => price === 0 || price === '0')],
    10
  );
};

export const getRentableUnitPrice = (
  type,
  minimumPrice,
  currencyCode,
  pricesByMonth,
  fixedPrice,
  { moveIn, moveOut } = {},
  { flexibleMoveIn, flexibleMoveOut } = {}
) => {
  const monthsStaying = DateRangeHandler.getMonthsStaying(
    { moveIn, moveOut },
    { flexibleMoveIn, flexibleMoveOut }
  );

  const price = {
    type,
    amount: minimumPrice,
    currencyCode,
    fixedPrice,
    seasonalPrice: null,
    isSeasonalPriceFeatureOn: true
  };

  if (type !== 'byMonth') {
    return price;
  }

  const seasonalPrice = Object.keys(pricesByMonth)
    .filter(
      month =>
        pricesByMonth[month] !== 'PriceByMonth' &&
        (!monthsStaying || monthsStaying.includes(month))
    )
    .map(month => pricesByMonth[month])
    .sort((minPrice, maxPrice) => minPrice - maxPrice);

  const min = seasonalPrice[0];
  const max = seasonalPrice[seasonalPrice.length - 1];

  if (min === max) {
    price.fixedPrice = min;
    price.type = 'fixed';
    price.amount = price.fixedPrice;
  } else {
    price.seasonalPrice = { min, max };
  }
  return price;
};

export default { getMonthsFirstPayment, getRentableUnitPrice };
