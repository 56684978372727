import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import NoSSR from 'react-no-ssr';

import classNames from 'classnames';

import { isSmallerThanLaptop } from '../utils/breakpoints';
import CloseButton from '../CloseButton';
import Modal from '../Modal';
import Chip from '../Chip';

import './DropdownButton.scss';

const DropdownContainer = ({
  isOpen,
  onClose,
  isRightToLeft,
  renderDropdownContent
}) => {
  const parent = useRef();

  const dropdownClassses = classNames('dropdown-button__dropdown', {
    'dropdown-button__dropdown--right-to-left': isRightToLeft
  });

  useEffect(() => {
    const closeOnClick = e => {
      if (isOpen && parent.current && !parent.current.contains(e.target)) {
        onClose();
      }
    };

    if (isOpen && !isSmallerThanLaptop()) {
      document.addEventListener('click', closeOnClick);
    }

    if (!isOpen) {
      document.removeEventListener('click', closeOnClick);
    }

    return () => {
      document.removeEventListener('click', closeOnClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return isOpen ? (
    <div
      ref={parent}
      data-test="dropdown-button-dropdown"
      className={dropdownClassses}
    >
      {renderDropdownContent(onClose)}
    </div>
  ) : null;
};

const DropdownButton = ({
  className = '',
  dataTest = 'dropdown-button-toggle',
  isOpen,
  isSelected = false,
  onToggle,
  onClose = () => {},
  renderButtonContent,
  renderDropdownContent,
  isRightToLeft = false
}) => (
  <div className={classNames('dropdown-button', className)}>
    <Chip.Filter
      className="dropdown-button dropdown-button__chip"
      showChevron
      isSelected={isSelected}
      label={renderButtonContent()}
      isOpen={isOpen}
      onClick={onToggle}
      dataTest={dataTest}
    />
    <NoSSR>
      {isSmallerThanLaptop() ? (
        <Modal
          size="fullscreen"
          className="dropdown-button"
          isOpen={isOpen}
          onRequestClose={onClose}
        >
          <CloseButton
            dataTest="dropdown-button-close"
            className="dropdown-button__close"
            onClick={onClose}
          />
          {renderDropdownContent(onClose)}
        </Modal>
      ) : (
        <DropdownContainer
          isOpen={isOpen}
          isRightToLeft={isRightToLeft}
          onClose={onClose}
          renderDropdownContent={renderDropdownContent}
        />
      )}
    </NoSSR>
  </div>
);

DropdownButton.propTypes = {
  renderDropdownContent: PropTypes.func.isRequired,
  renderButtonContent: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool,
  isRightToLeft: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  className: PropTypes.string,
  dataTest: PropTypes.string
};

export default DropdownButton;
