import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz/client';

import {
  formatReadableDateRange,
  formatReadableDateWithRentalType
} from '../../../utils/DateRangeHandler';

import AvailabilityFilterComponent from '../../../Filters/AvailabilityFilter';

import ExposedFilter from '../ExposedFilter';

const AvailabilityFilter = ({
  moveIn,
  moveOut,
  selectedRentalTypes,
  isFilterOpen,
  onToggleFilter,
  onFiltersUpdate,
  minimumStaying,
  totalResults,
  moveOutFrom = '',
  moveOutTo = '',
  moveInFrom = '',
  moveInTo = '',
  showResults
}) => {
  const renderButtonContent = () => {
    const formattedLabel =
      moveOut || (moveOutFrom && moveOutTo)
        ? formatReadableDateRange(moveIn || moveInFrom, moveOut || moveOutTo)
        : formatReadableDateWithRentalType(
            moveIn || moveInFrom,
            selectedRentalTypes
          );

    return formattedLabel || trans('filters.label.availabilities');
  };

  return (
    <ExposedFilter
      isFilterOpen={isFilterOpen}
      onToggleFilter={onToggleFilter}
      renderButtonContent={renderButtonContent}
      renderFilterContent={() => (
        <AvailabilityFilterComponent
          from={moveIn}
          minimumStaying={minimumStaying}
          to={moveOut}
          rentalType={selectedRentalTypes}
          updateFiltersWith={onFiltersUpdate}
          moveOutFrom={moveOutFrom}
          moveOutTo={moveOutTo}
          moveInFrom={moveInFrom}
          moveInTo={moveInTo}
        />
      )}
      totalResults={totalResults}
      showResults={showResults}
      filterConfig={{
        name: 'availabilities',
        title: trans('search.exposedfilters.dates.title'),
        subtitle: trans('search.exposedfilters.dates.subtitle'),
        eventLabel: 'apply-date',
        dataTestOpen: 'date-exposed-filter-button',
        dataTestApply: 'date-exposed-filter-apply',
        isSelected:
          !!(moveIn || moveOut) ||
          !!selectedRentalTypes?.length ||
          !!(moveInFrom || moveInTo)
      }}
    />
  );
};

AvailabilityFilter.propTypes = {
  moveIn: PropTypes.string.isRequired,
  moveOut: PropTypes.string.isRequired,
  selectedRentalTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  isFilterOpen: PropTypes.bool.isRequired,
  onToggleFilter: PropTypes.func.isRequired,
  onFiltersUpdate: PropTypes.func.isRequired,
  minimumStaying: PropTypes.number.isRequired,
  totalResults: PropTypes.number.isRequired,
  moveOutFrom: PropTypes.string,
  moveOutTo: PropTypes.string,
  moveInFrom: PropTypes.string,
  moveInTo: PropTypes.string,
  showResults: PropTypes.bool.isRequired
};

export default AvailabilityFilter;
