import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz/client';
import classNames from 'classnames';

import { CircleClockIcon } from '@spotahome/ui-library';

import './BookSecurelyBannerBack.scss';

const BookSecurelyBannerBack = ({ isOneColumnView }) => {
  const backWrapperCn = classNames('home-card-banner-back__wrapper', {
    'home-card-banner-back__wrapper--multi-column-view': !isOneColumnView
  });

  return (
    <div className="home-card-banner-back__container">
      <div className={backWrapperCn}>
        <div className="home-card-banner-back__top">
          <span>
            <CircleClockIcon theme="secondary" />
          </span>
          <div className="home-card-banner-back__title">
            {trans(`homecard.banner.book-securely.title`)}
          </div>
        </div>
        <div className="home-card-banner-back__message">
          {trans(`homecard.banner.book-securely.message`)}
        </div>
      </div>
    </div>
  );
};

BookSecurelyBannerBack.propTypes = {
  isOneColumnView: PropTypes.bool
};

BookSecurelyBannerBack.defaultProps = {
  isOneColumnView: false
};

export default BookSecurelyBannerBack;
