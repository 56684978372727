import PropTypes from 'prop-types';

import getStructuredDataAttributes from '../lib/StructuredDataAttributes';

const JsonLdOrganization = ({ description = '' }) => {
  const generateJsonString = () => {
    const properties = getStructuredDataAttributes();

    return {
      '@context': 'https://schema.org/',
      '@type': 'RealEstateAgent',
      name: properties.name,
      description,
      url: properties.url,
      logo: properties.logo,
      image: '',
      email: '',
      telephone: '',
      address: '',
      priceRange: properties.priceRange,
      sameAs: properties.sameAs,
      aggregateRating: properties.aggregateRating
    };
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(generateJsonString())
      }}
    />
  );
};

JsonLdOrganization.propTypes = {
  description: PropTypes.string
};

export default JsonLdOrganization;
