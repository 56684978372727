import { useSoyuzLocales, useSoyuzClientConfig } from '@spotahome/soyuz/client';

import { useFiltersContext } from '@spotahome/ui-library/src/tenant/context/FiltersContext';

import { useTenantTypeContext } from '@spotahome/ui-library';

import Search from './Search';

const SearchContainer = props => {
  const clientConfig = useSoyuzClientConfig();
  const { current } = useSoyuzLocales();
  const { filters, handleUpdateFilters, handleUpdatePage, handleUpdateMap } =
    useFiltersContext();
  const { tenantType } = useTenantTypeContext();
  return (
    <Search
      {...props}
      clientConfig={clientConfig}
      currentLocale={current}
      filters={filters}
      handleUpdateFilters={handleUpdateFilters}
      handleUpdatePage={handleUpdatePage}
      handleUpdateMap={handleUpdateMap}
      tenantType={tenantType}
    />
  );
};

export default SearchContainer;
