import PropTypes from 'prop-types';

import Currency from '@spotahome/soyuz-currency';

import FormInput from '../FormInput';

const PriceInput = ({
  currencyIsoCode,
  hasError,
  handleOnChange,
  value,
  placeholder,
  title,
  name,
  id
}) => {
  const isCurrencySymbolOnLeft =
    Currency.isCurrencySymbolOnLeft(currencyIsoCode);

  const currencySymbol = Currency.getCurrencySymbolForISOCode(currencyIsoCode);

  return (
    <FormInput
      title={title}
      name={name}
      id={id}
      type="text"
      pattern="[0-9]*"
      inputmode="numeric"
      onChange={handleOnChange}
      placeholder={placeholder}
      value={value}
      hasError={hasError}
      {...(isCurrencySymbolOnLeft
        ? {
            renderPrefix: () => currencySymbol
          }
        : {
            renderSuffix: () => currencySymbol
          })}
    />
  );
};

PriceInput.propTypes = {
  currencyIsoCode: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  handleOnChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string
};

PriceInput.defaultProps = {
  hasError: false,
  handleOnChange: () => {},
  value: '',
  placeholder: '',
  title: '',
  name: '',
  id: undefined
};
export default PriceInput;
