import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz/client';
import { DSIcon } from '@spotahome/ui-library/base';

import './IconPreFilterItem.scss';

const IconPreFilterItem = ({ id, onChange, checked, iconName }) => {
  return (
    <Fragment>
      <input
        className="icon-pre-filter-item__checkbox"
        type="checkbox"
        name={id}
        id={id}
        onChange={onChange}
        checked={checked}
      />
      <label className="icon-pre-filter-item__label" htmlFor={id}>
        <DSIcon name={iconName} className="icon-pre-filter-item__icon" />
        <span className="icon-pre-filter-item__label-text">
          {trans(`search.prefilters.${id}.title.short`)}
        </span>
      </label>
    </Fragment>
  );
};

IconPreFilterItem.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  iconName: PropTypes.string.isRequired
};

export default IconPreFilterItem;
