import PropTypes from 'prop-types';

import styles from './HomecardLabelList.module.scss';

const HomecardLabelList = ({ renderLabels }) => (
  <div className={styles['home-card-label-list']}>{renderLabels()}</div>
);

HomecardLabelList.propTypes = {
  renderLabels: PropTypes.func.isRequired
};

export default HomecardLabelList;
