import { trans } from '@spotahome/soyuz/client';

import {
  Button,
  Modal,
  useMediatorModalHandle,
  ModalBody,
  ModalFooter
} from '@spotahome/ui-library';

import { OPEN_OUT_OF_DATE_ALERT_MODAL } from '../../channels';

import './AlertInPastModal.scss';

const AlertInPastIcon = () => (
  <svg
    width="302"
    height="223"
    viewBox="0 0 302 223"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M301.449 130.7H210.479V222.78H301.449V130.7Z" fill="#FFCCEF" />
    <path d="M210.479 130.7H164.999V222.78H210.479V130.7Z" fill="#0A0777" />
    <path d="M267.779 152.16H245.169V222.78H267.779V152.16Z" fill="white" />
    <path
      d="M250.715 147.446L219.75 152.945L223.249 172.647L254.214 167.148L250.715 147.446Z"
      fill="#FB6E44"
    />
    <path d="M194.929 130.7V38.48H285.899V130.7" fill="white" />
    <path
      d="M194.929 130.7V38.48H285.899V130.7"
      stroke="#0A0777"
      strokeWidth="0.75"
      strokeMiterlimit="10"
    />
    <path d="M194.929 38.1001H149.449V130.7H194.929V38.1001Z" fill="#0A0777" />
    <path d="M249.499 38.03H226.889V109.9H249.499V38.03Z" fill="#FFCCEF" />
    <path d="M179.579 59.0898H165.029V130.7H179.579V59.0898Z" fill="white" />
    <path
      d="M56.0645 71.0454V17.0793"
      stroke="#0A0777"
      strokeWidth="0.787059"
      strokeMiterlimit="10"
    />
    <path
      d="M89.3301 71.0454V17.0793"
      stroke="#0A0777"
      strokeWidth="0.787059"
      strokeMiterlimit="10"
    />
    <path
      d="M94.4457 18.7058H84.2664V13.0127C84.2664 11.4386 82.9808 10.1793 81.433 10.1793H64.0127C62.4386 10.1793 61.1793 11.4648 61.1793 13.0127V18.7058H51V13.0127C51 5.82424 56.8505 0 64.0127 0H81.433C88.6214 0 94.4457 5.85047 94.4457 13.0127V18.7058Z"
      fill="#0A0777"
    />
    <path
      d="M91.482 70.4944H43.7862C32.4266 70.4944 23.2178 79.7032 23.2178 91.0629V187.11C23.2178 198.47 32.4266 207.679 43.7862 207.679H91.482C102.842 207.679 112.051 198.47 112.051 187.11V91.0629C112.051 79.7032 102.842 70.4944 91.482 70.4944Z"
      fill="#FFCCEF"
    />
    <path
      d="M90.6168 223C94.8477 223 98.2775 219.57 98.2775 215.339C98.2775 211.109 94.8477 207.679 90.6168 207.679C86.3859 207.679 82.9561 211.109 82.9561 215.339C82.9561 219.57 86.3859 223 90.6168 223Z"
      fill="#0A0777"
    />
    <path
      d="M31.7183 223C35.9492 223 39.379 219.57 39.379 215.339C39.379 211.109 35.9492 207.679 31.7183 207.679C27.4874 207.679 24.0576 211.109 24.0576 215.339C24.0576 219.57 27.4874 223 31.7183 223Z"
      fill="#0A0777"
    />
    <path
      d="M68.2643 70.4944H20.5685C9.20881 70.4944 0 79.7032 0 91.0629V187.11C0 198.47 9.20881 207.679 20.5685 207.679H68.2643C79.6239 207.679 88.8327 198.47 88.8327 187.11V91.0629C88.8327 79.7032 79.6239 70.4944 68.2643 70.4944Z"
      fill="#F4EFED"
    />
    <path
      d="M0 98.0674H112.051"
      stroke="#0A0777"
      strokeWidth="0.787059"
      strokeMiterlimit="10"
    />
    <path
      d="M0 125.274H112.051"
      stroke="#0A0777"
      strokeWidth="0.787059"
      strokeMiterlimit="10"
    />
    <path
      d="M0 154.238H112.051"
      stroke="#0A0777"
      strokeWidth="0.787059"
      strokeMiterlimit="10"
    />
    <path
      d="M0 179.633H112.051"
      stroke="#0A0777"
      strokeWidth="0.787059"
      strokeMiterlimit="10"
    />
    <path
      d="M26.0786 109.113C32.8886 109.113 38.4092 103.592 38.4092 96.782C38.4092 89.972 32.8886 84.4514 26.0786 84.4514C19.2686 84.4514 13.748 89.972 13.748 96.782C13.748 103.592 19.2686 109.113 26.0786 109.113Z"
      fill="#FB6E44"
    />
    <path
      d="M77.8768 147.428L42.084 131.976L34.2437 150.138L70.0365 165.59L77.8768 147.428Z"
      fill="white"
    />
  </svg>
);

const AlertInPastModal = () => {
  const { isModalOpen, handleModalClose } = useMediatorModalHandle({
    channel: OPEN_OUT_OF_DATE_ALERT_MODAL
  });

  return isModalOpen ? (
    <Modal
      isOpen
      onRequestClose={handleModalClose}
      ariaHideApp={false}
      className="alert-in-past-modal"
    >
      <ModalBody className="alert-in-past-modal__body">
        <AlertInPastIcon />

        <p
          dangerouslySetInnerHTML={{
            __html: trans('alerts.alert.out_of_date.modal')
          }}
        />
      </ModalBody>
      <ModalFooter className="alert-in-past-modal__footer">
        <Button onClick={handleModalClose}>
          {trans('listing.pricing.suggest.success.cta.lower')}
        </Button>
      </ModalFooter>
    </Modal>
  ) : null;
};

export default AlertInPastModal;
