import gql from 'graphql-tag';

import { Mediator } from '@spotahome/soyuz-mediator';

import { COMMON_LOGIN_MODAL } from '../../channels';

const MUTATE_ADD_FAVORITES = gql`
  mutation AddFavorite($id: ID!) {
    addFavorite(id: $id) {
      id
    }
  }
`;

const MUTATE_REMOVE_FAVORITES = gql`
  mutation RemoveFavorite($id: ID!) {
    removeFavorite(id: $id) {
      id
    }
  }
`;

const triggerTokenError = err => {
  if (err.message === 'GraphQL error: Token expired') {
    Mediator.publish(COMMON_LOGIN_MODAL);
  }
  throw err;
};

export const addFavorite = client => id =>
  client
    .mutate({
      mutation: MUTATE_ADD_FAVORITES,
      variables: { id }
    })
    .catch(triggerTokenError);

export const removeFavorite = client => id =>
  client
    .mutate({
      mutation: MUTATE_REMOVE_FAVORITES,
      variables: { id }
    })
    .catch(triggerTokenError);

export default {
  addFavorite,
  removeFavorite
};
