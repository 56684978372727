import { forwardRef, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Carousel } from 'react-responsive-carousel';

import { trans } from '@spotahome/soyuz-trans/client';

import { IMAGE_NAVIGATION, RATIOS } from './utils/constants';

import GalleryArrow from './GalleryArrow';
import SliderTrackImage from './SliderTrackImage';

import styles from './ImageCarousel.module.scss';

/**
 * @param {Number} preloadRange -- Number of items to preload on each side of the carousel
 * @param {Number} nItems -- Item collection length
 * @param {Number} currentIndex -- Current slider position
 * @returns {Array[Number]} itemsToPreload -- List of indexes to be preloaded
 *
 * For example, with params as (3, 10, 1) will generate the following arrays:
 * 1. Initial array with index on the left and right: [-1, -2, -3, 1, 2, 3]
 * 2. Map that first array to get the actual index in the collection: [0, 10, 9, 2, 3, 4]
 */
const getItemsToPreload = (preloadRange, nItems, currentIndex) => {
  const nextItems = new Array(preloadRange).fill(null).map((_, i) => i + 1);
  const prevItems = new Array(preloadRange)
    .fill(null)
    .map((_, i) => -1 * (i + 1));

  return [...prevItems, ...nextItems].map(
    preloadIndex => (preloadIndex + currentIndex + nItems) % nItems
  );
};

const ImageCarousel = ({
  items,
  className = '',
  showCaption = false,
  showCounter = false,
  keyboardHandleEnabled = false,
  onImageChange = () => {},
  onImageClick = () => {},
  children = null,
  isInfinite = true,
  ratio = RATIOS.PANORAMIC,
  forwardedRef = () => {},
  preloadRange = 0,
  keepHeightWhileLoading = false,
  lazyLoad = false,
  withBackgroundChevrons = false,
  isShowStatus = false,
  autoFocus = false,
  chevronStyle = 'small'
}) => {
  const [isLoaded, setIsLoaded] = useState(!lazyLoad);
  const [currentIndex, setCurrentIndex] = useState(0);

  const lazyAction = useRef(null);

  useEffect(() => {
    if (isLoaded && lazyAction.current) {
      lazyAction.current();
      lazyAction.current = null;
    }
  }, [currentIndex, isLoaded, items.length]);

  const handleSwipeStart = () => {
    if (!isLoaded) {
      setIsLoaded(true);
    }
  };

  const handlePreviousItem = goToPrevItem => e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!isLoaded) {
      lazyAction.current = goToPrevItem;
      setIsLoaded(true);
    } else {
      goToPrevItem();
    }
  };

  const handleNextItem = goToNextItem => e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!isLoaded) {
      lazyAction.current = goToNextItem;
      setIsLoaded(true);
    } else {
      goToNextItem();
    }
  };

  const handleItemChange = newIndex => {
    let action = '';

    if (currentIndex === 0) {
      // First item
      action =
        newIndex === items.length - 1
          ? IMAGE_NAVIGATION.prev
          : IMAGE_NAVIGATION.next;
    } else {
      action =
        newIndex > currentIndex ? IMAGE_NAVIGATION.next : IMAGE_NAVIGATION.prev;
    }

    setCurrentIndex(newIndex);

    onImageChange(action, {
      currentItem: items[newIndex],
      index: newIndex
    });
  };

  const preloadIndexes = getItemsToPreload(
    Math.min(preloadRange, items.length),
    items.length,
    currentIndex
  );

  const galleryClassName = classNames(className, styles['image-carousel']);
  const wrapClassName = classNames(styles['image-carousel__wrap'], {
    [styles[`image-carousel__wrap--keep-height-${ratio.replace(':', '-')}`]]:
      keepHeightWhileLoading
  });
  const constantHeightWrapClassName = keepHeightWhileLoading
    ? styles['image-carousel__keep-height-wrapper']
    : '';

  return (
    <div className={galleryClassName}>
      <div className={wrapClassName}>
        <div className={constantHeightWrapClassName}>
          <Carousel
            autoFocus={autoFocus}
            preventMovementUntilSwipeScrollTolerance
            swipeScrollTolerance={100}
            infiniteLoop={isInfinite}
            useKeyboardArrows={keyboardHandleEnabled}
            dynamicHeight
            showArrows={false}
            showIndicators={false}
            showThumbs={false}
            showStatus={isShowStatus}
            statusFormatter={current => `${current}/${items.length}`}
            onSwipeStart={handleSwipeStart}
            renderArrowPrev={onClick =>
              items.length > 1 ? (
                <GalleryArrow
                  style={chevronStyle}
                  navRole="prev"
                  onClick={handlePreviousItem(onClick)}
                  dataTest="gallery-arrow-prev"
                  withBackground={withBackgroundChevrons}
                />
              ) : null
            }
            renderArrowNext={onClick =>
              items.length > 1 ? (
                <GalleryArrow
                  style={chevronStyle}
                  navRole="next"
                  onClick={handleNextItem(onClick)}
                  dataTest="gallery-arrow-next"
                  withBackground={withBackgroundChevrons}
                />
              ) : null
            }
            onChange={handleItemChange}
            onClickItem={onImageClick}
          >
            <SliderTrackImage
              key={items[0].id || items[0].src}
              shouldLoad
              noPlaceholder={keepHeightWhileLoading}
              showCaption={showCaption}
              item={items[0]}
              ratio={ratio}
              isCurrent={currentIndex === 0}
            />
            {isLoaded
              ? items
                  .slice(1)
                  .map((currentItem, i) => (
                    <SliderTrackImage
                      key={currentItem.id || currentItem.src}
                      shouldLoad={
                        currentIndex - 1 === i || preloadIndexes.includes(i + 1)
                      }
                      noPlaceholder={keepHeightWhileLoading && i === 0}
                      showCaption={showCaption}
                      item={currentItem}
                      ratio={ratio}
                      autoPlay={false}
                      isCurrent={currentIndex - 1 === i}
                    />
                  ))
              : null}
          </Carousel>
        </div>
      </div>

      {showCounter && (
        <div className={styles['image-carousel__counter']} ref={forwardedRef}>
          {currentIndex + 1}/{items.length} {trans('slider.counter.photos')}
        </div>
      )}
      {children}
    </div>
  );
};

ImageCarousel.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      renderItem: PropTypes.func,
      src: PropTypes.string.isRequired,
      caption: PropTypes.string.isRequired
    })
  ).isRequired,
  showCaption: PropTypes.bool,
  showCounter: PropTypes.bool,
  keyboardHandleEnabled: PropTypes.bool,
  onImageChange: PropTypes.func,
  onImageClick: PropTypes.func,
  children: PropTypes.node,
  chevronStyle: PropTypes.oneOf(['small', 'big']),
  isInfinite: PropTypes.bool,
  ratio: PropTypes.oneOf(Object.values(RATIOS)),
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.object })
  ]),
  preloadRange: PropTypes.number,
  keepHeightWhileLoading: PropTypes.bool,
  lazyLoad: PropTypes.bool,
  withBackgroundChevrons: PropTypes.bool,
  isShowStatus: PropTypes.bool,
  autoFocus: PropTypes.bool
};

const ImageCarouselWithRef = forwardRef((props, ref) => (
  <ImageCarousel {...props} forwardedRef={ref} />
));

ImageCarouselWithRef.RATIOS = RATIOS;

export default ImageCarouselWithRef;
