import { forwardRef } from 'react';
import PropTypes from 'prop-types';

const PlusLabelIcon = forwardRef((props, ref) => (
  <svg
    {...props}
    ref={ref}
    width={`${props.size}px`}
    height={`${props.size}px`}
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.1508 16.8872C23.1508 19.0232 21.4192 20.7548 19.2832 20.7548C17.1471 20.7548 15.4155 19.0232 15.4155 16.8872C15.4155 14.7511 17.1471 13.0195 19.2832 13.0195C21.4192 13.0195 23.1508 14.7511 23.1508 16.8872Z"
      stroke="#0D0D0D"
      strokeWidth="1.5"
    />
    <path
      d="M16.9942 6.72799C16.9942 8.86403 15.2626 10.5956 13.1266 10.5956C10.9905 10.5956 9.25891 8.86403 9.25891 6.72799C9.25891 4.59196 10.9905 2.86035 13.1266 2.86035C15.2626 2.86035 16.9942 4.59196 16.9942 6.72799Z"
      stroke="#0D0D0D"
      strokeWidth="1.5"
    />
    <path
      d="M10.837 16.8862C10.837 19.0222 9.10539 20.7538 6.96934 20.7538C4.83329 20.7538 3.10168 19.0222 3.10168 16.8862C3.10168 14.7502 4.83329 13.0186 6.96934 13.0186C9.10539 13.0186 10.837 14.7502 10.837 16.8862Z"
      stroke="#0D0D0D"
      strokeWidth="1.5"
    />
  </svg>
));

PlusLabelIcon.defaultProps = {
  size: 40,
  className: ''
};

PlusLabelIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string
};

export default PlusLabelIcon;
