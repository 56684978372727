import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useSoyuzLocales } from '@spotahome/soyuz/client';

import { useFooterLinks } from '../../context/ExternalLink';
import { useLocaleChange } from '../../../hooks/useLocaleChange';
import LanguageSwitcher from '../LanguageSwitcher';

import FooterSimplifiedSection from './components/FooterSimplifiedSection';
import FooterSimplifiedSeparator from './components/FooterSimplifiedSeparator';

import SpotahomeFooter from './SpotahomeFooter';
import LegalFooter from './LegalFooter';
import SupportFooter from './SupportFooter';
import SocialFooter from './SocialFooter';
import AppFooter from './AppFooter';

import CopyrightFooter from './CopyrightFooter';
import FooterSimplifiedBlock from './components/FooterSimplifiedBlock';

import styles from './FooterSimplified.module.scss';

const FooterSimplified = memo(({ locales, onLocaleChange }) => {
  const externalLinks = useFooterLinks();
  const handleOnLocaleChange = useLocaleChange();
  const { current, ui: uiLocales } = useSoyuzLocales();
  const localesToUse = locales || Object.keys(uiLocales);

  return (
    <footer>
      <section className={classNames(styles.footer, styles['footer--beige'])}>
        <FooterSimplifiedSection>
          <SpotahomeFooter {...externalLinks} />
          <FooterSimplifiedBlock>
            <SupportFooter {...externalLinks} />
          </FooterSimplifiedBlock>
          <FooterSimplifiedBlock>
            <LegalFooter {...externalLinks} />
          </FooterSimplifiedBlock>
          <FooterSimplifiedBlock className={styles.footer__external}>
            <AppFooter />
          </FooterSimplifiedBlock>
          <FooterSimplifiedBlock className={styles.footer__app}>
            <AppFooter />
          </FooterSimplifiedBlock>
        </FooterSimplifiedSection>
      </section>
      <section
        className={classNames(
          styles.footer__disclaimer,
          styles['footer--disclaimer__beige']
        )}
      >
        <FooterSimplifiedSeparator />
        <FooterSimplifiedSection>
          <CopyrightFooter />
          <div className={styles.footer__language}>
            <LanguageSwitcher
              theme="dark"
              onChange={onLocaleChange || handleOnLocaleChange}
              locales={localesToUse}
              current={current}
            />
            <SocialFooter />
          </div>
        </FooterSimplifiedSection>
      </section>
    </footer>
  );
});

FooterSimplified.propTypes = {
  locales: PropTypes.arrayOf(PropTypes.string),
  onLocaleChange: PropTypes.func
};

FooterSimplified.defaultProps = {
  locales: null,
  onLocaleChange: null
};

export default FooterSimplified;
