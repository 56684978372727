import { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FavouriteIcon from '../icons/FavouriteIcon';
import FavoriteIconWithBorder from '../icons/FavoriteIconWithBorder';

import { useDeviceContext } from '../../context/DeviceContext';

import styles from './Favourite.module.scss';

const Favourite = ({
  onClick = () => {},
  selected,
  className = '',
  iconWithBorder = false
}) => {
  const [hovered, setHovered] = useState(false);
  const button = useRef(null);
  const { deviceType } = useDeviceContext();

  const handleClick = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    },
    [onClick]
  );

  const handleAddHover = () => {
    setHovered(true);
  };
  const handleRemoveHover = () => {
    setHovered(false);
  };

  useEffect(() => {
    const buttonRef = button.current;
    buttonRef.addEventListener('click', handleClick);

    return () => {
      buttonRef.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  const onMouseEventProps =
    deviceType === 'smartphone'
      ? {}
      : { onMouseEnter: handleAddHover, onMouseLeave: handleRemoveHover };

  return (
    <button
      ref={button}
      type="button"
      className={classNames(styles.favourite, className)}
      {...onMouseEventProps}
      data-test="favorite-icon"
      data-testid="favorite-icon"
    >
      {iconWithBorder ? (
        <FavoriteIconWithBorder active={hovered || selected} />
      ) : (
        <FavouriteIcon theme={selected || hovered ? 'favourite' : 'light'} />
      )}
    </button>
  );
};

Favourite.propTypes = {
  selected: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  iconWithBorder: PropTypes.bool
};

export default Favourite;
