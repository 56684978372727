/* eslint-disable import/no-named-as-default-member */
import isBefore from 'date-fns/isBefore';
import startOfDay from 'date-fns/startOfDay';
import Tracking from '@spotahome/soyuz-tracking';
import { Mediator } from '@spotahome/soyuz-mediator';
import { clientErrorTracker } from '@spotahome/soyuz/client';
import SoyuzAnalytics from '@spotahome/soyuz-analytics';

import {
  getItem,
  removeItem
} from '@spotahome/ui-library/src/utils/localStorage';

import getGqlClient from '../graphql/client';
import { addAlert } from '../graphql/mutations/alerts';
import {
  OPEN_APPLIED_FILTERS_MODAL,
  OPEN_OUT_OF_DATE_ALERT_MODAL
} from '../channels';

export const LOCAL_STORAGE_ALERT_DATA_KEY = 'alertData';
export const QUICK_ALERT_STORAGE_DATA = 'quick-alert-data';

const dateIsInThePast = date => {
  const today = startOfDay(new Date());
  return isBefore(new Date(date), today);
};

export const createAlert = async alertData => {
  const hasDates = alertData?.moveIn || alertData?.moveOut;
  if (
    (hasDates && dateIsInThePast(alertData?.moveIn)) ||
    dateIsInThePast(alertData?.moveOut)
  ) {
    Mediator.publish(OPEN_OUT_OF_DATE_ALERT_MODAL);
    removeItem(QUICK_ALERT_STORAGE_DATA);
    return;
  }

  try {
    await addAlert(getGqlClient())(alertData);
    Tracking.ga.sendEvent(
      'My alerts modal',
      'alert creation success',
      'alert creation success'
    );
    const { cityId, ...filters } = alertData;
    SoyuzAnalytics.sendGA4Event('search_saved', { city: cityId, filters });
    Mediator.publish(OPEN_APPLIED_FILTERS_MODAL);
    removeItem(QUICK_ALERT_STORAGE_DATA);
  } catch (error) {
    clientErrorTracker.error({ error, source: 'CreateAlert' });
  }
};

const parseBudget = (budget = '') => {
  const splitBudget = budget.split('-');
  return {
    minPrice: parseInt(splitBudget[0], 10),
    maxPrice: parseInt(splitBudget[1], 10)
  };
};

export const getAlertFromFilters = (cityId, filters) => ({
  cityId,
  moveIn: filters['move-in'],
  moveOut: filters['move-out'],
  moveInFrom: filters.moveInFrom,
  moveInTo: filters.moveInTo,
  moveOutFrom: filters.moveOutFrom,
  moveOutTo: filters.moveOutTo,
  propertyTypes: filters['type[]'],
  cityAreaIds: filters['areaId[]'],
  bathrooms: filters['bathrooms[]'],
  bed: filters.bed,
  features: filters['features[]'],
  instantBooking: filters.instantBooking,
  noDeposit: filters.noDeposit,
  verified: filters.verified,
  topology: filters['topology[]'],
  rentalType: filters['rentalType[]'],
  ...parseBudget(filters.budget)
});

export const getAlertDataFromStorage = storageKey => {
  // eslint-disable-next-line import/no-named-as-default-member
  const data = getItem(storageKey);
  try {
    return JSON.parse(data);
  } catch (e) {
    return null;
  }
};

export default {
  createAlert,
  parseBudget,
  getAlertFromFilters,
  getAlertDataFromStorage
};
