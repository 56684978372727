import PropTypes from 'prop-types';

import { useSoyuzLocales } from '@spotahome/soyuz/client';
import { HeaderContainer as HeaderContainerComponent } from '@spotahome/ui-library';
import { useLocaleChange } from '@spotahome/ui-library/src/hooks/useLocaleChange';

import './HeaderContainer.scss';

const HeaderContainer = ({
  renderExtraContent = () => {},
  renderLeftContent = () => {}
}) => {
  const { current: currentLocale } = useSoyuzLocales();
  const handleOnLocaleChange = useLocaleChange();

  return (
    <HeaderContainerComponent
      className="header-menu-wrapper"
      color="light"
      locales={{
        current: currentLocale,
        onChange: handleOnLocaleChange
      }}
      showLogin
      links={{ changeCity: true }}
      renderExtraContent={renderExtraContent}
      renderLeftContent={renderLeftContent}
      isContactUsEnabled
    />
  );
};

HeaderContainer.propTypes = {
  renderExtraContent: PropTypes.func,
  renderLeftContent: PropTypes.func
};

export default HeaderContainer;
