import { forwardRef } from 'react';
import PropTypes from 'prop-types';

const LongTermLabelIcon = forwardRef((props, ref) => (
  <svg
    {...props}
    ref={ref}
    width={`${props.size}px`}
    height={`${props.size}px`}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.75 21.25V2.75H21.25V21.25H0.75Z"
      stroke="#0D0D0D"
      strokeWidth="1.5"
    />
    <path d="M6.5 0V5" stroke="#0D0D0D" strokeWidth="1.5" />
    <path d="M15.5 0V5" stroke="#0D0D0D" strokeWidth="1.5" />
    <path d="M0 8.5L22 8.5" stroke="#0D0D0D" strokeWidth="1.5" />
  </svg>
));

LongTermLabelIcon.defaultProps = {
  size: 40,
  className: ''
};

LongTermLabelIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string
};

export default LongTermLabelIcon;
