import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Metrics from '@spotahome/soyuz-tracking';

import SoyuzAnalytics from '@spotahome/soyuz-analytics';

import { requestUnitPrices } from '../../api';

import FormSlider from '../../../FormSlider';

import { adaptFiltersFromUrl, getBudgetValue } from '../../../utils/filters';

const FILTER_NAME = 'budget';

const PriceSliderFilter = ({
  budget = '',
  maxPriceInCity,
  updateFiltersWith,
  currencyIsoCode,
  cityId,
  filters,
  sortingAlgorithm
}) => {
  const [unitPrices, setUnitPrices] = useState([]);

  const receivedBudget = budget;
  const splittedBudget = receivedBudget && receivedBudget.split('-');
  const budgetMin = parseInt(splittedBudget[0], 10) || 0;
  const budgetMax = parseInt(splittedBudget[1], 10) || '';

  useEffect(() => {
    const updateUnitPrices = async () => {
      const newUnitPrices = await requestUnitPrices({
        cityId,
        adaptedFilters: adaptFiltersFromUrl(filters, sortingAlgorithm),
        maxPriceInCity
      });
      setUnitPrices(newUnitPrices);
    };
    updateUnitPrices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const sendPriceChangeEvent = newBudget =>
    newBudget.includes('-')
      ? Metrics.ga.sendEvent(
          'Search',
          'PriceSliderFilter',
          'range-price-update'
        ) &&
        SoyuzAnalytics.sendGA4Event('filter', {
          context: 'budget',
          section: 'price-slider',
          action: 'price-range-update'
        })
      : Metrics.ga.sendEvent(
          'Search',
          'PriceSliderFilter',
          'min-price-update'
        ) &&
        SoyuzAnalytics.sendGA4Event('filter', {
          context: 'budget',
          section: 'price-slider',
          action: 'min-price-update'
        });

  const updateBudget = ({ minPrice, maxPrice }) => {
    const budgetValue = getBudgetValue({
      minBudget: Number.isNaN(parseInt(minPrice, 10)) ? budgetMin : minPrice,
      maxBudget: Number.isNaN(parseInt(maxPrice, 10)) ? budgetMax : maxPrice,
      maxPrice: maxPriceInCity
    });

    sendPriceChangeEvent(budgetValue);
    updateFiltersWith({ [FILTER_NAME]: budgetValue });
  };

  const handleOnSliderChange = event =>
    updateBudget({
      minPrice: event.sliderFrom,
      maxPrice: event.sliderTo
    });

  return (
    <FormSlider
      histogramMaxHeight={60}
      onChange={handleOnSliderChange}
      sliderFrom={budgetMin}
      sliderTo={budgetMax || maxPriceInCity}
      currencyIsoCode={currencyIsoCode}
      unitPrices={unitPrices}
      maxPrice={maxPriceInCity}
      trackingPrefix="ga-search-form-price-slider"
    />
  );
};

PriceSliderFilter.propTypes = {
  budget: PropTypes.string,
  updateFiltersWith: PropTypes.func.isRequired,
  currencyIsoCode: PropTypes.string.isRequired,
  maxPriceInCity: PropTypes.number.isRequired,
  filters: PropTypes.shape({}).isRequired,
  sortingAlgorithm: PropTypes.string.isRequired,
  cityId: PropTypes.string.isRequired
};

export default PriceSliderFilter;
