import Metrics from '@spotahome/soyuz-tracking';
import gql from 'graphql-tag';

import getGqlClient from '../client';

const SEND_TENANT_ACTION_EVENT = gql`
  mutation sendTenantActionEvent($input: TenantActionEventInput!) {
    sendTenantActionEvent(input: $input)
  }
`;

export const sendTenantActionEvent = client => input =>
  client.mutate({
    mutation: SEND_TENANT_ACTION_EVENT,
    variables: { input }
  });

export const configureMetricsActions = () =>
  Metrics.actions.configureClient(async ({ actionId, payload }) => {
    try {
      await sendTenantActionEvent(getGqlClient())({
        actionId,
        payload
      });
    } catch (e) {
      console.log('error sending sendTenantActionEvent:', e);
    }
  });
