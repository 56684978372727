import PropTypes from 'prop-types';

import BaseChip from '../BaseChip';

const Tag = ({
  size = 'medium',
  isDarkMode = false,
  isOverlay = false,
  colorSchema,
  ...props
}) => (
  <BaseChip
    size={size} // TODO: size prop is not yet implemented in BaseChip component
    colorSchema={BaseChip.SCHEMAS[colorSchema] || BaseChip.SCHEMAS.tag}
    isDarkMode={isDarkMode}
    isOverlay={isOverlay}
    {...props}
  />
);

export default Tag;

Tag.propTypes = {
  ...BaseChip.propTypes,
  isDarkMode: PropTypes.bool,
  isOverlay: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium'])
};
