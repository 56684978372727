import PropTypes from 'prop-types';
import moment from 'moment';

import { trans } from '@spotahome/soyuz-trans/client';

import Price from '../../common/Price';

import styles from './HorizontalHomecardContent.module.scss';

const DAY_FORMAT = 'D';
const LISTING_TYPES = ['apartment', 'studio', 'room_shared', 'residence'];
const DEFAULT_LISTING_TYPE = 'room_shared';
const TRANS_DELIMITER = '_';

const getTranslatedMonth = month => {
  const translatedMonths = trans('pikaday.label.months').split(TRANS_DELIMITER);

  return translatedMonths[month];
};

const parseAvailableFrom = availableFrom => {
  const date = moment(availableFrom);
  const month = date.month();
  const translatedMonth = getTranslatedMonth(month);
  const year = date.year();

  return {
    day: date.format(DAY_FORMAT),
    month: translatedMonth,
    year
  };
};

const HorizontalHomecardContent = ({
  availableFrom,
  price = {},
  title,
  type = DEFAULT_LISTING_TYPE,
  showPrice = true,
  showAvailableFromYear = false,
  onClick
}) => {
  const { day, month, year } = parseAvailableFrom(availableFrom);

  const availableFromDatesTranslated = {
    __html: trans(`homecard.available-from`, {
      ordinalDate: showAvailableFromYear
        ? `${day} ${month} ${year}`
        : `${day} ${month}`
    })
  };

  return (
    <div
      className={styles['horizontal-homecard-content']}
      onClick={onClick}
      role="button"
      tabIndex={-1}
    >
      <div className={styles['horizontal-homecard-content-header']}>
        <div className={styles['horizontal-homecard-content-type']}>
          {trans(`homecard.type.${type}.small`)}
        </div>
        <div
          className={styles['horizontal-homecard-content__available-from']}
          dangerouslySetInnerHTML={availableFromDatesTranslated}
        />
      </div>
      <div className={styles['horizontal-homecard-content-body']}>{title}</div>
      {showPrice ? (
        <div className={styles['horizontal-homecard-content-footer']}>
          <Price
            {...price}
            className={styles['horizontal-homecard-content-footer__price']}
          />
        </div>
      ) : (
        <span
          className={
            styles['horizontal-homecard-content-footer__price--hidden']
          }
        >
          {trans('search.hide-prices-until-dates')}
        </span>
      )}
    </div>
  );
};

HorizontalHomecardContent.propTypes = {
  availableFrom: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]).isRequired,
  price: PropTypes.shape(Price.propTypes),
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(LISTING_TYPES),
  showPrice: PropTypes.bool,
  showAvailableFromYear: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

export default HorizontalHomecardContent;
