import PropTypes from 'prop-types';

const SquareListIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    stroke={color}
  >
    <path
      d="M0 0H6.5V6.5H0zM9.75 0H16.25V6.5H9.75zM9.75 9.75H16.25V16.25H9.75zM0 9.75H6.5V16.25H0z"
      transform="translate(-294 -21) translate(280.737 8) translate(4.106 3) translate(10.032 10.5)"
    />
  </svg>
);
SquareListIcon.propTypes = {
  color: PropTypes.string
};

SquareListIcon.defaultProps = {
  color: '#003762'
};
export default SquareListIcon;
