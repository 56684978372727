const HomecardMapPlaceholder = ({ ...props }) => (
  <div className="homecard-map__placeholder">
    <svg
      {...props}
      width="335px"
      viewBox="0 0 335 100"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Mobile-Map---MVP"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <rect fill="#FFFFFF" x="0" y="0" width="327" height="100" rx="4" />
        <rect
          id="Rectangle"
          fill="#E3E3E3"
          x="118"
          y="14"
          width="77"
          height="12"
        />
        <rect
          id="Rectangle"
          fill="#E3E3E3"
          x="118"
          y="34"
          width="157"
          height="12"
        />
        <rect
          id="Rectangle"
          fill="#E3E3E3"
          x="118"
          y="54"
          width="201"
          height="12"
        />
        <path
          d="M4,0 L110,0 L110,100 L4,100 C1.790861,100 2.705415e-16,98.209139 0,96 L0,4 C-2.705415e-16,1.790861 1.790861,4.05812251e-16 4,0 Z"
          id="Rectangle"
          fill="#E3E3E3"
        />
        <rect
          id="Rectangle"
          fill="#CBCBCB"
          x="242"
          y="74"
          width="77"
          height="12"
        />
      </g>
    </svg>
  </div>
);

export default HomecardMapPlaceholder;
