import PropTypes from 'prop-types';

import themes from '../utils/iconColorThemes';

const ChevronDown = ({ theme = 'base' }) => (
  <svg
    width="8"
    height="6"
    viewBox="0 0 8 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="chevron-down-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1.72571L4 5.28127L-4.42105e-08 1.72571L0.899038 0.714294L4 3.47071L7.10096 0.714294L8 1.72571Z"
      fill={themes[theme]}
    />
  </svg>
);

ChevronDown.propTypes = {
  theme: PropTypes.oneOf(Object.keys(themes))
};

export default ChevronDown;
