import PropTypes from 'prop-types';

import HomeCardFlipBanner from '../../components/HomeCardFlipBanner';

import BookSecurelyBannerFront from './BookSecurelyBannerFront';
import BookSecurelyBannerBack from './BookSecurelyBannerBack';

const BookSecurelyBanner = ({ isOneColumnView }) => (
  <HomeCardFlipBanner
    Front={BookSecurelyBannerFront}
    Back={BookSecurelyBannerBack}
    className="default"
    tracking={{
      eventCategory: 'Search',
      eventAction: 'informative-homecard',
      eventLabel: '24h-return'
    }}
    isOneColumnView={isOneColumnView}
  />
);

BookSecurelyBanner.propTypes = {
  isOneColumnView: PropTypes.bool.isRequired
};

export default BookSecurelyBanner;
