import { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SliderPlaceholder from './SliderPlaceholder';
import { createPictureEl, renderPicture } from './utils/picture';
import { RATIOS } from './utils/constants';

import styles from './SliderTrackImage.module.scss';

const SliderTrackImage = ({
  item,
  shouldLoad = false,
  showCaption = false,
  ratio,
  noPlaceholder = false,
  isCurrent = false
}) => {
  const [imageLoaded, setImageLoaded] = useState(noPlaceholder);
  const imgRef = useRef(null);

  const handleLoad = e => {
    if (e.target && document.body.contains(e.target)) {
      setImageLoaded(true);
    }
  };

  useEffect(() => {
    if (shouldLoad && !imageLoaded && !noPlaceholder && item.src) {
      const picture = createPictureEl(item.src, item.caption, handleLoad);

      imgRef.current.insertAdjacentElement('beforeBegin', picture);
      imgRef.current.remove();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, shouldLoad, noPlaceholder]);

  const className = classNames(styles['slider-figure'], {
    [styles['slider-figure--hidden']]: !imageLoaded && item.src
  });

  if (item.renderItem) {
    return item.renderItem({ className, isCurrent });
  }

  return (
    <Fragment>
      {!imageLoaded && <SliderPlaceholder ratio={ratio} />}
      <figure className={className}>
        {!item ? null : (
          <Fragment>
            {noPlaceholder ? (
              renderPicture(item.src, item.caption)
            ) : (
              <span ref={imgRef} />
            )}
            {showCaption && item.caption && (
              <figcaption>{item.caption}</figcaption>
            )}
          </Fragment>
        )}
      </figure>
    </Fragment>
  );
};

SliderTrackImage.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    renderItem: PropTypes.func,
    src: PropTypes.string,
    caption: PropTypes.string
  }).isRequired,
  showCaption: PropTypes.bool,
  shouldLoad: PropTypes.bool,
  ratio: PropTypes.oneOf(Object.values(RATIOS)).isRequired,
  noPlaceholder: PropTypes.bool,
  isCurrent: PropTypes.bool
};

export default SliderTrackImage;
