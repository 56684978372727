const TYPES = ['webp', 'jpg'];

const replaceFormat = (src, newFormat) => {
  const splitSrc = src.split('.');

  splitSrc.pop();

  return [...splitSrc, newFormat].join('.');
};

const createSource = (src, type) => {
  const source = document.createElement('source');
  source.type = `image/${type}`;
  source.srcset = replaceFormat(src, type);

  return source;
};

const createImg = (src, caption, onload) => {
  const img = document.createElement('img');
  img.src = src;
  img.alt = caption;
  img.onload = onload;

  return img;
};

export const createPictureEl = (src, caption, onload) => {
  const img = createImg(src, caption, onload);
  const picture = document.createElement('picture');
  TYPES.forEach(type => {
    const source = createSource(src, type);
    picture.appendChild(source);
  });

  picture.appendChild(img);

  return picture;
};

export const renderPicture = (src, caption) => (
  <picture>
    {TYPES.map(type => (
      <source
        key={type}
        type={`image/${type}`}
        srcSet={replaceFormat(src, type)}
      />
    ))}
    <img src={src} alt={caption} />
  </picture>
);

export default {
  createPictureEl
};
