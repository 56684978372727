import aggregateRating from '../../../../utils/aggregateRating';

const getStructuredDataAttributes = () => ({
  name: 'Spotahome',
  url: 'https://www.spotahome.com',
  description: '',
  logo: 'https://sah-public-assets.s3.eu-west-1.amazonaws.com/logo/logo_360.png',
  priceRange: '$-$$$',
  sameAs: [
    'https://twitter.com/spotahome',
    'https://facebook.com/spotahome',
    'https://instagram.com/spotahome',
    'https://pinterest.com/spotahome',
    'https://plus.google.com/112987047080252998385'
  ],
  aggregateRating
});

export default getStructuredDataAttributes;
