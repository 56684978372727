import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz/client';

import HomeCardBannerImage from '../../components/HomeCardBannerImage';

import './BookSecurelyBannerFront.scss';

const BookSecurelyBannerFront = ({ isOneColumnView }) => (
  <Fragment>
    <HomeCardBannerImage
      bannerName={'book-securely'}
      fullHeight={isOneColumnView}
      mobileImage={isOneColumnView}
    >
      <div className="book-securely-banner-front__label-wrapper">
        <div className="book-securely-banner-front__label">
          {trans(`homecard.banner.book-securely.label`)}
        </div>
        {isOneColumnView && (
          <div className="book-securely-banner-front__title--one-column-view">
            {trans(`homecard.banner.book-securely.title`)}
          </div>
        )}
      </div>
    </HomeCardBannerImage>
    {!isOneColumnView && (
      <div className="book-securely-banner-front__title">
        {trans(`homecard.banner.book-securely.title`)}
      </div>
    )}
  </Fragment>
);

BookSecurelyBannerFront.propTypes = {
  isOneColumnView: PropTypes.bool
};

BookSecurelyBannerFront.defaultProps = {
  isOneColumnView: false
};

export default BookSecurelyBannerFront;
