import { trans } from '@spotahome/soyuz/client';

const COMMA = ',';
const SPACE = ' ';
const MAX_PROPERTY_TYPE_FILTERS = 4;

const getPropertyTypeText = propertyType =>
  trans(`search.filter_header.results.${propertyType}`);

const noPropertyTypeApplied = propertyTypes =>
  !propertyTypes.length || propertyTypes.length >= MAX_PROPERTY_TYPE_FILTERS;

const getPropertyFilterTitle = (propertyTypes, nBedrooms) => {
  if (noPropertyTypeApplied(propertyTypes)) {
    return trans('search.filter_header.results');
  }
  const PROPERTY_TYPES_WITH_BEDROOMS = ['apartments', 'rooms'];

  if (
    propertyTypes.length === 1 &&
    PROPERTY_TYPES_WITH_BEDROOMS.includes(propertyTypes[0]) &&
    nBedrooms.length === 1
  ) {
    return [
      trans(
        `search.filter_header.results.${propertyTypes[0]}.${nBedrooms[0]}_bedroom`
      )
    ].join(SPACE);
  }

  const propertyTypesCopy = [...propertyTypes];
  const lastPropertyTypeTitle = getPropertyTypeText(propertyTypesCopy.pop());
  const basePropertyTypeTitle = propertyTypesCopy
    .map(propertyType => getPropertyTypeText(propertyType))
    .join(`${COMMA}${SPACE}`);

  return [basePropertyTypeTitle, lastPropertyTypeTitle]
    .filter(Boolean)
    .join(`${SPACE}${trans('search.filter_header.results.and')}${SPACE}`);
};

const getShortLongTermSuffix = propertyTypes =>
  noPropertyTypeApplied(propertyTypes)
    ? trans('search.filter_header.results.properties_claim')
    : null;

export const generateTitleElems = (
  propertyTypes = [],
  cityName,
  nBedrooms = []
) => {
  return [
    getPropertyFilterTitle(propertyTypes, nBedrooms),
    trans('search.filter_header.results.for_rent'),
    cityName,
    getShortLongTermSuffix(propertyTypes)
  ]
    .filter(Boolean)
    .join(SPACE);
};

export const generateTitleElemsSimplified = (types, cityName, totalResults) => {
  const [type] = types;
  const key = type ? `search.title.${type}` : 'search.title';

  return trans(key, {
    city: cityName,
    nProperties: totalResults
  });
};

export default {
  generateTitleElems,
  generateTitleElemsSimplified
};
