import { useRef } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import './FormHistogram.scss';

const N_BARS = 40;

const FormHistogram = ({ maxHeight, priceList, from, to, histogramRange }) => {
  const distancesCached = useRef(undefined);

  const calculateDistances = () => {
    if (distancesCached.current) {
      return distancesCached.current;
    }
    const newDistancesCached = [];
    let previous;
    histogramRange.forEach(item => {
      if (previous !== undefined) {
        newDistancesCached.push({
          lengthValue: item.value - previous.value,
          lengthPercentage: item.percentage - previous.percentage,
          startOuterValue: previous.value,
          startOuterPercentage: previous.percentage
        });
      }
      previous = item;
    });
    distancesCached.current = newDistancesCached;
    return newDistancesCached;
  };

  const distanceOfPrice = (distances, price) =>
    distances.find(
      distance => price <= distance.lengthValue + distance.startOuterValue
    );

  const nonLinearIndexOfPrice = (value, distances) => {
    const distance = distanceOfPrice(distances, value);

    if (distance) {
      const innerBars = parseInt(N_BARS * distance.lengthPercentage);
      const innerValue = value - distance.startOuterValue;
      const innerIndex = parseInt(
        (innerValue * innerBars) / distance.lengthValue
      );
      const innerOffset = parseInt(N_BARS * distance.startOuterPercentage);

      return innerIndex + innerOffset - 1;
    }

    return value;
  };

  const barsAbsolute = Array(N_BARS).fill(0);
  const distances = calculateDistances();

  priceList.forEach(price => {
    const index = nonLinearIndexOfPrice(price, distances);
    const currentPrice = barsAbsolute[index];
    barsAbsolute[index] = currentPrice + 1;
  });

  const ceiling = Math.max(...barsAbsolute.filter(val => val >= 0));
  const output = [];
  const generalWidth = `${100 / N_BARS}%`;
  const heightUnit = ceiling === 0 ? 0 : maxHeight / ceiling;
  const leftKnobIndex = nonLinearIndexOfPrice(from, distances);
  const rightKnobIndex = nonLinearIndexOfPrice(to, distances);

  barsAbsolute.forEach((bar, index) => {
    const isActive = index > leftKnobIndex && index <= rightKnobIndex;
    const classes = classNames('form-histogram__bar', {
      'form-histogram__bar--active': isActive
    });

    const divStyle = {
      height: heightUnit * bar,
      width: generalWidth
    };
    output.push(
      <div key={`index-${index}`} className={classes} style={divStyle} />
    );
  });

  const style = {
    height: `${maxHeight}px`
  };

  return (
    <div className="form-histogram" style={style}>
      {output}
    </div>
  );
};

FormHistogram.propTypes = {
  priceList: PropTypes.arrayOf(PropTypes.number),
  histogramRange: PropTypes.arrayOf(
    PropTypes.shape({
      percentage: PropTypes.number,
      value: PropTypes.number
    })
  ),
  from: PropTypes.number,
  to: PropTypes.number,
  maxHeight: PropTypes.number
};

FormHistogram.defaultProps = {
  priceList: [],
  histogramRange: [],
  from: 0,
  to: 100,
  maxHeight: 60
};

export default FormHistogram;
