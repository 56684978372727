import { useState } from 'react';
import PropTypes from 'prop-types';

import { InfoBanner, useCookieContext } from '@spotahome/ui-library';
import { trans, useSoyuzExperiment } from '@spotahome/soyuz/client';
import Tracking from '@spotahome/soyuz-tracking';

import './ValueProposition.scss';

export const COOKIE_NAME = 'sah-search-value-proposition';
export const COOKIE_VALUE = 1;
const COOKIE_15_DAYS = 60 * 60 * 24 * 15;

const BULLET_POINTS = [
  {
    renderIcon: () => <i className="ds-icon-device_portrait" />,
    key: 'search.value_proposition.rentals'
  },
  {
    renderIcon: () => <i className="ds-icon-check_circle" />,
    key: 'search.value_proposition.fraud-protection'
  },
  {
    renderIcon: () => <i className="ds-icon-diamond" />,
    key: 'search.value_proposition.cancellation'
  }
];

const ValueProposition = ({ className, showInVariant, deviceType }) => {
  const { getCookie, setCookie } = useCookieContext();
  const [isClosed, setIsClosed] = useState(!!getCookie(COOKIE_NAME));
  const VPMobileMidHomecardsExperiment = useSoyuzExperiment(
    'VPMobileMidHomecards'
  );
  const handleClose = eventLabel => {
    Tracking.ga.sendEvent('Search', 'ValueProposition', eventLabel);
    setCookie(COOKIE_NAME, COOKIE_VALUE, { maxAge: COOKIE_15_DAYS });
    setIsClosed(true);
  };

  if (isClosed || getCookie(COOKIE_NAME)) {
    return null;
  }

  // Hide component that should render in experimental mobile version
  if (deviceType !== 'smartphone' && showInVariant) {
    return null;
  }

  const shouldBeHiddenInVariant = () =>
    VPMobileMidHomecardsExperiment.get('behaviour') === 'variant' &&
    !showInVariant;

  const shouldBeHiddenInOriginal = () =>
    VPMobileMidHomecardsExperiment.get('behaviour') === 'original' &&
    showInVariant;

  if (
    deviceType === 'smartphone' &&
    (shouldBeHiddenInVariant() || shouldBeHiddenInOriginal())
  ) {
    return null;
  }

  const bullets = BULLET_POINTS;

  return (
    <InfoBanner
      className={className}
      closePosition="center"
      onCloseBanner={handleClose}
      content={bullets.map(bulletPoint => (
        <div className="value-proposition__bullet-point" key={bulletPoint.key}>
          {bulletPoint.renderIcon()}
          <div>
            <p className="value-proposition__bullet-point__text--original">
              {trans(bulletPoint.key)}
            </p>
            {bulletPoint.subkey && (
              <p className="value-proposition__bullet-point__subtext">
                {trans(bulletPoint.subkey)}
              </p>
            )}
          </div>
        </div>
      ))}
    />
  );
};

ValueProposition.propTypes = {
  className: PropTypes.string,
  deviceType: PropTypes.oneOf(['smartphone', 'tablet', 'desktop']).isRequired,
  showInVariant: PropTypes.bool // Not very fond of this kind of props, but it will allow us to keep the experiment logic centralized here, without duplicating cookies logic
};

ValueProposition.defaultProps = {
  className: '',
  showInVariant: false
};

export default ValueProposition;
