const TimerIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="30"
      viewBox="0 0 44 30"
      {...props}
    >
      <g fill="#080808" fillRule="nonzero">
        <path
          stroke="#080808"
          strokeWidth=".6"
          d="M30.812 3.846V2.182h4.483V1.17h-9.967v1.013h4.483v1.664c-6.669.27-12.04 5.886-12.04 12.673 0 6.995 5.626 12.687 12.54 12.687 6.915 0 12.541-5.692 12.541-12.687 0-6.787-5.371-12.404-12.04-12.673zm-.5 24.346c-6.363 0-11.54-5.236-11.54-11.673 0-6.436 5.177-11.673 11.54-11.673 6.362 0 11.538 5.237 11.538 11.673 0 6.437-5.176 11.673-11.539 11.673z"
        />
        <path
          stroke="#080808"
          strokeWidth=".6"
          d="M30.812 8.341h-1.001v8.685h5.124v-1.014h-4.123z"
        />
        <path d="M4.23 11.697h7.049v1H4.23zM1.41 20.254h7.049v1H1.41zM0 14.549h11.279v1H0zM2.82 17.402h11.279v1H2.82z" />
      </g>
    </svg>
  );
};

export default TimerIcon;
