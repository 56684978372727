import { memo, useState } from 'react';

import { LabelItem, CloseButton } from '@spotahome/ui-library';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './GenericBanner.scss';

const GenericBanner = memo(({ content, label, color, dataTest }) => {
  const [isOpen, setIsOpen] = useState(true);

  const renderContent = () => {
    return (
      <div
        className={classNames(
          'generic-banner__content-text',
          `generic-banner__content-text--${color}`
        )}
      >
        <p>{content}</p>
      </div>
    );
  };

  return (
    isOpen && (
      <div
        data-test={dataTest}
        className={classNames(
          'generic-banner__container',
          `generic-banner__container--${color}`
        )}
      >
        <div className="generic-banner__content">
          {label && (
            <LabelItem
              text={label}
              color={color}
              className="generic-banner__label"
            />
          )}
          {renderContent()}
        </div>
        <CloseButton
          size="small"
          className={classNames(
            'generic-banner__close-button',
            `generic-banner__close-button--${color}`
          )}
          onClick={() => {
            setIsOpen(false);
          }}
        />
      </div>
    )
  );
});

GenericBanner.propTypes = {
  content: PropTypes.string.isRequired,
  color: PropTypes.oneOf[('blue-cerulean', 'blue-indigo')],
  dataTest: PropTypes.string,
  label: PropTypes.string
};

GenericBanner.defaultProps = {
  dataTest: 'generic-banner',
  color: 'blue-cerulean',
  label: ''
};

export default GenericBanner;
