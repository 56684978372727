import BookSecurelyBanner from './banners/BookSecurelyBanner';
import SelectBanner from './banners/SelectBanner';
import LongTermBanner from './banners/LongTermBanner';

const BOOK_SECURELY_BANNER = {
  name: 'book-securely',
  isActive: () => false,
  component: BookSecurelyBanner,
  position: 3
};

const SELECT_BANNER = {
  name: 'select',
  isActive: () => false,
  component: SelectBanner,
  position: 3
};

const LONG_TERM_BANNER = {
  name: 'longTerm',
  isActive: () => false,
  component: LongTermBanner,
  position: 2
};

const BANNERS = [SELECT_BANNER, BOOK_SECURELY_BANNER, LONG_TERM_BANNER];

export const getActiveBanners = (cityId, experiments = {}) =>
  BANNERS.filter(config => config.isActive(cityId, experiments));

export const getNumberActiveBanners = (cityId, experiments) =>
  getActiveBanners(cityId, experiments).length;

export default {
  getActiveBanners,
  getNumberActiveBanners
};
