import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Observer from 'react-intersection-observer';
import classNames from 'classnames';

import styles from './HorizontalHomecardImage.module.scss';

const renderHomecardImage = (
  pictureClassName,
  imageSet,
  imageClassName,
  title,
  image,
  handleOnLoad
) => (
  <picture className={pictureClassName}>
    <div className={styles['horizontal-home-card__image-mask']} />
    <img
      onLoad={handleOnLoad}
      srcSet={imageSet}
      className={imageClassName}
      alt={title}
      src={image}
    />
  </picture>
);
class HorizontalHomecardImage extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    image: PropTypes.string.isRequired,
    imageSet: PropTypes.string,
    onLoad: PropTypes.func,
    title: PropTypes.string.isRequired,
    discount: PropTypes.shape({
      quantity: PropTypes.string,
      expiresAt: PropTypes.string
    }),
    hasLazyLoad: PropTypes.bool
  };

  static defaultProps = {
    className: '',
    imageSet: '',
    onLoad: () => {},
    discount: null,
    hasLazyLoad: false
  };

  state = {
    imageLoaded: false
  };

  handleOnLoad = () => {
    this.setState(
      {
        imageLoaded: true
      },
      () => {
        this.props.onLoad();
      }
    );
  };

  render() {
    const { className, image, imageSet, title } = this.props;

    const imageContainerClassName = classNames(
      styles['horizontal-home-card__image-container'],
      className
    );

    const imageClassName = classNames(styles['horizontal-home-card__image']);

    const pictureClassName = classNames({
      [styles['horizontal-home-card__image--hidden']]: !this.state.imageLoaded
    });

    return (
      <div className={imageContainerClassName}>
        {this.props.hasLazyLoad ? (
          <Observer tag="span" triggerOnce>
            {inView =>
              inView &&
              renderHomecardImage(
                pictureClassName,
                imageSet,
                imageClassName,
                title,
                image,
                this.handleOnLoad
              )
            }
          </Observer>
        ) : (
          renderHomecardImage(
            pictureClassName,
            imageSet,
            imageClassName,
            title,
            image,
            this.handleOnLoad
          )
        )}
        {!this.state.imageLoaded && (
          <div className={styles['home-card__placeholder']} />
        )}
      </div>
    );
  }
}

export default HorizontalHomecardImage;
