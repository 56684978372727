import { Component } from 'react';
import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz/client';
import { showInformationToast, withCookie } from '@spotahome/ui-library';

import {
  hasValidCityInfo,
  cityInformation
} from '../../components/InformationToast/informationToastUtils';

import TimerIcon from './../icons/TimerIcon';

const TOAST_EXIT_TIMEOUT = 20000;
const TOAST_ENTER_TIMEOUT = 3000;

const COOKIES_ACCEPTED_VALUE = '1';
const COOKIE_CONFIG = {
  cookieName: 'city-event-toast',
  cookieExpires: {
    days: 1
  }
};
class InformationToastWrapper extends Component {
  componentDidMount() {
    const toastProps = {
      renderIcon: () => <TimerIcon />,
      renderContent: () =>
        trans(cityInformation('freeComission', this.props.city))
    };
    if (
      hasValidCityInfo(this.props.city) &&
      !this.props.isCookieAccepted(COOKIES_ACCEPTED_VALUE)
    ) {
      this.props.acceptCookie(COOKIES_ACCEPTED_VALUE);
      showInformationToast(toastProps, {
        autoClose: TOAST_EXIT_TIMEOUT,
        delay: TOAST_ENTER_TIMEOUT
      });
    }
  }

  render() {
    return null;
  }
}

InformationToastWrapper.propTypes = {
  isCookieAccepted: PropTypes.func.isRequired,
  acceptCookie: PropTypes.func.isRequired,
  city: PropTypes.string.isRequired
};

export default withCookie(COOKIE_CONFIG)(InformationToastWrapper);
