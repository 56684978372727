const Bell = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.125 5.88065C13.125 2.63265 10.3824 0 7 0C3.61764 0 0.875 2.63265 0.875 5.88065V11.0833H13.125V5.88065ZM6.99992 14C5.94495 14 5.05351 13.2999 4.76488 12.3389H9.23496C8.94633 13.2999 8.05489 14 6.99992 14Z"
      fill="#ffff"
    />
  </svg>
);

export default Bell;
