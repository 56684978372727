/* eslint-disable jsx-a11y/anchor-has-content */

import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz/client';
import { IconGeneric, Button } from '@spotahome/ui-library';

import './Paginator.scss';

const Paginator = ({
  previousPageUrl,
  nextPageUrl,
  previousButtonDisabled = false,
  nextButtonDisabled = false,
  onPreviousButtonClick = () => {},
  onNextButtonClick = () => {}
}) => (
  <div className="paginator">
    <Button
      fullWidth
      color="tertiary"
      size="large"
      onClick={onPreviousButtonClick}
      dataTest="paginator-btn-prev"
      disabled={previousButtonDisabled}
    >
      <IconGeneric name="angle-left" /> {trans('paginator.label.prev')}
      <a href={previousPageUrl} />
    </Button>
    <Button
      color="tertiary"
      size="large"
      fullWidth
      onClick={onNextButtonClick}
      dataTest="paginator-btn-next"
      disabled={nextButtonDisabled}
    >
      {trans('paginator.label.next')} <IconGeneric name="angle-right" />
      <a href={nextPageUrl} />
    </Button>
  </div>
);

Paginator.propTypes = {
  previousButtonDisabled: PropTypes.bool,
  nextButtonDisabled: PropTypes.bool,
  onPreviousButtonClick: PropTypes.func,
  onNextButtonClick: PropTypes.func,
  previousPageUrl: PropTypes.string.isRequired,
  nextPageUrl: PropTypes.string.isRequired
};

export default Paginator;
