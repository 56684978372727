import PropTypes from 'prop-types';

import BaseButton from '../BaseButton';

const FabButton = ({
  onClick,
  color,
  disabled,
  dataTest,
  className,
  renderContent,
  renderIcon,
  size
}) => (
  <BaseButton
    className={className}
    onClick={onClick}
    color={color}
    size={size}
    disabled={disabled}
    dataTest={dataTest}
    roundedCorners
    dropShadow
    renderContent={renderContent}
    renderIcon={renderIcon}
  />
);

FabButton.defaultProps = {
  onClick: () => {},
  renderContent: null,
  renderIcon: null,
  color: 'primary',
  size: 'medium',
  className: null,
  disabled: false,
  dataTest: null
};

FabButton.propTypes = {
  renderContent: PropTypes.func,
  renderIcon: PropTypes.func,
  className: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'tertiary-light'
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  disabled: PropTypes.bool,
  dataTest: PropTypes.string
};

export default FabButton;
