import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './GalleryArrowWithBackground.module.scss';

const GalleryArrowWithBackground = ({
  className = '',
  dataTest = '',
  isRotatedAxis = false
}) => {
  const arrowClasses = classNames(
    {
      [styles['gallery-arrow--is-rotated']]: isRotatedAxis
    },
    className
  );

  return (
    <svg
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={arrowClasses}
      data-test={dataTest}
    >
      <path
        d="M0.99823 17.5344C0.99823 8.14552 8.60939 0.534363 17.9982 0.534363C27.3871 0.534363 34.9982 8.14552 34.9982 17.5344C34.9982 26.9232 27.3871 34.5344 17.9982 34.5344C8.60939 34.5344 0.99823 26.9232 0.99823 17.5344Z"
        fill="white"
        className={styles['gallery-arrow']}
        fillOpacity="0.8"
      />
      <path
        d="M17.9983 23.5344L23.9983 17.5344L17.9983 11.5344"
        stroke="#0D0D0D"
        strokeWidth="1.5"
        style={{ pointerEvents: 'none' }}
      />
      <line
        x1="23.9983"
        y1="17.5343"
        x2="10.9983"
        y2="17.5343"
        stroke="#0D0D0D"
        strokeWidth="1.5"
        style={{ pointerEvents: 'none' }}
      />
    </svg>
  );
};

GalleryArrowWithBackground.propTypes = {
  className: PropTypes.string,
  dataTest: PropTypes.string,
  isRotatedAxis: PropTypes.bool
};

export const GalleryArrowWithBackgroundRight = props => (
  <GalleryArrowWithBackground {...props} dataTest={'gallery-arrow-right'} />
);

export const GalleryArrowWithBackgroundLeft = props => (
  <GalleryArrowWithBackground
    {...props}
    dataTest="gallery-arrow-left"
    isRotatedAxis
  />
);

export default {
  GalleryArrowWithBackgroundRight,
  GalleryArrowWithBackgroundLeft
};
