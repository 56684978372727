import { forwardRef } from 'react';
import PropTypes from 'prop-types';

const BillsLabelIcon = forwardRef(
  ({ size = 40, className = '', ...props }, ref) => (
    <svg
      {...props}
      className={className}
      ref={ref}
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="14"
        cy="13.9995"
        r="12.3913"
        stroke="#0D0D0D"
        strokeWidth="1.30435"
      />
      <path
        d="M13.7186 18.996C14.7232 18.996 15.572 18.7477 16.2649 18.2512C16.9578 17.7425 17.4601 17.0643 17.7719 16.2166H19.4348C19.0653 17.5488 18.3955 18.6024 17.4255 19.3775C16.4554 20.1405 15.2256 20.522 13.7359 20.522C12.3155 20.522 11.1029 20.1042 10.0983 19.2685C9.09359 18.4207 8.43536 17.2884 8.12356 15.8714H6.39136V15.0176H7.96766C7.93302 14.6906 7.91569 14.3575 7.91569 14.0184C7.91569 13.8246 7.92724 13.5461 7.95034 13.1828H6.39136V12.3289H8.07159C8.26791 11.3601 8.6259 10.5123 9.14556 9.78565C9.66522 9.04688 10.3177 8.47767 11.1029 8.07801C11.8998 7.67835 12.7774 7.47852 13.7359 7.47852C15.2256 7.47852 16.4554 7.86607 17.4255 8.64117C18.3955 9.40415 19.0653 10.4517 19.4348 11.784H17.7719C17.4601 10.9362 16.9578 10.264 16.2649 9.76748C15.572 9.25882 14.7232 9.00449 13.7186 9.00449C12.7601 9.00449 11.9229 9.30121 11.2069 9.89465C10.4909 10.476 10.0059 11.2874 9.75183 12.3289H13.9784V13.1828H9.59593C9.56129 13.4492 9.54396 13.7278 9.54396 14.0184C9.54396 14.406 9.56706 14.739 9.61325 15.0176H13.9784V15.8714H9.78647C10.0752 16.8524 10.566 17.6214 11.2588 18.1785C11.9633 18.7235 12.7832 18.996 13.7186 18.996Z"
        fill="#0D0D0D"
      />
    </svg>
  )
);

BillsLabelIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string
};

export default BillsLabelIcon;
