import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz-trans/client';

import FacebookIcon from '../icons/FacebookIcon';
import Instagram from '../icons/Instagram';
import LinkedIn from '../icons/LinkedIn';
import TwitterIcon from '../icons/TwitterIcon';

import FooterSimplifiedBlock from './components/FooterSimplifiedBlock';
import FooterSimplifiedBlockList from './components/FooterSimplifiedBlockList';
import FooterSimplifiedLink from './components/FooterSimplifiedLink';
import styles from './FooterSimplified.module.scss';

const SOCIAL_LINKS = [
  {
    urlKey: 'footer.social.instagram',
    IconComponent: Instagram
  },
  {
    urlKey: 'footer.social.twitter',
    IconComponent: TwitterIcon
  },
  {
    urlKey: 'footer.social.linkedin',
    IconComponent: LinkedIn
  },
  {
    urlKey: 'footer.social.facebook',
    IconComponent: FacebookIcon
  }
];

const SocialFooter = () => (
  <FooterSimplifiedBlock className={styles.socialmedia}>
    <FooterSimplifiedBlockList hasSocial>
      {SOCIAL_LINKS.map(({ urlKey, IconComponent }) => (
        <FooterSimplifiedLink
          key={urlKey}
          href={trans(urlKey)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconComponent size="big" theme="beige" />
        </FooterSimplifiedLink>
      ))}
    </FooterSimplifiedBlockList>
  </FooterSimplifiedBlock>
);

SocialFooter.propTypes = {};

export default SocialFooter;
