const cityToggleConfig = cityId => ({
  showFeeOffer: [].includes(cityId),
  isInSpain: ['madrid', 'barcelona', 'valencia'].includes(cityId),
  showPriceIndexTooltip: ['barcelona'].includes(cityId),
  isNoDepositSupported: [
    'barcelona',
    'berlin',
    'brussels',
    'dublin',
    'florence',
    'lisbon',
    'london',
    'madrid',
    'milan',
    'paris',
    'porto',
    'rome',
    'valencia',
    'vienna'
  ].includes(cityId),
  isCoreCity: [
    'barcelona',
    'berlin',
    'brussels',
    'dublin',
    'florence',
    'lisbon',
    'london',
    'madrid',
    'milan',
    'paris',
    'porto',
    'rome',
    'valencia',
    'vienna'
  ].includes(cityId),
  includeAdditionalDetailsPlaceholder: [
    'berlin',
    'lisbon',
    'milan',
    'brussels'
  ].includes(cityId),
  canSeeTenantServices: [].includes(cityId),
  isTenantFeeExperimentCity: ![
    'london',
    'nottingham',
    'glasgow',
    'edinburgh',
    'manchester',
    'amsterdam',
    'rotterdam'
  ].includes(cityId),
  depositIsForbidden: ['paris'].includes(cityId),
  isDocumentRequiredCity: [
    'munich',
    'hamburg',
    'frankfurt',
    'cologne',
    'stuttgart',
    'duesseldorf',
    'leipzig',
    'paris',
    'lyon',
    'nice',
    'montpellier',
    'lille',
    'marseille',
    'toulouse'
  ].includes(cityId)
});

export default cityToggleConfig;
