import PropTypes from 'prop-types';

import themes from '../utils/iconColorThemes';

const ChevronDescend = ({ theme = 'base' }) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.99998 5.77951L8.86432 2.34454L9.7983 3.39527L4.99998 7.66044L0.20166 3.39527L1.13564 2.34454L4.99998 5.77951Z"
      fill={themes[theme]}
    />
  </svg>
);

ChevronDescend.propTypes = {
  theme: PropTypes.oneOf(Object.keys(themes))
};

export default ChevronDescend;
