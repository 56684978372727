import PropTypes from 'prop-types';

const MapIcon = ({ color }) => (
  <svg
    width="14px"
    height="18px"
    viewBox="0 0 14 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Group 3</title>
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1.2"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="[Search]-Mobile---List"
        transform="translate(-331.000000, -17.000000)"
        stroke={color}
      >
        <g id="Group-7" transform="translate(280.737212, 8.000000)">
          <g id="Group-3" transform="translate(50.262788, 9.000000)">
            <circle id="Oval" cx="6.82051282" cy="6.46153846" r="2.37179487" />
            <path
              d="M6.82153257,0.499999459 C8.56938444,0.502571786 10.1520683,1.18023942 11.2974247,2.27716801 C12.4346236,3.36628411 13.1401353,4.8693746 13.1410256,6.53044786 C13.1410256,8.74066999 10.9843532,12.0547973 6.82165164,16.501115 C2.65753269,12.0543323 0.499999998,8.74053073 0.499999928,6.53071573 C0.500890314,4.86877332 1.20690026,3.36525782 2.34475116,2.27607817 C3.49076743,1.17908241 5.07427024,0.50171465 6.82153257,0.499999459 Z"
              id="Fill-1"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

MapIcon.propTypes = {
  color: PropTypes.string
};

MapIcon.defaultProps = {
  color: '#003762'
};

export default MapIcon;
