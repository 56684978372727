const DEFAULT_PAGE_SIZE = 30;
const DEFAULT_INITIAL_PAGE = 1;
const DEFAULT_OPTS = {
  pageSize: DEFAULT_PAGE_SIZE,
  initialPage: DEFAULT_INITIAL_PAGE
};

class CardPaginator {
  constructor(opts = {}) {
    const options = { ...DEFAULT_OPTS, ...opts };

    this.pageSize = options.pageSize;
    this.firstPageSize = options.firstPageSize || this.pageSize;
    this.originalData = [];
    this.data = [];
    this.currentPage = options.initialPage;
  }

  initializeData = data => {
    this.originalData = data;
    this.data = this.originalData;
  };

  updateData = data => {
    this.originalData = data;
    this.data = this.originalData;
    this.validateCurrentPage();
  };

  getAllMarkers = () => this.data;

  hasPrevPage = () => this.currentPage > 1;

  hasNextPage = () => this.currentPage < this.getTotalPages();

  getLength = () => this.data.length;

  getOriginalLength = () => this.originalData.length;

  getCurrentPageNumber = () => this.currentPage;

  getNextPageNumber = () => (this.hasNextPage() ? this.currentPage + 1 : null);

  getPreviousPageNumber = () =>
    this.hasPrevPage() ? this.currentPage - 1 : null;

  getTotalPages = () => Math.ceil(this.data.length / this.pageSize);

  getCurrentPageContent = () => {
    const pageSize =
      this.currentPage === 1 ? this.firstPageSize : this.pageSize;

    let currentPageElems;
    const startIndex = (this.currentPage - 2) * pageSize + this.firstPageSize;
    if (this.currentPage === this.getTotalPages()) {
      currentPageElems = this.data.slice(startIndex);
    } else {
      currentPageElems = this.data.slice(startIndex, startIndex + pageSize);
    }

    return currentPageElems;
  };

  setCurrentPage = page => {
    this.currentPage = page || DEFAULT_INITIAL_PAGE;
  };

  setFirstPageSize = size => {
    this.firstPageSize = size;
  };

  nextPage = () => {
    if (this.hasNextPage()) {
      this.currentPage += 1;
    }
  };

  previousPage = () => {
    if (this.hasPrevPage()) {
      this.currentPage -= 1;
    }
  };

  filter = filterCb => {
    if (typeof filterCb === 'function') {
      this.data = this.originalData.filter(filterCb);
      this.validateCurrentPage();
    }
  };

  restoreOriginalData = () => {
    this.data = this.originalData;
  };

  validateCurrentPage = () => {
    if (this.getTotalPages() < this.currentPage) {
      this.currentPage = 1;
    }
  };
}

export default CardPaginator;
