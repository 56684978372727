/* global dataLayer */
import { Component } from 'react';
import PropTypes from 'prop-types';
import NoSSR from 'react-no-ssr';
import Nouislider from 'nouislider';

import Currency from '@spotahome/soyuz-currency';

import FormHistogram from '../FormHistogram';

import './FormSlider.scss';
import './nouislider.scss';

const THRESHOLD_VALUE = 600;

const hasLimitChanged = (currentLimit, prevLimit) =>
  !isNaN(currentLimit) && currentLimit !== prevLimit;

const getMiddleValue = value =>
  value >= THRESHOLD_VALUE ? THRESHOLD_VALUE : value / 2;

class FormSlider extends Component {
  componentDidMount() {
    this._triggerSlider();
  }

  componentDidUpdate(prevProps) {
    const { sliderFrom, sliderTo } = this.props;
    if (
      hasLimitChanged(sliderFrom, prevProps.sliderFrom) ||
      hasLimitChanged(sliderTo, prevProps.sliderTo)
    ) {
      this._setPriceSlider(sliderFrom, sliderTo);
    }
  }

  getLabel = (value, currencyIsoCode, addPlusSymbol) => {
    const parsedValue = parseInt(value, 10);
    const appendSymbol =
      addPlusSymbol && parsedValue === this.props.maxPrice ? ' +' : '';

    return Currency.formatPrice(parsedValue, currencyIsoCode) + appendSymbol;
  };

  slider = null;

  _histogramRange = maxPrice => [
    { percentage: 0, value: 0 },
    { percentage: 0.5, value: getMiddleValue(maxPrice) },
    { percentage: 1, value: maxPrice }
  ];

  _convertToNouisliderRange = histogramRange => {
    const nouisliderRange = {};
    histogramRange.forEach((item, index) => {
      if (index === 0) {
        nouisliderRange.min = item.value;
      } else if (index + 1 === histogramRange.length) {
        nouisliderRange.max = item.value;
      } else {
        const percentageString = `${(item.percentage * 100).toString()}%`;
        nouisliderRange[percentageString] = item.value;
      }
    });

    return nouisliderRange;
  };

  _triggerSlider() {
    const nouisliderRange = this._convertToNouisliderRange(
      this._histogramRange(this.props.maxPrice)
    );
    Nouislider.create(this.slider, {
      start: [this.props.sliderFrom, this.props.sliderTo],
      connect: true,
      range: nouisliderRange
    });

    this.slider.noUiSlider.on('change', ([fromValue, toValue]) => {
      const { onChange, sliderFrom, sliderTo } = this.props;
      const fromValueParsed = parseInt(fromValue, 10);
      const toValueParsed = parseInt(toValue, 10);

      onChange({ sliderFrom: fromValueParsed, sliderTo: toValueParsed });

      // Trigger analytics tracking events
      if (typeof dataLayer !== 'undefined' && sliderFrom !== fromValueParsed) {
        dataLayer.push({ event: this._getSliderFromChangedEventName() });
      }
      if (typeof dataLayer !== 'undefined' && sliderTo !== toValueParsed) {
        dataLayer.push({ event: this._getSliderToChangedEventName() });
      }
    });

    this.slider.noUiSlider.on('update', this.updateLabels);
  }

  updateLabels = values => {
    const [min, max] = values;

    this.sliderFrom.innerHTML = this.getLabel(min, this.props.currencyIsoCode);
    this.sliderTo.innerHTML = this.getLabel(
      max,
      this.props.currencyIsoCode,
      true
    );
  };

  _setPriceSlider = (sliderFrom, sliderTo) => {
    this.slider.noUiSlider.set([sliderFrom, sliderTo]);
  };

  _getSliderFromChangedEventName = () => `${this.props.trackingPrefix}-from`;

  _getSliderToChangedEventName = () => `${this.props.trackingPrefix}-to`;

  render() {
    const { sliderFrom, sliderTo, maxPrice, unitPrices, histogramMaxHeight } =
      this.props;

    return (
      <div className="form-slider">
        <NoSSR>
          <FormHistogram
            maxHeight={histogramMaxHeight}
            histogramRange={this._histogramRange(maxPrice)}
            priceList={unitPrices}
            max={maxPrice}
            from={sliderFrom}
            to={sliderTo}
          />
        </NoSSR>
        <div ref={node => (this.slider = node)} />
        <div
          ref={node => (this.sliderFrom = node)}
          className="form-slider__from"
        />
        <div ref={node => (this.sliderTo = node)} className="form-slider__to" />
      </div>
    );
  }
}

FormSlider.propTypes = {
  onChange: PropTypes.func.isRequired,
  currencyIsoCode: PropTypes.string.isRequired,
  maxPrice: PropTypes.number.isRequired,
  histogramMaxHeight: PropTypes.number,
  unitPrices: PropTypes.arrayOf(PropTypes.number),
  sliderFrom: PropTypes.number.isRequired,
  sliderTo: PropTypes.number.isRequired,
  trackingPrefix: PropTypes.string.isRequired
};

FormSlider.defaultProps = {
  histogramMaxHeight: 60,
  unitPrices: []
};

export default FormSlider;
