import PropTypes from 'prop-types';
import { formatPrice } from '@spotahome/soyuz-currency';

const BY_MONTH_PRICE_TYPE = 'byMonth';

const isSeasonalPrice = priceType => priceType === BY_MONTH_PRICE_TYPE;

const defaultSeasonalPriceFormat = (seasonalPrice, currencyCode) =>
  `${seasonalPrice.min}-${formatPrice(seasonalPrice.max, currencyCode)}`;

const Themed = ({
  amount,
  currencyCode,
  i18n,
  theme,
  seasonalPrice,
  type,
  isSeasonalPriceFeatureOn,
  monthly,
  seasonalPriceFormat
}) =>
  isSeasonalPriceFeatureOn ? (
    <div className={theme.wrapper}>
      {!isSeasonalPrice(type) && !isSeasonalPriceFeatureOn && (
        <span className={theme.from}>{i18n.from}</span>
      )}
      <span className={theme.price}>
        {isSeasonalPrice(type) && !!seasonalPrice
          ? seasonalPriceFormat(seasonalPrice, currencyCode)
          : formatPrice(amount, currencyCode)}
      </span>
      <span className={theme.priceMonthly}>/{i18n.perMonth}</span>
    </div>
  ) : (
    <div className={theme.wrapper} data-test="priceContainer">
      {isSeasonalPrice(type) && <span className={theme.from}>{i18n.from}</span>}
      <span className={theme.price}>{formatPrice(amount, currencyCode)}</span>
      {monthly && <span className={theme.priceMonthly}>/{i18n.perMonth}</span>}
    </div>
  );

const defaultProps = {
  amount: 0,
  currencyCode: 'EUR',
  isSeasonalPriceFeatureOn: false,
  seasonalPrice: {
    min: 0,
    max: 0
  },
  monthly: true,
  seasonalPriceFormat: defaultSeasonalPriceFormat
};

const propTypes = {
  type: PropTypes.string.isRequired,
  amount: PropTypes.number,
  currencyCode: PropTypes.string,
  seasonalPrice: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number
  }),
  monthly: PropTypes.bool,
  seasonalPriceFormat: PropTypes.func
};

Themed.defaultProps = {
  ...defaultProps,
  i18n: {},
  theme: {}
};

Themed.propTypes = {
  ...propTypes,
  i18n: PropTypes.shape({
    from: PropTypes.string,
    perMonth: PropTypes.string
  }),
  theme: PropTypes.shape({
    currency: PropTypes.string,
    price: PropTypes.string,
    priceMonthly: PropTypes.string,
    wrapper: PropTypes.string
  })
};
export { defaultProps, propTypes };
export default Themed;
