import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz/client';
import { Button } from '@spotahome/ui-library';

const ClearFiltersButton = ({ activeFiltersCount, onClearFilters }) =>
  activeFiltersCount > 0 && (
    <Button
      color="link"
      size="small"
      onClick={onClearFilters}
      className="filters__clear"
      data-test="filters-clear-button"
    >
      {trans('search.filters.clear.cta')}
    </Button>
  );

ClearFiltersButton.propTypes = {
  activeFiltersCount: PropTypes.number.isRequired,
  onClearFilters: PropTypes.func.isRequired
};

export default ClearFiltersButton;
