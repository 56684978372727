import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Mediator } from '@spotahome/soyuz-mediator';
import Metrics from '@spotahome/soyuz-tracking';

import { trans, getPagePath } from '@spotahome/soyuz/client';

import {
  Button,
  CloseButton,
  Modal,
  useModalHandle,
  useCityName,
  useCityCurrency
} from '@spotahome/ui-library';

import { useFiltersContext } from '@spotahome/ui-library/src/tenant/context/FiltersContext';

import AlertBell from '../icons/AlertBell';
import { OPEN_APPLIED_FILTERS_MODAL } from '../../channels';
import { getAlertContent } from '../../utils/getAlertContent';

import './AppliedFiltersModal.scss';

const AppliedFiltersModal = ({ cityId }) => {
  const { isModalOpen, handleModalOpen, handleModalClose } = useModalHandle();
  const { filters } = useFiltersContext();
  const createDefaultFiltersContent = () => {
    return {
      cityId,
      moveIn: filters['move-in'],
      moveOut: filters['move-out'],
      moveOutTo: filters.moveOutTo,
      budget: filters.budget,
      propertyTypes: filters['type[]'],
      cityAreaIds: filters['areaId[]'],
      bathrooms: filters['bathrooms[]'],
      bed: filters.bed,
      features: filters['features[]'],
      instantBooking: filters.instantBooking,
      noDeposit: filters.noDeposit,
      verified: parseInt(filters.verified, 10),
      topology: filters['topology[]'],
      rentalType: filters['rentalType[]'],
      includeBlockedProperties: filters.includeBlockedProperties
    };
  };

  const handleOpen = useCallback(() => {
    Metrics.ga.sendEvent('Search', 'applied-filters-modal', 'open');
    handleModalOpen();
  }, [isModalOpen]);

  useEffect(() => {
    Mediator.subscribe(OPEN_APPLIED_FILTERS_MODAL, handleOpen);
    return () => Mediator.unsubscribe(OPEN_APPLIED_FILTERS_MODAL, handleOpen);
  }, [handleModalOpen, handleOpen]);
  const currentCityCurrency = useCityCurrency(cityId);

  const handleClose = () => {
    handleModalClose();
    Metrics.ga.sendEvent('Search', 'applied-filters-modal', 'close');
  };

  const renderAppliedFiltersContent = () => {
    const alert = createDefaultFiltersContent();

    const alertContent = getAlertContent(alert, currentCityCurrency).map(
      formattedFilter => (
        <li
          className="applied-filters-modal__filter-value"
          key={formattedFilter}
        >
          {formattedFilter}
        </li>
      )
    );

    const emptyAlertContent =
      !Array.isArray(alertContent) || !alertContent.length;

    return emptyAlertContent ? (
      <li className="applied-filters-modal__filter-value">
        {trans('search.applied-filters-modal.value.allProperties')}
      </li>
    ) : (
      alertContent
    );
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={handleClose}
      ariaHideApp={false}
    >
      <div className="applied-filters-modal">
        <CloseButton
          className="applied-filters-modal__close-button"
          onClick={handleClose}
          data-test="applied-filters-modal"
        />

        <h1 className="applied-filters-modal__header">
          {trans('search.applied-filters-modal.header-title')}
        </h1>

        <div className="applied-filters-modal__icon">
          <AlertBell />
        </div>

        <p>{trans('search.applied-filters-modal.feedback')}</p>

        <div className="applied-filters-modal__filters">
          <li className="applied-filters-modal__filter-value">
            {useCityName(cityId)}
          </li>
          {renderAppliedFiltersContent()}
        </div>

        <p>{trans('search.applied-filters-modal.notify-info')}</p>

        <Button
          className="applied-filters-modal__cta"
          href={getPagePath('marketplace.alerts')}
          target="_blank"
        >
          {trans('search.applied-filters-modal.cta')}
        </Button>
      </div>
    </Modal>
  );
};

AppliedFiltersModal.propTypes = {
  cityId: PropTypes.string.isRequired
};

export default AppliedFiltersModal;
