import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz/client';
import Tracking from '@spotahome/soyuz-tracking';
import { Button, CloseButton } from '@spotahome/ui-library';

import AdUrlService from '../../../../utils/ad-url-service';

import HomeCardBannerImage from '../../components/HomeCardBannerImage';

import './LongTermBanner.scss';

const LongTermBanner = ({ onHideClick }) => {
  const [href, setHref] = useState('');
  const handleHideClick = () => {
    Tracking.ga.sendEvent('Search', 'long-term-banner', 'close');
    onHideClick();
  };

  const handleDiscoverClick = () => {
    Tracking.hotjar.recordTag('LongTermBannerClick');
  };

  useEffect(() => {
    setHref(
      AdUrlService.getLocationUrlWithNewUrlState({
        contractDurationTypesAllowed: 'permanent'
      })
    );
  }, []);

  return (
    <HomeCardBannerImage fullHeight bannerName="long-term">
      <div className="long-term-banner">
        <CloseButton
          className="long-term-banner__hide-button"
          onClick={handleHideClick}
          color="black"
        />
        <div className="long-term-banner__content">
          <h3 className="long-term-banner__title">
            {trans('homecard.banner.long-term.title')}
          </h3>
        </div>
        <Button
          className="long-term-banner__discover-button"
          onClick={handleDiscoverClick}
          href={href}
          target="_blank"
          color=""
        >
          {trans('homecard.banner.long-term.cta')}
        </Button>
      </div>
    </HomeCardBannerImage>
  );
};

LongTermBanner.propTypes = {
  onHideClick: PropTypes.func.isRequired
};

export default LongTermBanner;
